import axios from "axios";
import { Dispatch } from "redux";
import { getCookie } from "../../common/cookies";
import { isNumber } from "../../common/price/price";
import { baseUrl } from "../../serverConfig";
import { itemCount, orderItemType } from "../../Types/Types";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { store } from "../store";
import { OrderMode } from "./../../constants";
import {
  BusinessDiscount,
  BusinessInfo,
  calculatedDiscount,
  Channel,
  customizationType,
  DISCOUNT_TYPE,
  DISCOUNT_VALUE_TYPE,
  FinalOrderPayload,
  MenuType,
  preItemType,
  ProductById,
} from "./../../Types/Types";
import sendOrderToServer from "./order";
import sendReceiptToServer from "./receipt";

const isOrderingDisabled = (bizJson: BusinessInfo) => {
  if (bizJson.storeTemporarilyClosed) {
    return true;
  }

  if (!bizJson.storeOpen) {
    return true;
  }

  return false;
};

const getConfigPath = (viewMode?: string) => {
  let configPath = "";

  if (isNumber(viewMode)) {
    configPath = OrderMode.table;
    return configPath + "/";
  }

  switch (viewMode) {
    case OrderMode.kiosk:
      configPath = OrderMode.kiosk;
      break;
    case OrderMode.menuOnly:
      configPath = OrderMode.menuOnly;
      break;
    case OrderMode.catering:
      configPath = OrderMode.catering;
      break;
    default:
      return configPath;
  }
  return configPath + "/";
};

export const fetchBusinessData = (
  businessPath: string,
  channel?: Channel,
  menuCategory?
) => {
  return async (dispatch: Dispatch<Action>) => {
    const serviceProviderId = await fetchAndStoreBizInfo(
      businessPath,
      channel,
      dispatch
    );

    if (channel !== OrderMode.catering) {
      fetchProductsAndCategories(
        serviceProviderId,
        channel,
        dispatch,
        menuCategory
      );
    }
  };
};

export const fetchProductsAndCategories = (
  serviceProviderId,
  channel?: Channel,
  outerDispatch?: Dispatch<Action>,
  menuCategory?
) => {
  const fetchIt = async (dispatch: Dispatch<Action>) => {
    const productsById: { [k: string]: MenuType[] } = {};

    const configPath = getConfigPath(channel);
    try {
      const { data } = await axios.get(
        `${baseUrl}/rest/productCategory/${serviceProviderId}`
      );

      data.forEach((category: any) => {
        productsById[category.id] = [];
      });

      dispatch({
        type: ActionType.FETCH_FOODS_CATEGORIES_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ActionType.FETCH_FOODS_CATEGORIES_ERROR,
        payload: err.message,
      });
    }

    try {
      // TODO change path
      const businessId =
        store?.getState()?.businessInfo?.businessInfo?.businessId ?? "";
      const data = await fetchProducts(serviceProviderId, businessId, channel);

      if (Array.isArray(data)) {
        storeProducts(data, productsById, dispatch);

        const firstActiveCategoryId = Object.entries(productsById).find(
          (x) => x[1].length > 0
        )?.[0];
        if (!!firstActiveCategoryId) {
          menuCategory ??
            dispatch({
              type: ActionType.CHANGE_DISPLAY_MENU,
              payload: +firstActiveCategoryId,
            });
        }
      }
    } catch (err) {
      dispatch({
        type: ActionType.FETCH_PRODUCTS_ERROR,
        payload: err.message,
      });
    }

    const isSmsNotificationRequestedCookie = getCookie(
      "smsNotificationRequested"
    )?.toLowerCase();
    const isSmsNotificationRequestedInit = !!isSmsNotificationRequestedCookie
      ? (JSON.parse(isSmsNotificationRequestedCookie) as boolean)
      : true;

    dispatch({
      type: ActionType.SET_SMS_NOTIFICATION_REQUESTED,
      payload: isSmsNotificationRequestedInit,
    });

    dispatch({
      type: ActionType.SET_APP_BOOTED_UP,
      payload: true,
    });

    await fetchCreditInfo(dispatch, serviceProviderId);
  };

  if (!outerDispatch) return fetchIt;
  else return fetchIt(outerDispatch);
};

export const fetchProducts = async (
  serviceProviderId: string | number,
  businessId?: string,
  channel?: Channel,
  dateTime?: number
) => {
  if (businessId === undefined) {
    return null;
  }

  channel = channel ?? "normal";
  dateTime = dateTime ?? 0;

  const { data } = await axios.get(
    `${baseUrl}/rest/product/productsListByBusinessId/${serviceProviderId}/${businessId}/${channel}/${dateTime}`
  );

  return data;
};

export const setAppBooted = (
  newValue: boolean,
  dispatch?: Dispatch<Action>
) => {
  if (dispatch) {
    dispatch({
      type: ActionType.SET_APP_BOOTED_UP,
      payload: newValue,
    });
    return;
  }

  return {
    type: ActionType.SET_APP_BOOTED_UP,
    payload: newValue,
  };
};

export const storeProductsAndCategories = (data, menuCategory?: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      menuCategory ??
        dispatch({
          type: ActionType.CHANGE_DISPLAY_MENU,
          payload: data[0].id,
        });

      dispatch({
        type: ActionType.FETCH_FOODS_CATEGORIES_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ActionType.FETCH_FOODS_CATEGORIES_ERROR,
        payload: err.message,
      });
    }
  };
};

export const storeProducts = (
  menuProducts: MenuType[],
  productsById: ProductById = {},
  dispatch?: Dispatch<Action>
) => {
  const storeIt = async (_dispatch: Dispatch<Action>) => {
    const foodsCategories = store?.getState()?.businessInfo?.foodsCategories;
    if (foodsCategories) {
      foodsCategories.forEach((category: any) => {
        productsById[category.id] = [];
      });
    }

    menuProducts.forEach((product: MenuType) => {
      const category = productsById[product.category];
      if (category) {
        category.push(product);
      }
    });

    _dispatch({
      type: ActionType.FETCH_PRODUCTS_SUCCESS,
      payload: productsById,
    });
  };

  if (!dispatch) return storeIt;

  storeIt(dispatch);
};

export const storeCateringProducts = (
  menuProducts: MenuType[],
  productsById: ProductById = {},
  dispatch?: Dispatch<Action>
) => {
  const storeIt = async (_dispatch: Dispatch<Action>) => {
    const dispatchRes = storeProducts(menuProducts, productsById, _dispatch);

    _dispatch({
      type: ActionType.SET_CATERING_DATE_TIME_SUCCESS,
    });

    return dispatchRes;
  };

  if (!dispatch) return storeIt;

  storeIt(dispatch);
};

export const fetchAndStoreBizInfo = (
  businessPath: string,
  viewMode?: string,
  dispatch?: Dispatch<Action>
) => {
  const fetchIt = async (dispatch: Dispatch<Action>) => {
    try {
      const configPath = getConfigPath(viewMode);
      const url = `${baseUrl}/rest/business/${configPath}${businessPath}`;
      const { data } = await axios.get(url);

      const businessJson = data as BusinessInfo;
      const menuOnlyFromUrl = viewMode === OrderMode.menuOnly;
      const isKioskViewMode = viewMode === OrderMode.kiosk;

      if (
        !isKioskViewMode &&
        (menuOnlyFromUrl || isOrderingDisabled(businessJson))
      ) {
        businessJson.menuOnly = true;
      }

      if (businessPath === "miraas") {
        businessJson.menuOnly = false;
      }

      if (!businessJson.paperBagFee) {
        dispatch({
          type: ActionType.SET_PAPER_BAG_COUNT,
          payload: 0,
        });
        businessJson.paperBagFee = 0;
      }

      dispatch({
        type: ActionType.FETCH_BUSINESSINFO_SUCCESS,
        payload: businessJson,
      });

      dispatch({
        type: ActionType.SET_PAPER_BAG_FEE,
        payload: businessJson.paperBagFee,
      });

      const selectedTipPercent =
        store.getState().orderDetails.order.selectedTipPercentage;
      if (selectedTipPercent === undefined) {
        dispatch({
          type: ActionType.SET_TIP_PERCENTAGE,
          payload: data.tip[0],
        });
      }
      const driverTipPercentage = store.getState().orderDetails.order.driverTipPercentage;
      if (driverTipPercentage === undefined) {
        dispatch({
          type: ActionType.SET_DRIVER_TIP_PERCENTAGE,
          payload: data.tip[0],
        });
      }

      return data.serviceProviderUserId;
    } catch (err) {
      dispatch({
        type: ActionType.FETCH_BUSINESSINFO_ERROR,
        payload: err.message,
      });
    }
    return -1;
  };

  if (!dispatch) {
    return fetchIt;
  }

  return fetchIt(dispatch);
};

const fetchCreditInfo = async (
  dispatch: Dispatch<Action>,
  serviceProviderUserId?: string
) => {
  try {
    const state = store.getState();
    const serviceProviderId =
      serviceProviderUserId ??
      state.businessInfo.businessInfo?.serviceProviderUserId;
    const { credential, isSignedIn } = store.getState().account;
    if (!isSignedIn) return;
    const uri = `${baseUrl}/rest/giftCard/balance`;
    const { data } = await axios.get(
      `${uri}/${credential.email}/${serviceProviderId}`
    );
    const credit = data;

    if (!credit) return;

    if (credit.creditBalance === 0) return;

    dispatch({
      type: ActionType.ADD_OVERALL_DISCOUNT,
      payload: {
        title: "Credit",
        type: DISCOUNT_VALUE_TYPE.amount,
        discountType: DISCOUNT_TYPE.credit,
        value: credit.creditBalance * 100,
        amount: 0,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const loadCreditInfo = (
  serviceProviderUserId?: string,
  dispatch?: Dispatch<Action>
) => {
  if (dispatch) {
    return fetchCreditInfo(dispatch, serviceProviderUserId);
  }

  return async (dispatch: Dispatch<Action>) => {
    fetchCreditInfo(dispatch, serviceProviderUserId);
  };
};

export const setBizInfo = (newBizInfo: BusinessInfo) => {
  return {
    type: ActionType.SET_BUSINESSINFO,
    payload: newBizInfo,
  };
};

export const turnOffApp = () => {
  return {
    type: ActionType.APP_TURN_OFF,
  };
};

export const addPromoDiscount = (promoDiscount: BusinessDiscount) => {
  return {
    type: ActionType.ADD_OVERALL_DISCOUNT,
    payload: promoDiscount,
  };
};

export const changeDisplayMenu = (categoryId: number) => {
  return {
    type: ActionType.CHANGE_DISPLAY_MENU,
    payload: categoryId,
  };
};

export const clearSelectedDisplayMenu = () => {
  return {
    type: ActionType.CLEAR_DISPLAY_MENU,
  };
};

export const addItem = (item: orderItemType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ADD_ITEM,
      payload: item,
    });

    dispatch({
      type: ActionType.SET_ADDED_CART_ITEM,
      payload: item,
    });
  };
};

export const preItem = (item: preItemType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.PRE_ITEM,
      payload: item,
    });

    dispatch({
      type: ActionType.SET_ADDED_CART_ITEM,
      payload: item,
    });
  };
};
export const setCountItem = (index: number, count: number) => {
  return {
    type: ActionType.SET_COUNT_ITEM,
    payload: { index, count },
  };
};

export const incrementItem = (countNum: itemCount) => {
  return {
    type: ActionType.INCREMENT_ITEM,
    payload: countNum,
  };
};

export const decrementItem = (countNum: itemCount) => {
  return {
    type: ActionType.DECREMENT_ITEM,
    payload: countNum,
  };
};

export const removeItem = (item: orderItemType) => {
  return {
    type: ActionType.REMOVE_ITEM,
    payload: item,
  };
};

export const setInfoToFinalOrder = (
  businessInfo: BusinessInfo | null = null,
  isTableViewMode = false
) => {
  return {
    type: ActionType.SET_INFO_TO_FINAL_ORDER,
    payload: {
      businessInfo,
      isTableViewMode,
    },
  };
};

export const setDiscountsToFinalOrder = (discounts: calculatedDiscount[]) => ({
  type: ActionType.SET_DISCOUNTS_TO_FINAL_ORDER,
  payload: discounts,
});

export const setCompleteFinalOrder = (finalOrder: FinalOrderPayload) => {
  return {
    type: ActionType.SET_COMPLETE_FINAL_ORDER,
    payload: finalOrder,
  };
};

export const setCompleteOrder = (order: FinalOrderPayload) => {
  return {
    type: ActionType.SET_COMPLETE_ORDER,
    payload: order,
  };
};

export const addCustomization = (customizationValues: customizationType) => {
  return {
    type: ActionType.UPDATE_CUSTOMIZE,
    payload: customizationValues,
  };
};

export const changeOrder = (finalOrder: FinalOrderPayload) => {
  return {
    type: ActionType.CHANGE_ORDER,
    payload: finalOrder,
  };
};

export const emptyCart = () => {
  return {
    type: ActionType.EMPTY_CART,
  };
};

export const setSpecialNotes = (note: string) => {
  return {
    type: ActionType.SET_SPECIAL_NOTES,
    payload: note,
  };
};

export const setOrderType = (orderType: FinalOrderPayload["orderType"]) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ORDER_TYPE,
      payload: orderType,
    });
    dispatch({
      type: ActionType.SET_PREV_ORDER_TYPE,
      payload: orderType,
    });
  };
};

export const setUserName = (userName: string) => {
  return {
    type: ActionType.SET_USER_NAME,
    payload: userName,
  };
};

export const setUserNumber = (phoneNumber: string) => {
  return {
    type: ActionType.SET_USER_NUMBER,
    payload: phoneNumber,
  };
};

export const setTipPercentage = (orderType: string | number) => {
  return {
    type: ActionType.SET_TIP_PERCENTAGE,
    payload: orderType,
  };
};

export const setDriverTipPercentage = (orderType: string | number) => {
  return {
    type: ActionType.SET_DRIVER_TIP_PERCENTAGE,
    payload: orderType,
  };
};

export const setTime = (time: string) => {
  return {
    type: ActionType.SET_TIME,
    payload: time,
  };
};

export const setPreOrderDateTime = (time?: number) => {
  return {
    type: ActionType.SET_PREORDER_DATETIME,
    payload: time,
  };
};

export const setSmsNotificationRequested = (flag: boolean) => {
  return {
    type: ActionType.SET_SMS_NOTIFICATION_REQUESTED,
    payload: flag,
  };
};

export * from "./account";
export * from "./order";
export * from "./status";

export const sendOrder = sendOrderToServer;

export const receiptInfo = sendReceiptToServer;
