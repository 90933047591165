import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { sendResetEmail } from "../../api/user";
import { validateEmail } from "../../common/account/account";
import { useActions } from "../../hooks/useActions";
import classes from "./Account.module.scss";
import { convertToPath } from "./AccountHeader";
import SubmitFieldWithButton from "./SubmitFieldWithButton";

import Button from "@mui/material/Button";
import Typography  from "@mui/material/Typography";

const ForgotPassword: React.FC = () => {
  const params = useParams<{ businessName: string }>();
  const appBaseUrl = convertToPath(params.businessName);

  const { resetPasswordSuccess} = useActions();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = String(event.target.value);
    setMessage("");
    setEmail(currValue);
  }

  const isFormValid = () => {
    return email.length > 0;
  }

  const submitEmail = async(e) => {
    setEmail("");
    if(!validateEmail(email)) {
      setError("Please enter a valid Email");
      return;
    }
    setError("");

    try {

      const userLogin = {
        emailAsUserId: email,
        password: "",
        enterChoice: ""
      }

      await sendResetEmail(userLogin);
      resetPasswordSuccess(email);
      setMessage("We've sent a temporary password to your email.");

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={classes.form}>
      <div className={classes.pageTitle}>Trouble Signing In?</div>

      <SubmitFieldWithButton
        value={email}
        onChange={handleInput}
        onSubmit={submitEmail}
        isFormValid={isFormValid}
        moreClasses={classes.forgot_button}
      />

      <div className={classes.messages}>
        <span className={classes.info}>
          {message}
        </span>
        <span className={classes.error}>
          {error}
        </span>
      </div>
      <Button
        sx={{
          width: "100%",
          padding: "1rem 0",
          backgroundColor: "primary.main",
          color: "white",
          borderRadius: "56px",
          "&:hover": {
            backgroundColor: "primary.main",
          },
        }}
        onClick={submitEmail}
      >
        <Typography>Submit Email</Typography>
      </Button>
      <div className={classes.forgot_links}>
        <div className={classes.forgot_info}>
          Enter your email and we'll send you a link to get back into your account.
        </div>
        <Link
          className={`${classes.link_bottom} ${classes.link} ${classes.forgot_backBtn}`}
          to={`${appBaseUrl}/account/signIn`}
        >
          Back to Login
        </Link>
      </div>
    </div>
  );
}

export default ForgotPassword;