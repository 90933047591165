import { useStableVersion } from "../../../serverConfig";
import ConfirmOrderStable from "../../ConfirmOrder/ConfirmOrderStable";
import classes from "./styles/CheckoutSheet.module.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BackDrop from "../../BackDrop/BackDrop";
import CheckoutDetail from "../../Checkout/CheckoutDetail";
import ConfirmOrder from "../../ConfirmOrder/ConfirmOrder";
import OrderTypeSelection from "./OrderTypeSelection";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useBizTheme } from "../../../hooks/useBizTheme";
import { OptionType, OrderMode, StoreOrderingState, defaultDiningOption, orderTypeLabelMap, orderTypeMap } from "../../../constants";
import { ArrowBackIos } from '@material-ui/icons';
import { IconButton, Typography } from "@material-ui/core";
import { IPublicProfileRouteParams } from "../../../Types/RouteTypes";
import { useHistory, useParams } from "react-router";
import { isNumber } from "../../../common/price/price";
import { getOrderMode, isDiningOptionAvailable } from "../../../common/app";
import { useActions } from "../../../hooks/useActions";
import { FinalOrderPayload } from "../../../Types/Types";
import CheckoutFooter from "../../Checkout/CheckoutFooter";

interface CheckoutSheetProps {
  show: boolean;
  setShowCheckout: any;
}

enum ModalStep {
  CheckoutSelection,
  CheckoutDetails,
  ConfirmOrder,
  Catering,
  TableView,
}

const ModalStepsToTitle = {
  [ModalStep.CheckoutSelection]: "Checkout",
  [ModalStep.CheckoutDetails]: "Checkout",
  [ModalStep.ConfirmOrder]: "Confirm Order",
  [ModalStep.Catering]: "Catering",
}

const DefaultModalStep = ModalStep.CheckoutSelection;


const CheckoutSheet: React.FC<CheckoutSheetProps> = ({
  children,
  show,
  setShowCheckout,
}) => {

  const history = useHistory()
  const [activeStep, setActiveStep] = useState(DefaultModalStep);
  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const appViewMode = getOrderMode(viewMode);

  const { setOrderType, setEnableBrowserBackModal } = useActions();
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const appStates = useTypedSelector((state) => state.appLocalStatus);
  const order = useTypedSelector(x => x.orderDetails.order);
  const theme = useBizTheme();

  const isKioskViewMode = appViewMode === OrderMode.kiosk;
  const isCateringViewMode = appViewMode === OrderMode.catering;
  const isPreOrderOnly = businessInfo?.storeOrderingStatus === StoreOrderingState.preOrdersOnly;
  const isTableViewMode = isPreOrderOnly ? false : isNumber(viewMode);

  const hasAnyDiningOptionEnabled = useMemo(() => {
    const orderTypeKeys = Object.keys(orderTypeMap);
    const enabledDiningOption = orderTypeKeys.find(x => isDiningOptionAvailable(businessInfo?.diningOption[x]));

    return !!enabledDiningOption;
  }, [orderTypeMap, businessInfo?.diningOption]);

  const hasOnlyGiftCardsInCart = useMemo(() => {
    const items = order.finalOrderItems;
    return items.every((item) => item.type === OptionType.giftCard);
  }, [order.finalOrderItems]);

  const jumpToDetailConditions = useMemo(() => {
    const list = [isTableViewMode, isCateringViewMode, hasOnlyGiftCardsInCart, !hasAnyDiningOptionEnabled]
    return !!list.find(x => x === true);
  }, [isTableViewMode, isCateringViewMode, hasOnlyGiftCardsInCart, hasAnyDiningOptionEnabled]);

  useEffect(() => {
    if (jumpToDetailConditions) {
      setActiveStep(ModalStep.CheckoutDetails);
    }
  }, [show, jumpToDetailConditions]);

  useEffect(() => {
    if (!!appStates.orderTypeFromTracking) return;

    if (appViewMode === OrderMode.table) {
      setOrderType(+viewMode);
      return;
    }

    const defaultOrderType = orderTypeMap[defaultDiningOption] as FinalOrderPayload["orderType"];
    if (isKioskViewMode) {
      setOrderType(defaultOrderType);
    }
  }, [appViewMode, appStates.orderTypeFromTracking]);

  const dismissModal = () => {
    setShowCheckout(false);
    setActiveStep(DefaultModalStep);
  };

  const handleNext = useCallback(() => {
    if (activeStep == ModalStep.ConfirmOrder) {
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, setActiveStep]);

  const handleBack = useCallback(() => {
    if (activeStep === DefaultModalStep || jumpToDetailConditions) {
      setShowCheckout(false);
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [activeStep, setActiveStep, jumpToDetailConditions]);

  const ModalTitle = useMemo(() => {

    if (isTableViewMode) {
      return orderTypeLabelMap["dine-in"];
    }
    if (isCateringViewMode) {
      return ModalStepsToTitle[ModalStep.Catering];
    }

    if (hasOnlyGiftCardsInCart) {
      return ModalStepsToTitle[ModalStep.CheckoutSelection];
    }

    if (activeStep === ModalStep.CheckoutDetails && order.orderType) {
      return orderTypeLabelMap[order.orderType];
    }

    return ModalStepsToTitle[activeStep];
  }, [activeStep, isTableViewMode, isCateringViewMode, hasOnlyGiftCardsInCart])

  const ModalContent = useMemo(() => {
    switch (activeStep) {
      case ModalStep.CheckoutSelection:
        return <OrderTypeSelection handleNext={handleNext} handleBack={handleBack} />
      case ModalStep.CheckoutDetails:
        return <CheckoutDetail handleNext={handleNext} handleBack={handleBack} />
      case ModalStep.ConfirmOrder:
        return <ConfirmOrder handleNext={handleNext} handleBack={handleBack} dismissModal={dismissModal} />
      default:
        break;
    }
  }, [activeStep, handleNext, handleBack])

  const ModalIcon = useMemo(() => {

    return <ArrowBackIos style={{ color: "white" }} />;
  }, [activeStep])

  const ModalFooter = useMemo(() => {
    let elem: React.ReactNode = null;
    switch (activeStep) {
      case ModalStep.CheckoutDetails:
        elem = <CheckoutFooter handleNext={handleNext} />;
        break;
      case ModalStep.CheckoutSelection:
      case ModalStep.ConfirmOrder:
      default:
        return null;
    }

    return <div className={classes.footer}>
      {elem}
    </div>
  }, [activeStep])

  return (
    <BackDrop show={show} onClick={dismissModal}>
      <div className={classes.sheet}>

        <div className={classes.header} style={{ backgroundColor: theme.color }}>

          <IconButton aria-label="" onClick={handleBack}>
            {ModalIcon}
          </IconButton>

          <Typography variant="h5" className={classes.title}>{ModalTitle}</Typography>

        </div>

        <div className={classes.content}>

          {ModalContent}

          {ModalFooter}

        </div>

      </div>
    </BackDrop>
  );
};

export default CheckoutSheet;