import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Route, useHistory, useLocation, useParams, useRouteMatch } from "react-router";
import { getFont } from "../../common/themeUtils";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import classes from "./Account.module.scss";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
export const convertToPath = (businessName) => {
  return businessName ? `/${businessName}` : "";
}

const AccountHeader: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ businessName: string }>();
  const location = useLocation<{ returnUrl?: string }>();
  const returnUrl = location?.state?.returnUrl;
  const { path } = useRouteMatch();
  const { businessInfo } = useTypedSelector(state => state.businessInfo);

  const isBizNameLong = !!businessInfo?.name && businessInfo.name.length > 14;
  
  return (
    <Box className={classes.accountHeader} style={{
      background: businessInfo?.theme?.color ?? "#000", 
    }}>
      <Route exact path={[`${path}`, `${path}/signIn`, `${path}/signUp`]}>
        <div className={classes.backBtn}
          onClick={(e) => history.push(returnUrl || `/${params.businessName ?? ""}`)}
        >
          <FontAwesomeIcon icon={faArrowLeft} color="#fff"/>
        </div>
      </Route>
      <div
        style={{
          font: getFont(businessInfo),
          fontSize: isBizNameLong ? "1rem" : "1.5rem",
        }}
        className={classes.appTitle}
      ><Typography  variant="h5" color="#fff">{businessInfo?.name ?? "Relayfy"}</Typography></div>
    </Box>
  );
}

export default AccountHeader;