import axios from "axios";
import { baseUrl } from "../serverConfig";

export type UrlMenuType = "menuOnly" | "ordering" | number;

export interface FetchQrCodeState {
  serviceProviderUserId: string;
  menuType: UrlMenuType;
}

export const getQrCode = async (data: FetchQrCodeState) => {
  const { serviceProviderUserId, menuType } = data;

  try {
    const params = `${serviceProviderUserId}/${menuType}`;
    const url = `${baseUrl}/rest/user/menuQRCode/${params}`;
    return await axios.get(url, { responseType: 'arraybuffer' });
  } catch (error) {
    throw error;
  }
}