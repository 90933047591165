// ReviewOrderPopup.jsx

import "./ReviewOrderPopup.scss";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatPrice, } from "../../common/price/price";
import { appendBaseUrlToImage } from "../../common/image";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import AppButton from "../AppButton/AppButton";
import { useItemDetails } from "../../hooks/useItemDetails";
import { ChevronBack } from "../ChevronBack/ChevronBack";
import { DISCOUNT_VALUE_TYPE, MenuItem } from "../../Types/Types";

type ReviewOrderPopupProps = {
  open: boolean,
  onClose: () => void,
  updateOrderQuantity,
  handleDeleteItem,
  businessInfo,
  openCheckout,
  showCustomize,
  getImgUri,
  getStoreProductAndOption,
  errorMsg,
}

const ReviewOrderPopup = ({
  open,
  onClose,
  updateOrderQuantity,
  handleDeleteItem,
  businessInfo,
  openCheckout,
  showCustomize,
  getImgUri,
  getStoreProductAndOption,
  errorMsg,
}: ReviewOrderPopupProps) => {
  const orders = useTypedSelector((state) => state.orderDetails);

  return (
    <div>
      <Dialog open={open} onClose={onClose} className="dialog">
        <div
          className="dialogContent"
          style={{ width: "414px", height: "552px", gap: "32px" }}
        >
          <DialogTitle
            className="dialogTitle"
            style={{
              backgroundColor: businessInfo?.theme.color,
              color: "white",
            }}
          >
            <ChevronBack onClick={onClose}/>
            <Typography className="titleText">
              Review & Customize ($
              {(orders.order.subTotal / 100).toFixed(2)})
            </Typography>
          </DialogTitle>
          {errorMsg && <div className="errorMsg">{errorMsg}</div>}
          <DialogContent className="dialogContent">
            <List>
              {orders.cart.map((item, index) => (
                <ListItemComponent
                  key={item.productOptionId}
                  item={item}
                  updateOrderQuantity={updateOrderQuantity}
                  removeItem={handleDeleteItem}
                  businessInfo={businessInfo}
                  index={index}
                  showCustomize={showCustomize}
                  getImgUri={getImgUri}
                  getStoreProductAndOption={getStoreProductAndOption}
                />
              ))}
            </List>
            <Typography variant="h6" className="subtotalText">
              Subtotal........................$
              {(orders.order.subTotal / 100).toFixed(2)}
            </Typography>
          </DialogContent>
        </div>
        <DialogActions className="dialogActions">
          <AppButton
            isOutlined
            className="orderMore"
            variant="outlined"
            onClick={onClose}
          >
            Order more
          </AppButton>
          <AppButton
            onClick={() => openCheckout()}
            className="checkout"
            color="primary"
            variant="contained"
            style={{
              backgroundColor: businessInfo?.theme.color,
              color: "white",
            }}
          >
            Checkout
          </AppButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

type ListItemComponentProps = {
  item: MenuItem,
  updateOrderQuantity,
  removeItem,
  businessInfo,
  index,
  showCustomize,
  getImgUri,
  getStoreProductAndOption,
}

const ListItemComponent = ({
  item,
  updateOrderQuantity,
  removeItem,
  businessInfo,
  index,
  showCustomize,
  getImgUri,
  getStoreProductAndOption,
}: ListItemComponentProps) => {

  const { image, fullPriceWithCust, itemTotal } = useItemDetails(item);
  const [containerHeights, setContainerHeights] = useState<number[]>([]);
  const [itemLabelContainerHeights, setItemLabelContainerHeights] = useState<number[]>([]);

  useEffect(() => {
    const containers = document.querySelectorAll(".rate-ou-in");
    const heights = Array.from(containers).map(
      (container) => container.clientHeight
    );
    setContainerHeights(heights);
    const itemLabelcontainers = document.querySelectorAll(".itemLabel");
    const itemLabelheights = Array.from(itemLabelcontainers).map(
      (container) => container.clientHeight
    );
    setItemLabelContainerHeights(itemLabelheights);
  }, [item]);

  const getStyleNoDiscountStyle = (index) => {
    if (containerHeights[index]) {
      const padding = containerHeights[index] - 14;
      return { paddingBottom: `${padding}px` };
    }
    return {};
  };

  const getStyleDiscounted = (index) => {
    if (itemLabelContainerHeights[index] > 16) {
      const padding = itemLabelContainerHeights[index] - 32;
      return { paddingBottom: `${padding}px` };
    }

    return {};
  };

  const getStyleRate = (index) => {
    if (itemLabelContainerHeights[index] <= 16 && hasDiscount) {
      return { marginTop: `${-16}px` };
    }
    return {};
  };

  const isBogo = item.discountRule?.type === DISCOUNT_VALUE_TYPE.bogo;
  const hasDiscount = item.discountRule && item.discountRule.value > 0 && !isBogo;
  let isSoldOut = false;
  let noProductImage = false;
  const getProductImageByOptiondId = () => {
    let immg = getImgUri(item);
    if (!immg) {
      //this means we do nto have image for . need to add busbiess logo with 0.25 opacity here
      noProductImage = true;
      immg = appendBaseUrlToImage(businessInfo?.logo);
    }
    return immg;
  };
  const productOption = getStoreProductAndOption(item.productOptionId);

  if (productOption?.storeOption) {
    isSoldOut = productOption.storeOption.soldout;
  }

  return (
    <ListItem
      key={item.productOptionId}
      className="listItem"
      style={{ width: "308px", gap: "16px" }}
    >
      {/* <div style={{width:"308px",height:"144px",gap:"16px"}}> */}
      <img
        src={!image ? getProductImageByOptiondId() : image}
        style={
          noProductImage
            ? {
              borderRadius: "12px",
              width: "40px",
              height: "40px",
              opacity: "0.25",
            }
            : {
              borderRadius: "12px",
              width: "80px",
              height: "80px",
              filter: isSoldOut ? "grayscale(100%)" : undefined,
            }
        }
      />
      <ListItemText
        primary={
          <div className="rate-ou-in">
            <div
              className="itemLabel"
              style={{ wordWrap: "break-word", whiteSpace: "normal" }}
            >
              {item?.optionName + " " + item?.name}
            </div>
            <div className="rate-price" style={getStyleRate(index)}>
              {hasDiscount ? (
                <div
                  className="menu__container_price"
                  style={getStyleDiscounted(index)}
                >
                  <div className="menu__container_price_discount">
                    <span className="menu__container_price_original">
                      {formatPrice(fullPriceWithCust)}
                    </span>
                    <span className="menu__container_price_discounted">
                      {formatPrice(itemTotal)}
                    </span>
                  </div>
                </div>
              ) : (
                <span
                  className="menu__container_price_nodiscount"
                  style={getStyleNoDiscountStyle(index)}
                >
                  {formatPrice(itemTotal)}
                </span>
              )}
            </div>
          </div>
        }
        secondary={
          <div className="price-div">
            <div className="rate-data">
              {item.selectedCustomization.oneOfList
                ?.map((ofl) =>
                  ofl?.choices
                    ?.filter((choices) => choices.value > 0)
                    .map((choices, index) => (
                      <div>
                        <Typography
                          key={index}
                          component="div"
                          style={{ width: "139px" }}
                        >
                          {`${choices.value > 1
                            ? choices.name + " * " + choices.value
                            : choices.name
                            } `}
                          {/* ${!(choices.price > 0 || choices.price < 0) ? '' : choices.price / 100} */}
                        </Typography>
                        <span>
                          {/* ${!(choices.price > 0 || choices.price < 0) ? '' : choices.price / 100} */}
                          {choices.price === 0 || !choices.price
                            ? ""
                            : choices.price > 0
                              ? `+$${(choices.value > 1
                                ? (
                                  (choices.price * choices.value) /
                                  100
                                ).toFixed(2)
                                : (choices.price / 100).toFixed(2)) +
                              (item.count > 1 ? "/ea" : "")
                              }`
                              : `-$${Math.abs(choices.price / 100).toFixed(2)}`}
                        </span>
                      </div>
                    ))
                )
                .flat()}
              {item.selectedCustomization &&
                item.selectedCustomization.optionSpecialRequest ? (
                <span>
                  <strong>Note: </strong>
                  {item.selectedCustomization.optionSpecialRequest}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        }
      />
      {/* </div> */}
      <ListItemSecondaryAction
        style={{ width: "63px", height: "68px", gap: "24px" }}
      >
        <div
          className="counter"
          style={{ backgroundColor: businessInfo?.theme.color }}
        >
          <IconButton size="large">
            {item.count === 1 ? (
              <DeleteIcon
                onClick={() => removeItem(item)}
                style={{
                  backgroundColor: businessInfo?.theme.color,
                  color: "white",
                  width: "16px",
                  height: "16px",
                }}
              />
            ) : (
              <RemoveIcon
                onClick={() => updateOrderQuantity(index, item.count - 1, item)}
              />
            )}
          </IconButton>

          <Typography
            className="count"
            style={{
              marginLeft: "4px",
            }}
          >
            {item.count}
          </Typography>

          <IconButton
            // size="large"
            disabled={isSoldOut}
            onClick={() => updateOrderQuantity(index, item.count + 1, item)}
          >
            <AddIcon
              fontSize="large"
              style={{ width: "15px", height: "15px" }}
            />
          </IconButton>
        </div>
        <a
          className="edit-btn"
          style={{ color: businessInfo?.theme.color }}
          onClick={() => showCustomize(index)}
        >
          Customize
        </a>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ReviewOrderPopup;
