import ReactDOM from "react-dom";
import RootRouter from "./RootRouter";
import { Provider } from "react-redux";
import { store } from "./state/store";
// material ui context
import { ThemeProviderComponent } from "./context/themeContext";
import "semantic-ui-css/semantic.min.css";


if (process.env.NODE_ENV === "development") {
  const style = "position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;";
  const interval = setInterval(() => {

    const iFrameElem = document.querySelector(`iframe[style*="${style}"]`);
    if (!!iFrameElem) {
      iFrameElem.remove();
    }
  }, 1000);
}


const ThemedApp = () => {
  return <ThemeProviderComponent>
    <RootRouter />
  </ThemeProviderComponent>;
}

ReactDOM.render(
    <Provider store={store}>
      <ThemedApp />
    </Provider>,
  document.getElementById("root")
); 