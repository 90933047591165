import { Button, ButtonProps } from "@material-ui/core";
import React from "react";
import StyledComponent from "./StyledComponent";

interface StyledButtonPropsBase {
  isMaterial: boolean;
}

export type StyledButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> | ButtonProps | StyledButtonPropsBase;


const StyledButton: React.FC<StyledButtonProps> = (props) => {
  const { isMaterial, ...asButtonProps } = props as StyledButtonPropsBase;

  return (
    <StyledComponent>
      {isMaterial ?
        <Button {...asButtonProps}>
          {props.children}
        </Button>
        : <button {...props}>
          {props.children}
        </button>}
    </StyledComponent>
  );
}

export default StyledButton;