import classes from "./Checkout.module.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../serverConfig";
import axios from "axios";
import Loader from "../Loader/Loader";
import { TextField } from "@material-ui/core";
import AppButton from "../AppButton/AppButton";


const PromoCombo = () => {
    const { addPromoDiscount, } = useActions();

    const { businessInfo } = useTypedSelector((state) => state.businessInfo);
    const [isLoading, setIsLoading] = useState(false);
    const [showPromoValid, setShowPromoValid] = useState(false);

    const [promoCode, setPromoCode] = useState("");
    const [isPromoValid, setIsPromoValid] = useState(false);

    const onPromoInvalid = () => {
        setIsPromoValid(false);
        setPromoCode("");
    };

    const validatePromoOnServer = async (code: string) => {
        if (!businessInfo || !code) return false;
        const format = /[ `!@#$%^&*()_+\-=[]{};':"\\|,.<>\/?~]/;
        if (format.test(code)) {
            onPromoInvalid();
            return false;
        }

        setIsLoading(true);

        const { businessId } = businessInfo;
        const validateUrl = `${baseUrl}/rest/business/validateByBusinessId`;
        const params = `${businessId}/${code}`;

        try {
            const result = await axios.get(`${validateUrl}/${params}`);

            return result.data;
        } catch (err) {
            console.error(err);
        } finally {
            setShowPromoValid(true);
            setIsLoading(false);
        }
    };

    const onValidatingPromo = async (e: React.MouseEvent) => {
        const result = await validatePromoOnServer(promoCode);

        if (!!result && result !== "error") {
            setIsPromoValid(true);
            addPromoDiscount(result);
        } else {
            onPromoInvalid();
        }
    };

    const onPromoInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setShowPromoValid(false);
        setPromoCode(newValue);
    };

    const PromoValidationResIcon = useMemo(() => {
        if (!showPromoValid) {
            return null;
        }

        if (isPromoValid) {
            return <FontAwesomeIcon icon={faCheck} color="green" />;
        } else {
            return <FontAwesomeIcon icon={faTimes} color="red" />
        }
    }, [showPromoValid, isPromoValid]);

    return <div className={classes.promo}>

        {isLoading && <Loader />}

        <TextField
            label="Promo code (opt)"
            autoComplete="off"
            type="text"
            placeholder="Optional"
            onChange={onPromoInput}
            inputProps={{ maxLength: 15 }}
            className={classes.textFieldHalf}
            value={promoCode}
            InputProps={{ endAdornment: PromoValidationResIcon }}
            />

        <AppButton
            variant="text"
            onClick={onValidatingPromo}
            disabled={promoCode.length === 0}
            style={{ width: 140, height: 30 }}
        >
            Validate
        </AppButton>
    </div>
}


export default PromoCombo;