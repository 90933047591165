import { Page, Text, View, Document } from '@react-pdf/renderer';
import { appendBaseUrlToImage } from '../../common/image';
import { formatPrice } from '../../common/price/price';
import {
  BusinessInfo, MenuByCategory, MenuCategory, MenuOption, MenuType
} from '../../Types/Types';
import { pdfStyles } from './PdfStyles';

interface MenuPageProps {
  businessInfo: BusinessInfo;
  category: MenuCategory;
  products: MenuType[];
  bizLogo: string;
}
const bracketsRegex = /\(.*\)/;
function MenuPage({ businessInfo, category, products }: MenuPageProps) {

  const optionsElems = (options: MenuOption[], product: MenuType) => {
    return options.map(option => {
      const price = formatPrice(option.price);
      const optionName = option.label ? `- ${option.label}` : `- ${product.name}`;

      const strippedOptionName = optionName.replace(bracketsRegex, '');
      return (
        <View key={option.id} style={pdfStyles.optionsWrap}>

          <View style={pdfStyles.optionNamePrice}>
            <Text style={pdfStyles.optionName}>
              {strippedOptionName}
            </Text>
            <Text>
              {price}
            </Text>
          </View>

          <View>
            <Text style={pdfStyles.optionSub}>
              {option.optionDescription}
            </Text>
          </View>
        </View>
      )
    })
  }

  const productElems = products.map((prod, ind) => {
    // const singleOption = prod.options.length === 1;
    // const firstOption = prod.options[0];
    // const hasNoOptions = singleOption && !firstOption?.label;
    const productName = prod.name.replace(bracketsRegex, '');

    const description = (
      prod.description.trim() ?
        <Text style={pdfStyles.productSub}>
          {prod.description}
        </Text>
        : <></>
    )

    const products = (
      prod.options.length > 0 ?
        <View style={pdfStyles.optionsList}>
          {optionsElems(prod.options, prod)}
        </View>
        : <></>
    )

    // const productTitle = (
    //   hasNoOptions ? <View style={pdfStyles.productNamePrice}>
    //     <Text>
    //       {productName}
    //     </Text>
    //     <Text>
    //       {`$${firstOption.price}`}
    //     </Text>
    //   </View>
    //     : <Text style={pdfStyles.product}>
    //       {productName}
    //     </Text>
    // )

    return (
      <View
        key={prod.id}
        wrap={ind === 0 ? true : false}
        style={pdfStyles.productWrap}
      >

        {/* {productTitle} */}
        <Text style={pdfStyles.product}>
          {productName}
        </Text>
        {/* <View>
          <Image
            style={pdfStyles.bizLogo}
            // src={"https://ecashier-content.s3.us-west-2.amazonaws.com/images/nusa-coffee/Nusa_Apple_Crumble.jpg"}
          src={bizLogo}
          debug
          />
        </View> */}

        {description}
        {products}
        {/* <Text style={pdfStyles.productSub}>
            {prod.description}
          </Text> */}

        {/* <View style={pdfStyles.optionsList}>
          {optionsElems(prod.options, prod)}
        </View> */}

      </View >
    )
  })

  const categoryName = category.name?.replace(bracketsRegex, '') ?? "";

  const categoryDescription = (
    category.description?.trim() ?
      <Text style={pdfStyles.productSub}>
        {category.description}
      </Text>
      : <></>
  )

  return (
    <Page size="A4" style={pdfStyles.page}>

      <Text
        style={pdfStyles.pageNumber}
        render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />

      <View style={pdfStyles.bizInfo}>
        <Text>
          {businessInfo?.name}
        </Text>
      </View>

      <View style={pdfStyles.categoryWrap}>

        <Text style={pdfStyles.categoryName}>
          {categoryName}
        </Text>
        <hr />
        {categoryDescription}
        {/* <Text style={pdfStyles.categorySub}>
          {category.description}
        </Text> */}
      </View>


      <View style={pdfStyles.productsList}>
        {productElems}
      </View>

    </Page>
  )
}


interface MenuDocumentProps {
  businessInfo: BusinessInfo;
  foodsCategories: MenuCategory[];
  productsInfo: MenuByCategory;
}

const MenuDocument: React.FC<MenuDocumentProps> = (props) => {
  const { businessInfo, foodsCategories, productsInfo } = props;

  const logoImg = appendBaseUrlToImage(businessInfo?.logo);

  return (
    <Document >
      {foodsCategories && foodsCategories
        .map(category => {
          const products = productsInfo[category.id]

          return (
            <MenuPage
              key={category.id}
              category={category}
              products={products}
              businessInfo={businessInfo}
              bizLogo={logoImg}
            />
          )
        })
      }
    </Document>
  )
};

export default MenuDocument;