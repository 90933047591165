import classes from "./AccountRoute.module.scss";
import SignIn from "../../components/Account/SignIn";
import AccountHeader from "../../components/Account/AccountHeader";
import SignInSeparator from "../../components/Account/SignInSeparator";
import SignUp from "../../components/Account/SignUp";
import ForgotPassword from "../../components/Account/ForgotPassword";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useEffect } from "react";
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router";
import ChangePassword from "../../components/Account/ChangePassword";
import ExternalSignInNew from "../../components/Account/ExtSignInNew";

interface AccountPageKLocationState {
  returnUrl?: string;
  signInRedirectUrl?: string;
}

interface AccountPageProp {
  isFromOrderingRoute?: boolean;
}

const AccountPage = (props: AccountPageProp) => {
  const { isFromOrderingRoute = false } = props;
  const { path } = useRouteMatch();
  const location = useLocation<AccountPageKLocationState>();
  const returnUrl = location?.state?.returnUrl;
  const signInRedirectUrl = location?.state?.signInRedirectUrl;
  const params = useParams<{ businessName: string }>();
  const history = useHistory();
  const account = useTypedSelector(state => state.account);
  const { businessInfo } = useTypedSelector(state => state.businessInfo);

  useEffect(() => {
    if (!account.isSignedIn) return;
    if (location.pathname.includes("changePassword")) return;
    if (signInRedirectUrl)
      history.push(signInRedirectUrl);

    if(!returnUrl) {
      history.goBack();
      return;
    }

    const returnUrlWithoutSlash = returnUrl?.startsWith("/") ? returnUrl.substring(1) : returnUrl;
    const redirectUrl = returnUrlWithoutSlash ?? params.businessName ?? "";
    console.log("Redirecting to: ", redirectUrl);

    history.push("/" + redirectUrl);
  }, [account.isSignedIn, history, location.pathname, params.businessName]);

  return (
    <Switch>
      <div className={classes.account}
        style={{
          background:'#fff',
        }}
      >

        <AccountHeader />
        <div className={classes.content}>
          <Route exact path={[`${path}`, `${path}/signIn`]}>
            <SignIn />
          </Route>
          <Route exact path={`${path}/signUp`}>
            <SignUp />
          </Route>
          <Route exact path={`${path}/forgot`}>
            <ForgotPassword />
          </Route>
          <Route exact path={`${path}/changePassword`}>
            <ChangePassword />
          </Route>
          <Route exact path={[`${path}`, `${path}/signIn`, `${path}/signUp`]}>
            <SignInSeparator />
            <ExternalSignInNew isFromOrderingRoute={isFromOrderingRoute} />
          </Route>
        </div>
      </div>
    </Switch>
  );
}

export default AccountPage;
