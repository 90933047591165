import classes from "./MenuCard.module.scss";
import MenuCardSlider from "./MenuCardSlider";
import MenuCardItem from "./MenuCardItem";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { MenuOption, MenuType, orderItemType } from "../../Types/Types";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { useCallback, useMemo } from "react";
import { featuredStyle } from "../../constants";
import { appendBaseUrlToImage } from "../../common/image";
import { useBizTheme } from "../../hooks/useBizTheme";

interface MenuCardProps {
  menu: MenuType;
  screenWidth: number;
  setShowSnackBar: Function;
}

const MenuRow = (props: MenuCardProps) => {
  const { menu, screenWidth, setShowSnackBar } = props;
  const theme = useBizTheme();
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);

  const { addItem, setInfoToFinalOrder } = useActions();
  //must render when responsive. -- will work on later

  const addCart = (index: number) => {
    const product = menu;
    const option = JSON.parse(JSON.stringify(product.options[index])) as MenuOption;

    const orderItem: orderItemType = {
      count: 1,
      selectedCustomization: { ...option.customization, optionSpecialRequest: "" },
      name: product.name,
      image: product.image,
      disabled: option.disabled,
      disabledReason: option.disabledReason,
      description: product.description,
      optionsType: product.optionsType,
      discountRule: option.discountRule,
      optionName: option.label,
      price: option.price,
      productOptionId: option.id,
      productImage: option.optionImage,
      addCondition: option.addCondition,
      type: option.type,
      productDescription: option.optionDescription,
      specialRequest: "",
    };
    addItem(orderItem);
    setInfoToFinalOrder();
  };

  const UnescapeHTML = (html: string) => {
    const escapeEl = document.createElement("p");
    escapeEl.innerHTML = html;
    return escapeEl.textContent;
  };

  const needsSlider = useCallback((optionLength: number) => {
    const totalOptionWidth = 174 * optionLength;
    return screenWidth < totalOptionWidth;
  }, []);

  const isCollapsible = businessInfo?.menuItemCollapsible ?? false;
  // const containerSyle = menu.featured ? featuredStyle : {};
  const containerSyle = {}; // new design do not have any backgrounf colro

  const Items = useMemo(() => {
    return needsSlider(menu.options.length) ? (
      <MenuCardSlider
        menu={menu}
        addCart={addCart}
        UnescapeHTML={UnescapeHTML}
        setShowSnackBar={setShowSnackBar}
      />
    ) : (
      <div className={classes.menu_wrap}>

        <div style={{ display: "flex" }}>
          {menu.options.map((option, i) => (
            <MenuCardItem
              menu={menu}
              option={option}
              addCart={addCart}
              UnescapeHTML={UnescapeHTML}
              index={i}
              key={i}
              getStyle={theme.color!}
              setShowSnackBar={setShowSnackBar}
            />
          ))}
        </div>
      </div>
    )
  }, [menu, needsSlider, addCart, UnescapeHTML, theme])

  const Title = useMemo(() => {
    return (
      <label className={classes.menu__name} style={{ color: theme.color }}>
        {menu.name}
      </label>)
  }, [menu.name, isCollapsible])

  const MenuRowContent = useMemo(() => {
    if (isCollapsible) {
      return <MenuItemAccordion
        summaryContent={Title}
        content={Items}
        menuItem={menu}
      />
    }

    return (
      <>
        {Title}

        {Items}
      </>
    )
  }, [menu, isCollapsible]);

  return (
    <div className={classes.row} style={isCollapsible ? {} : containerSyle}>

      <div className={classes.sliderWrap}>
        {MenuRowContent}
      </div>

    </div >
  );
};


interface MenuItemAccordionProps {
  summaryContent: JSX.Element;
  content: JSX.Element;
  menuItem: MenuType;
}

const MenuItemAccordion = (props: MenuItemAccordionProps) => {
  const { summaryContent, content, menuItem } = props;
  const containerSyle = menuItem.featured ? featuredStyle : {};
  const productImage = appendBaseUrlToImage(menuItem.image);

  return <Accordion className={classes.accordion} style={containerSyle}>
    <AccordionSummary
      expandIcon={<ExpandMore className={classes.expandIcon} elevation={24} />}
      aria-controls={`${menuItem.name}_${menuItem.id}-content`}
      id={`${menuItem.name}_${menuItem.id}-header`}
      className={classes.summaryRoot}
    >
      <div className={classes.summary}>
        <img src={productImage} alt="" />
        {summaryContent}
      </div>
    </AccordionSummary>
    <AccordionDetails className={classes.details}>

      {content}

    </AccordionDetails>
  </Accordion >
}


export default MenuRow;
