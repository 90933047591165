import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Fade from '@mui/material/Fade';

export interface SimpleSnackbarProps {
  open: boolean;
  setOpen: Function;
  message: ReactNode;
  timeOutSnackBar?: number;
  style?: React.CSSProperties;
}

const SimpleSnackbar = (props: SimpleSnackbarProps) => {
  const { open, setOpen, message, timeOutSnackBar, style, } = props;
  
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  
  const ActionElem = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const contentStyle: React.CSSProperties = {
    ...style,
  }

  return (
    <div>
      <Snackbar
        open={open}
        TransitionComponent={Fade}
        autoHideDuration={timeOutSnackBar || 6000}
        onClose={handleClose}
      >
        <SnackbarContent message={message} action={ActionElem} style={contentStyle} />
      </Snackbar>
    </div>
  );
}

export default SimpleSnackbar;