import axios from "axios"
import { baseUrl } from "../serverConfig"

export interface BizLabel {
  businessLabel: string;
  businessLabelDesc: string;
  businessLabelId: number;
  createDate: string;
  updateDate: string;
  visitsCount: number;
}

export const getBizData = async (labelIds: string | number = 0, userId = 0) => {
  const uri = `${baseUrl}/rest/business/businessList`;
  const bizListResults = await axios.get(`${uri}/${labelIds}/${userId}`);
  return bizListResults.data;
}

export const getLabels = async () => {
  const uri = `${baseUrl}/rest/business/businessLabelList`;
  const labelResults = await axios.get(`${uri}`);
  return labelResults.data;
}

