import { FC } from 'react';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './scss/MaterialOverride.scss';
import useTheme from '@mui/material/styles/useTheme';


interface CheckboxProps {
  checkOnChange: (newVal: boolean) => void;
  controlLabel: string;
  isChecked: boolean;
  boldLabel?: boolean;
  disabled?: boolean;
}



const CheckboxLabels: FC<CheckboxProps> = (props) => {
  const {
    checkOnChange, controlLabel,
    isChecked, disabled
  } = props;

  const theme = useTheme();

  const handleChange = (event: any) => {
    const newVal = event.target.checked;
    checkOnChange(newVal);
  };

  const checkbox = <Checkbox size="small"
    sx={{
      color: theme.palette.primary.main,
      transform: "scale(1.2)"
    }}
    checked={isChecked}
    onChange={handleChange}
    name="choiceVal"

  />;

  if (controlLabel === "Blonde") {
    console.log(disabled);
  }

  return (
    <FormControlLabel
      control={checkbox}
      disabled={disabled}
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      label={
        <Typography
          fontSize='14px'
          fontWeight={400}
          color='#49454F'
        >
          {controlLabel}
        </Typography>
      }
    />
  );
}

export default CheckboxLabels;