import classes from "./Account.module.scss";

const SignInSeparator: React.FC = () => {
  return (
    <div className={classes.separator}>
      <div className={classes.separator_line} />
      <span className={classes.separator_label}>OR</span>
      <div className={classes.separator_line} />
    </div>
  );
};

export default SignInSeparator;