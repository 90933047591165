import { MouseEventHandler } from "react";
import { useBizTheme } from "../../hooks/useBizTheme";
import StyledButton from "../StyledComponent/StyledButton";
import classes from "./ConfirmOrder.module.scss";
import { Button } from "@material-ui/core";
import AppButton from "../AppButton/AppButton";

interface CreditPayButtonProps {
    handleOrderSubmission: MouseEventHandler<HTMLButtonElement>;
    payableAmt: number;
}

const CreditPayButton = (props: CreditPayButtonProps) => {

    return <AppButton
        variant="contained"
        color="primary"
        className={classes.buttonWrap}
        onClick={props.handleOrderSubmission}
    >
        Pay With Store Credit
    </AppButton>
}

export default CreditPayButton;