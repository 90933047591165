import classes from "./Announcement.module.scss";
import Popup from "../Popup/Popup";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { getCookie, setCookieHours } from "../../common/cookies";
import { OrderMode } from "../../constants";
import { getOrderMode } from "../../common/app";
import { useParams } from "react-router";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { Typography, useMediaQuery } from "@mui/material";

export const AnnouncementPopup: React.FC = () => {
  const matchSmallScreen = useMediaQuery("(max-width:600px)");
  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const appViewMode = getOrderMode(viewMode);
  const isKioskViewMode = appViewMode === OrderMode.kiosk;

  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const announcement = businessInfo?.announcement ?? false;
    const needShow = announcement && announcement.show === "yes";
    const cookieName = `${businessInfo?.name}-announcement`;
    const hasShown = getCookie(cookieName);
    setOpen(!hasShown && needShow);
  }, [businessInfo]);

  const handleClose = () => {
    if (isKioskViewMode === false)
      setCookieHours(`${businessInfo?.name}-announcement`, true, 1);

    setOpen(false);
  };

  const title = (<div className={classes.title}>
    <FontAwesomeIcon icon={faBullhorn} className={classes.title_icon} />
    <Typography variant="h5">
      Announcement
    </Typography>
  </div>)

  const content = (
    <Typography className={classes.content}
      dangerouslySetInnerHTML={{
        __html: businessInfo ? businessInfo.announcement.message : ""
      }}>
    </Typography>
  );

  return (
    <Popup
      handleClose={handleClose}
      isOpen={isOpen}
      title={title}
      popupStyle={{ 
        border: "none", 
        maxWidth: matchSmallScreen  ? "unset": "460px",
        width: "80%",
      }}
      content={content}
      onOk={handleClose}
      okLabel="Close"
    />
  );
};

export default AnnouncementPopup;
