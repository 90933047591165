import { PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect } from "react";
import { CSSProperties } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import MenuDocument from "./MenuDocument";

const PdfPage = () => {
  const {
    businessInfo, foodsCategories, productsInfo
  } = useTypedSelector(state => state.businessInfo);

  useEffect(() => {

  }, [])

  const pdfWrapStyles: CSSProperties = {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  if (!businessInfo || !foodsCategories || !productsInfo) return <></>;

  const menuDoc = <MenuDocument
    businessInfo={businessInfo}
    foodsCategories={foodsCategories}
    productsInfo={productsInfo}
  />

  const bizName = businessInfo.name;
  return (
    <section style={pdfWrapStyles}>
      <PDFDownloadLink
        document={menuDoc}
        fileName={`${bizName}.pdf`}>
        {({ blob, url, loading, error }) => {
          if (error) {
            console.error(error);
            return 'There is some error...';
          }
          if (loading) {
            return 'Loading document...';
          }

          // if (url) {
          //   const link = document.createElement('a');
          //   link.href = url;
          //   link.download = `${bizName}.pdf`;
          //   link.dispatchEvent(new MouseEvent('click'));
          // }

          return `Download ${bizName} PDF!`;
        }}
      </PDFDownloadLink>
      {/* <PDFViewer 
        height="100%"
        width="100%"
        showToolbar={true}
      >
        {menuDoc}
      </PDFViewer> */}
    </section>
  );
}

export default PdfPage;