import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import appLocalStatesReducer from "./appLocalStatesReducer";
import businessInfoReducer from "./businessInfoReducer";
import displayedMenuReducer from "./displayedMenuReducer";
import orderReducer from "./orderReducer";
import receiptReducer from "./receiptReducer";
import trackReducer from "./trackReducer";

const reducers = combineReducers({
  businessInfo: businessInfoReducer,
  displayedMenuCategory: displayedMenuReducer,
  orderDetails: orderReducer,
  trackInfo: trackReducer,
  appLocalStatus: appLocalStatesReducer,
  receiptSuccess: receiptReducer,
  account: accountReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
