import React, {  } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getFont } from "../../common/themeUtils";

export interface StyledComponentProps {

}

const StyledComponent: React.FC<StyledComponentProps> = (props) => {
  const { children } = props;
  const { businessInfo } = useTypedSelector(state => state.businessInfo);

  const clones = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const currStyle = child?.props.style ?? {};
      return React.cloneElement(child, { 
        style: { 
          ...currStyle,
          fontFamily: getFont(businessInfo) 
        } 
      });
    }

    return child;
  });

  return (<>
    {clones}
  </>);
}

export default StyledComponent;