import { useEffect, useCallback } from 'react';
import { useTypedSelector } from './useTypedSelector';
import { useActions } from './useActions';
import { useHistory } from 'react-router-dom';

// Detect Safari and iOS devices
const isOnIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const useExitPrompt = () => {
  const history = useHistory();
  const { enableBrowserBack } = useTypedSelector((state) => state.appLocalStatus);
  const { setShowBrowserBackModal } = useActions();

  // Function to handle back navigation
  const onBefore = useCallback((event) => {
    if (enableBrowserBack) {
      const isPopstateEvent = event.type === 'popstate';
      const e = event || window.event;
      e.preventDefault();

      if (isPopstateEvent) {
        const confirmExit = window.confirm("Changes you made may not be saved. Do you really want to leave?");
        if (!confirmExit) {
          history.go(1);  // Prevent the back navigation
        } else {
          setShowBrowserBackModal(false);
        }
      } else {
        if (e) e.returnValue = ''; // For beforeunload
        return ''; // Required by some browsers to show confirmation dialog
      }
    }
    return null;
  }, [enableBrowserBack, history, setShowBrowserBackModal]);

  // Initialize back button prevention
  const initBackButtonHandler = useCallback(() => {
    // Push initial state to history to prevent navigation back
    window.history.pushState(null, document.title, window.location.href);

    // Add event listeners
    window.addEventListener('popstate', onBefore);  // Handle back button
    if (isSafari || isOnIOS) {
      // For Safari (iOS/macOS), use 'pagehide' to block swipe gestures
      window.addEventListener('pagehide', onBefore);
    } else {
      // For Chrome and other browsers, use 'beforeunload' to prevent unload actions
      window.addEventListener('beforeunload', onBefore);
    }
  }, [onBefore]);

  useEffect(() => {
    initBackButtonHandler();

    return () => {
      // Clean up event listeners on component unmount
      window.removeEventListener('popstate', onBefore);
      window.removeEventListener('pagehide', onBefore);
      window.removeEventListener('beforeunload', onBefore);
    };
  }, [initBackButtonHandler]);

  return null;  // This hook does not render anything
};

export default useExitPrompt;
