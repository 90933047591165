import { ActionType } from "../action-types";
import { Action } from "../actions";

interface UserCredentialState {
  email?: string;
  userId?: number;
}

interface AccountState {
  isSignedIn: boolean;
  credential: UserCredentialState;
  error: string | null;
  externalSignInError: string | null;
}

const initialState = {
  isSignedIn: false,
  credential: {},
  error: "",
  externalSignInError: ""
}

const reducer = (
  state: AccountState = initialState,
  action: Action
): AccountState => {
  switch (action.type) {
    case ActionType.SIGN_IN_SUCCESS:
      return { ...state, isSignedIn: true, credential: action.payload };
    case ActionType.SIGN_OUT:
      const emptyUser: UserCredentialState = {
        email: "",
        userId: undefined
      }
      return { ...state, isSignedIn: false, credential: emptyUser };
    case ActionType.SIGN_UP_ERROR:
    case ActionType.SIGN_IN_ERROR:
      case ActionType.SET_ACCOUNT_ERROR:
        return { ...state, error: action.payload };
    case ActionType.EXTERNAL_SIGN_IN_ERROR:
      return { ...state, externalSignInError: action.payload };
    case ActionType.CLEAR_ACCOUNT_ERRORS:
      return { ...state, error: "", externalSignInError: "" };
    case ActionType.RESET_PASSWORD_SENT:
      return state;
    case ActionType.SIGN_UP_SUCCESS:
    default:
      return state;
  }
};

export default reducer;
