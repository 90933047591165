import React, { ReactNode } from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import { snackbarTimeoutInMillis } from '../../constants';

export interface SnackbarSettings {
  message: ReactNode;
  severity: "success" | "error";
}

export const createErrorToastSetting = (msg): SnackbarSettings => {
  return {
    severity: "error",
    message: msg,
  }
}

export const createSuccessToastSetting = (msg): SnackbarSettings => {
  return {
    severity: "success",
    message: msg,
  }
}


export const defaultErrorSetting = createSuccessToastSetting('Something went wrong, please try again later.');

export interface SnackbarProps {
  open: boolean;
  autoHide?: boolean;
  setOpen: Function;
  message: ReactNode;
  severity?: AlertProps['severity'];
  timeOutSnackBar?:number;
}

const Snackbar: React.FC<SnackbarProps> = (props) => {
  const { open, setOpen, message, autoHide, severity = "info", timeOutSnackBar } = props;

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const autoHideDuration =timeOutSnackBar ? timeOutSnackBar : (autoHide ? snackbarTimeoutInMillis : null);
  return (
    <MuiSnackbar
    style={{transform:"none"}}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      message={message}
    // action={
    //   <React.Fragment>
    //     <Button color="secondary" size="small" onClick={handleClose}>
    //       CLOSE
    //     </Button>
    //     <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
    //       <CloseIcon fontSize="small" />
    //     </IconButton>
    //   </React.Fragment>
    // }
    >
      <Alert onClose={handleClose}
        variant="filled"
        elevation={6}
        severity={severity}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
export default Snackbar;