import * as React from 'react';
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
} from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

type QuantityInputProps = NumberInputProps & {
  min: number,
  defaultValue?: number,
  disableInput?: boolean,
  disableIncrement?: boolean,
  disableDecrement?: boolean,
  onInputChange: React.ChangeEventHandler<HTMLInputElement>,
  onChange: (event: React.FocusEvent | React.PointerEvent | React.KeyboardEvent, value: number | undefined | null) => void,
}

const NumberInput = function CustomNumberInput(props: QuantityInputProps, ref: React.ForwardedRef<HTMLDivElement>,) {

  const { disableInput, disableIncrement, disableDecrement, } = props;

  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        input: {
          disabled: disableInput,
          inputMode: 'none',
          readOnly: true,
        },
        incrementButton: {
          children: <AddIcon fontSize="small" />,
          className: 'increment',
          disabled: disableIncrement
        },
        decrementButton: {
          children: <RemoveIcon fontSize="small" />,
          disabled: disableDecrement
        },
      }}
      {...props}
      ref={ref}
    />
  );
};

export default React.forwardRef(NumberInput);

const StyledInputRoot = styled('div')(
  ({ theme }) => `
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: ${theme.palette.text.primary};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  `,
);

const StyledInput = styled('input')(
  ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    border-radius: 8px;
    margin: 0 8px;
    padding: 5px 6px;
    outline: 0;
    min-width: 0;
    width: 2rem;
    text-align: center;
    color: ${theme.palette.primary.main};
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.primary.main};
    box-shadow: 0px 2px 4px ${theme.palette.light05
    };
  `,
);

const StyledButton = styled('button')(
  ({ theme }) => `
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    line-height: 1.5;
    border: 1px solid;
    border-radius: 999px;
    border-color: ${theme.palette.primary.main};
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    width: 24px;
    height: 24px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      cursor: pointer;
    }
  
    &:focus-visible {
      outline: 0;
    }
  
    &.increment {
      order: 1;
    }
      
    &:disabled {
      background-color: ${theme.palette.primary.main}55;
    }
  `,
);