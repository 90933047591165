import axios from "axios";
import { baseUrl } from "../serverConfig";

interface GetRewardsState {
  serviceProviderUserId: string;
  serviceProviderEmail: string;
  costumerEmail: string;
}

export const getUserRewards = async (data: GetRewardsState) => {
  const { serviceProviderUserId, serviceProviderEmail, costumerEmail } = data;

  try {
    return await axios.get(`${baseUrl}/rest/customerLoyalty/stampCard/${serviceProviderUserId}/${serviceProviderEmail}/${costumerEmail}`);
  } catch (error) {
    throw error;
  }
}

interface GiftCardActivationParams {
  serviceProviderUserId: string;
  giftCardNum: string;
  giftCardPin: string;
  activatorEmail: string;
}

export const activateGiftCard = async (data: GiftCardActivationParams) => {
  const { serviceProviderUserId, giftCardNum, giftCardPin, activatorEmail } = data;

  const endpoint = baseUrl + '/rest/giftCard/activate';
  try {
    return await axios.get(`${endpoint}/${serviceProviderUserId}/${giftCardNum}/${giftCardPin}/${activatorEmail}`);
  } catch (error) {
    throw error;
  }
}