import Popup, { PopupPropsBase } from "../Popup/Popup";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { LocationSelectorUrlParams } from "../../pages/ordering/ServiceProviderRoute";

interface SignInPopupProps {
  isOpen: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: PopupPropsBase["handleClose"],
  returnUrl?: string,
  signInRedirectUrl?: string,
  message?: string,
}

const SignInPopup = (props: SignInPopupProps) => {
  const { isOpen, setOpen, handleClose, returnUrl, signInRedirectUrl } = props;
  const { serviceProviderUserId } = useParams<LocationSelectorUrlParams>();
  const { url } = useRouteMatch();

  const popupContent = (<>
    <Link to={{
      pathname: `${serviceProviderUserId ? `/${serviceProviderUserId}` : url}/account/signIn`,
      state: { returnUrl, signInRedirectUrl }
    }}>
      Sign in here
    </Link>
    <div>
      {props.message || "To order this item, you must Sign-in!"}
    </div>
  </>)

  return (
    <Popup
      handleClose={handleClose}
      isOpen={isOpen}
      content={popupContent}
      title="Please Sign In"
    />
  );
}

export default SignInPopup;