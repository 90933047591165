import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { getFont } from "../../common/themeUtils";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import classes from "./Popup.module.scss";
import { IconButton, Typography } from "@material-ui/core";
import { Close } from '@material-ui/icons';
import AppButton from "../AppButton/AppButton";

export interface PopupPropsBase extends PropsWithChildren<Object> {
  isOpen: boolean;
  isFullScreen?: boolean;
  popupStyle?: React.CSSProperties;
  onClickElement?: React.ReactNode;
  shouldCloseOnOverlayClick?: boolean;
  showHeaderCloseBtn?: boolean;
  handleClose: Modal.Props["onRequestClose"];
}

interface CustomBodyProps extends PopupPropsBase {
  body: React.ReactNode;
  content?: never;
  header?: never;
  title?: never;
  titleWrapStyles?: never;
  headerStyle?: never;
  contentStyle?: never;
  footer?: never;
  footerStyles?: never;
  onOkay?: never;
  onlyOkButton?: never;
}

interface DefaultPopupProps extends PopupPropsBase {
  body?: never;
  content?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  title?: React.ReactNode;

  footerStyles?: React.CSSProperties;
  titleWrapStyles?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  okBtnStyle?: React.CSSProperties;

  okLabel?: string;
  onOk?: React.MouseEventHandler<Element>;
  onlyOkButton?: boolean;
}

type PopupProps = CustomBodyProps | DefaultPopupProps;

const Popup: FC<PopupProps> = (props) => {
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const {
    onClickElement,
    isOpen,
    handleClose,
    popupStyle,
    isFullScreen = false,
  } = props;
  const { shouldCloseOnOverlayClick = true, showHeaderCloseBtn = true } = props;
  const { body } = props as CustomBodyProps;
  const {
    header, titleWrapStyles, titleStyle, content, contentStyle,
    title, footer, footerStyles, okLabel, onOk, okBtnStyle, onlyOkButton = true,
  } = props as DefaultPopupProps;
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const overlayStyle: React.CSSProperties = {
    zIndex: 1301,
  };

  const fullScreenCss: React.CSSProperties = isFullScreen
    ? {
      width: "100%",
      height: "100%",
      maxHeight: 'unset',
    }
    : {};

  const modalContentStyles: React.CSSProperties = {
    borderColor: businessInfo?.theme.color,
    fontFamily: getFont(businessInfo),
    ...fullScreenCss,
    ...popupStyle,
  };

  const bodyWrapColors: React.CSSProperties = {
    borderColor: businessInfo?.theme.color,
    color: businessInfo?.theme.color,
  };

  const titleWrapCSS: React.CSSProperties = {
    backgroundColor: businessInfo?.theme.color ?? "black",
    color: "#FFF",
    ...titleWrapStyles,
  };

  const contentCSS: React.CSSProperties = {
    marginBottom: footer ? "" : "20px",
    ...contentStyle,
  };

  const okBtnCSS: React.CSSProperties = isFullScreen ? {
    position: "absolute",
    bottom: 0,
    ...okBtnStyle
  } : { ...okBtnStyle };

  const OkayBtn = useMemo(() => {
    if (!onlyOkButton) {
      return null;
    }

    return <div className={classes.okayFooter} style={okBtnCSS}>
      <AppButton onClick={onOk ?? handleClose} style={{ width: "200px" }}>
        {okLabel || "OK"}
      </AppButton>
    </div>

    // return <div className={classes.okayFooter} style={okBtnCSS}>
    //   <hr className={classes.separator} />
    //   <Typography onClick={onOk ?? handleClose} className={classes.button}>
    //     {okLabel || "OK"}
    //   </Typography>
    // </div>
  }, [onlyOkButton]);

  const HeaderContent = useMemo(() => {
    if (header) {
      return header;
    }

    return <section style={titleWrapCSS} className={classes.title}>
      {showHeaderCloseBtn && (
        <IconButton
          onClick={handleClose}
          className={classes.closeButton}
        >
          <Close style={{ color: "white" }} />
        </IconButton>
      )}
      <Typography style={titleStyle} variant="h5" className={classes.label}>
        {title}
      </Typography>
    </section>
  }, [header]);


  const FooterContent = useMemo(() => {
    if (!footer) {
      return OkayBtn;
    }

    return <section className={classes.body_footer} style={footerStyles}>
      {footer}
    </section>
  }, [header]);


  const PopupBody = useMemo(() => {
    if (body) {
      return body;
    }

    return <div style={bodyWrapColors} className={classes.body}>
      {HeaderContent}
      <section style={contentCSS} className={classes.body_content}>
        {content || "Add some content!"}
      </section>
      {FooterContent}
    </div>
  }, [body, content, HeaderContent, FooterContent]);

  return (
    <div className={classes.popup_container}>
      {onClickElement}
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        ariaHideApp={false}
        className={classes.popup}
        style={{
          content: modalContentStyles,
          overlay: overlayStyle,
        }}
      >
        {PopupBody ?? (
          <div className={classes.popup_content}>
            {props.children ? props.children : body}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Popup;
