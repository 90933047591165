import axios from "axios";
import { baseUrl } from "../serverConfig";

export interface UserLoginState {
  emailAsUserId: string,
  password: string,
  enterChoice?: string,
  serviceProviderUserId?: number,
}

export const loginWithEmail = async (credential: UserLoginState) => {
  try {
    return await axios.post(`${baseUrl}/rest/user/login/light`, credential);
  } catch (error) {
    throw error;
  }
}

export const loginFromFirebase = async (credential: UserLoginState) => {
  try {
    return await axios.put(`${baseUrl}/rest/user/token`, credential);
  } catch (error) {
    throw error;
  }
}

export const signUpOnServer = async (credential: UserLoginState) => {
  try {
    return await axios.put(`${baseUrl}/rest/user/`, credential);
  } catch (error) {
    throw error;
  }
}

export const sendResetEmail = async (credential: UserLoginState) => {
  try {
    return await axios.post(`${baseUrl}/rest/user/forgotPassword`, credential);
  } catch (error) {
    throw error;
  }
}

export interface ChangePasswordState {
  confirmedPassword: string;
  emailAsUserId: string;
  newPassword: string;
  password: string;
}

export const sendPasswordChange = async (data: ChangePasswordState) => {
  try {
    return await axios.post(`${baseUrl}/rest/user/changePassword`, data);
  } catch (error) {
    throw error;
  }
}
