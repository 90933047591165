import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface CircularProgressWithLabelProps extends CircularProgressProps {
  value: number;
  maxValue: number;
}

const CircularProgressWithLabel = (props: CircularProgressWithLabelProps) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress {...props}
        variant="determinate"
        value={props.value / props.maxValue * 100}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {props.value}/{props.maxValue}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;