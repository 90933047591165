import { useTypedSelector } from './useTypedSelector';
import { grey } from "@mui/material/colors";

export const useBizTheme = () => {
  const businessInfo = useTypedSelector(store => store.businessInfo?.businessInfo);

  if (!businessInfo){
    return { color: grey[900] };
  }
  
  if (!businessInfo.theme) {
    return { color: grey[900] };
  }

  return businessInfo.theme;
};
