import classes from "./FoodCourtComps.module.scss";
import React, { useState } from "react";
import { ChevronLeft } from '@material-ui/icons';
import { useHistory } from "react-router";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { BizLabel } from "../../api/foodCourt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  Button, Chip, Divider, Drawer, IconButton, List, ListItem, ListItemText, Toolbar,
} from "@material-ui/core";
import FoodCourtBanner from "./FoodCourtBanner";
import Popup from "../Popup/Popup";
import AboutUs from "./AboutUs";

const addBizFormUrl = "https://forms.gle/e5S4cJ3ypcbGUxG6A";

interface FoodCourtHeaderProps {
  setLabelsList: Function;
  labelsList: BizLabel[];
}

const FoodCourtHeader: React.FC<FoodCourtHeaderProps> = (props): JSX.Element => {
  const { labelsList, setLabelsList } = props;
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isAboutPopupOpen, setAboutPopupOpen] = useState(false);
  const [isScrollOnTop, setIsScrollOnTop] = useState(true);
  const history = useHistory();
  const isSignedIn = useTypedSelector(state => state.account.isSignedIn);
  const { signOut } = useActions();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  window.onscroll = (event) => {
    const isOnTop = event.target.scrollingElement.scrollTop < 1;
    setIsScrollOnTop(isOnTop);
  }

  return (
    <>
      <div className={classes.banner_background} ></div>
      <div className={classes.toolbar_sticky}
        style={{
          backgroundColor: isScrollOnTop ? "" : "black",
          transitionDuration: ".3s",
        }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"

            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={classes.toolbar_menuButton}
          >
            <FontAwesomeIcon icon={faBars}
              style={{
                transitionDuration: ".3s",
              }}
              color={isScrollOnTop ? "black" : "white"}
            />
          </IconButton>

          <div className={classes.toolbar_title}
            style={{
              color: isScrollOnTop ? "black" : "white",
              transitionDuration: ".3s",
            }}
          >
            Eats & Treats in Metro Vancouver
          </div>

          <Chip
            className={classes.toolbar_signInBtn}
            label={isSignedIn ? "Sign-out" : "Sign-in"}
            onClick={(e) => isSignedIn ? signOut() : history.push('/account/signIn')}
            clickable
          />

        </Toolbar>
      </div>

      <FoodCourtBanner
        drawerOpen={isDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
        setLabelsList={setLabelsList}
        labelsList={labelsList}
      />

      <Popup
        handleClose={e => setAboutPopupOpen(false)}
        isOpen={isAboutPopupOpen}
        title="About Us"
        content={<AboutUs />}
        okLabel="Close"
      />

      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={isDrawerOpen}
        onBackdropClick={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawer_header}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List className={classes.drawer_list}>

          <Button variant="contained"
            onClick={e => isSignedIn ? signOut() : history.push('/account/signIn')}
            className={classes.drawer_signIn}
          >
            {isSignedIn ? "Sign-out" : "Sign-in"}
          </Button>

          <ListItem button
            onClick={e => window.open(addBizFormUrl, '_blank')}
          >
            <ListItemText primary="Add your restaurant" />
          </ListItem>

          <ListItem button
            onClick={e => setAboutPopupOpen(true)}
          >
            <ListItemText primary="About" />
          </ListItem>

        </List>
      </Drawer>

    </>
  );
};

export default FoodCourtHeader;