import { useState } from 'react';
import { appendBaseUrlToImage } from '../common/image';
import { calculateItemTotal } from '../common/price/price';
import { MenuItem } from '../Types/Types';

export const useItemDetails = (itemArg: MenuItem) => {

  const [item] = useState<MenuItem>(itemArg);
  const image = appendBaseUrlToImage(item.productImage || item.image);

  const { fullPriceWithCust, itemTotal, fullPrice, discountedAmt, customizationAmt } = calculateItemTotal(item)
  return {
    image,
    customizationAmt,
    discountedAmt,
    fullPrice,
    fullPriceWithCust, 
    itemTotal,
  };
};
