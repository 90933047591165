import { BusinessInfo, YesNo } from './../Types/Types';
import { OrderMode } from "./../constants";
import { isNumber } from './price/price';
export const getMobileOperatingSystem = () => {
  var userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;
  if (/android/i.test(userAgent)) {
    return "android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iphone";
  }
  return "other";
};

export const isDiningOptionAvailable = (diningOption?: YesNo) =>
  diningOption === "yes";

export const getOrderMode = (viewMode: string): OrderMode => {
  if (isNumber(viewMode)) return OrderMode.table;

  const key = Object.keys(OrderMode).find(
    (key) => OrderMode[key] === viewMode
  );

  if (!key) return OrderMode.normal;

  return OrderMode[key];
};

export const isOrderingDisabled = (bizJson: BusinessInfo) => {
  if (bizJson.storeTemporarilyClosed) {
    return true;
  }

  if (!bizJson.storeOpen) {
    return true;
  }

  return false;
}