import classes from "./RecommendPage.module.scss";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import Snackbar, { createSuccessToastSetting, defaultErrorSetting, SnackbarSettings } from "../../components/Snackbar/Snackbar";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import {useState } from "react";
import { useHistory } from "react-router";
import { validateEmail } from "../../common/account/account";
import { getCookie } from "../../common/cookies";
import { getFormattedPhoneNumber, normalizeFormattedPhoneNumber, validatePhone } from "../../common/phoneUtils";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { baseUrl } from "../../serverConfig";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DoneIcon from '@mui/icons-material/Done';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import SmsIcon from '@mui/icons-material/Sms';
const successSetting: SnackbarSettings = {
  severity: "success",
  message: "Recommendation has been sent to ",
}

const RecommendPage = () => {
  const history = useHistory();
  const { businessInfo } = useTypedSelector(store => store.businessInfo);
  const [mode, setMode] = useState<"email" | "sms">("email");
  const [email, setEmail] = useState("");
  const [sms, setSms] = useState("");
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [isContactEmpty, setIsContactEmpty] = useState(false);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [hasValidationError, setHasValidationError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbarSetting, setSnackbarSetting] = useState<SnackbarSettings>(successSetting);

  const goBack = (e) => {
    history.goBack();
  }

  const validateForm = (): boolean => {

    if (mode === "email") {
      const trimmedEmail = email.trim();
      if (!email) {
        setIsContactEmpty(true);
        return false;
      } else {
        setIsContactEmpty(false);
        if (!validateEmail(trimmedEmail)) {
          setHasValidationError(true);
          return false;
        } else {
          setHasValidationError(false);
        }
      }
    }

    if (mode === "sms") {

      if (!sms) {
        setIsContactEmpty(true);
        return false;
      } else {
        setIsContactEmpty(false);
        if (!validatePhone(sms)) {
          setHasValidationError(true);
          return false;
        } else {
          setHasValidationError(false);
        }
      }
    }

    if(!name.trim()) {
      setIsNameEmpty(true);
      return false;
    }else {
      setIsNameEmpty(false);
    }

    return true;
  }

  const submit = async (e) => {

    if (!validateForm()) return;

    const userId = getCookie("userId");
    const normalizedPhone = normalizeFormattedPhoneNumber(sms);
    const url = `${baseUrl}/rest/link/refer`;

    const notesPart = notes ? `${notes} - ` : "";
    const body = {
      fromUserId: userId ? Number(userId) : 999999,
      sharedLinkEmail: businessInfo?.serviceProviderUserEmail,
      validEmail: email.trim(),
      validPhone: normalizedPhone,
      shareMethod: mode === "email" ? "externalEmail" : "externalPhone",
      message: notesPart + name
    };

    setIsSubmitting(true);
    try {
      const res = await axios.post(url, body);
      const responseMsg = res.data;
      if (responseMsg === "error") {
        setSnackbarSetting(defaultErrorSetting);
        setIsSubmitting(false);
      } else if (responseMsg === "success") {
        setTimeout(() => {
          setEmail("");
          setSms("");
          setNotes("");
          setName("");

          setIsSubmitting(false);

          let contact = mode === "email" ? email : "";
          contact = mode === "sms" ? sms : contact;
          const success = createSuccessToastSetting(`Recommendation has been sent to ${contact}`);
          setSnackbarSetting(success);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setOpenSnackbar(true);
      setTimeout(() => setIsSubmitting(false), 1000);
    }

  }

  const toggleMode = (e: React.MouseEvent<HTMLButtonElement>) => {
    const fieldName = e.currentTarget.name as ("email" | "sms");
    setIsContactEmpty(false);
    setHasValidationError(false);
    setMode(fieldName);
  }

  const getMode = (modeToCheck): string => {
    return mode === modeToCheck ? classes.active : classes.default;
  };
  return (
    <div 
      className={classes.page}
      
      >
      <Box
              onClick={goBack}
              sx={{
                display: "flex",
                alignItems: "center",
                width:'100%',
                gap:'12px',
                padding: "10px 10px",
                cursor: "pointer",
                backgroundColor: "primary.main",
                color: "#fff",
                minHeight: "50px",    
              }}
            > 
              <ArrowBackIcon sx={{ color: "#fff", fontSize
              : "24px"
               }} />
              <span
                style={{
                  color: "#fff",
                  fontSize: "20px",
                  fontWeight: 400,
                }}
              >
                Back to {businessInfo?.name}
              </span>
            </Box>
      {isSubmitting && <Loader />}
      <div className={classes.title}>
        <Typography variant="h2" sx={{
          fontSize: '32px',
          fontWeight: '500',
          padding:'.5rem 2rem'
        }}>
        Recommend {businessInfo?.name}

        </Typography>
      </div>

      <div className={classes.body}>
      
        <div>
          <ButtonGroup
            disableElevation
            variant="contained" >
            <Button name="email"
              sx={{ backgroundColor: mode === "email" ? 'light05' : "white",
                  borderRadius: "50px",
                  border: "1px solid #1D192B",
                  color:'#1D192B',
                  display: "flex",
                  gap: "5px",
                  width:'100px',
                  '&:hover': {
                    backgroundColor: mode === "email" ? 'light05' : "white"
                  }
               }}
              onClick={toggleMode}
              >
                {mode === "email" && <DoneIcon />} 
                
                <Typography color='#1D192B'>
                  Email
                </Typography>
            </Button>
            <Button name="sms"
              onClick={toggleMode}
              sx={{ backgroundColor: mode === "sms" ? 'light05' : "white",
                borderRadius: "50px",
                border: "1px solid #1D192B",
                color:'#1D192B',
                width:'100px',
                gap:'10px',
                '&:hover': {
                    backgroundColor: mode === "sms" ? 'light05' : "white"
                  }
             }}
              >
                <Typography color='#1D192B'>
                  SMS
                </Typography>
                {mode === "sms" && <DoneIcon />}
            </Button>
          </ButtonGroup>
        </div>

        {mode === "email" ?
          <div 
            style={{
              margin:'0 5%'
            }}
          >
            <TextField
              id="emailInput"
              type="text"
              variant="standard"
              placeholder="Your friend's email"
              label="Your friend's email*"
              defaultValue='Your friend email'
              value={email}
              sx={{
                color: '#1D192B',
                width: '100%'
              }}
              onChange={e => setEmail(e.target.value.trim())}

              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          : <div 
            style={{
              margin:'0 5%'
            }}
            >
            <TextField
              id="phoneInput"
              type="text"
              variant="standard"
              placeholder="(123) 456 7890"
              label="Your friend's phone number*"
              value={getFormattedPhoneNumber(sms)}
              sx={{
                color: '#1D192B',
                width: '100%'
              }}
              onChange={e => {
                const normalized = normalizeFormattedPhoneNumber(e.target.value);
                setSms(normalized)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SmsIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>}

        <div 
          style={{
            margin:'0 5%'
          }}
          >
          <TextField
            id="nameInput"
            type="text"
            variant="standard"
            placeholder="Your name"
            label="Your name*"
            sx={{
              color: '#1D192B',
              width: '100%'
            }}
            inputProps={{
              maxlength: 50,
            }}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>


        <div 
          style={{
            margin:'0 5%'
          }}
          >
          <TextField
            id="noteInput"
            type="text"
            variant="standard"
            placeholder="Your message"
            label="Your message"
            sx={{
              color: '#1D192B',
              width: '100%'
            }}
            multiline
            inputProps={{
              maxlength: 200,
            }}
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </div>

        <div className={classes.actions}>
          <Button
            style={{
              margin:'0 5%'
            }}
            onClick={submit}
            disabled={isSubmitting}
            color="primary"
            sx={{
              width: '100%',
              backgroundColor: 'primary.main',
              borderRadius: "100px",
              '&:hover': {
                backgroundColor: 'primary.main',
              
              }
            }}
            >
            <Typography variant="button" sx={{color:'#fff',  fontSize:'20px', fontWeight:'500'}}>
              Recommend
            </Typography>
          </Button>

        </div>


        {isNameEmpty && <div className={classes.error}>
          Your name is required for this action
        </div>}
        {isContactEmpty && <div className={classes.error}>
          A contact is required for this action
        </div>}
        {hasValidationError && <div className={classes.error}>
          {mode === "email" && "Incorrect email format"}
          {mode === "sms" && "Incorrect phone format"}
        </div>}

        <Snackbar
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          autoHide={false}
          {...snackbarSetting}
        />
      </div>
    </div>
  )
}

export default RecommendPage;
