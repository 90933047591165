import { ArrowBackIos } from "@material-ui/icons"
import { IconButton, IconButtonProps } from "@mui/material"

type ChevronBackProps = {

} & IconButtonProps

export const ChevronBack = (props: ChevronBackProps) => {
    return <IconButton className={props.className}
        onClick={props.onClick}
        edge="start"
        sx={{
            width: '48px',
            height: '48px',
        }}
    >
        <ArrowBackIos style={{
            color: props.color ?? "white",
            width: 33,
            height: 28,
        }} />
    </IconButton>
}