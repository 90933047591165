import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { receiptPayload } from '../../Types/Types';
import { baseUrl } from "../../serverConfig";

 const sendReceiptToServer = (receiptPayload: receiptPayload) => {
		return async (dispatch: Dispatch<Action>) => {
			try {
				const { data } = await axios.put(`${baseUrl}/rest/order/receipt`, receiptPayload);
				
				dispatch({
				type: ActionType.SEND_RECEIPT_SUCCESS,
				payload: data.success,
				});
		
			} catch (err) {
				dispatch({
				type: ActionType.SEND_RECEIPT_ERROR,
				payload: err.message,
				});
			}
		};
  };
  
  export default sendReceiptToServer;