import { StyleSheet } from '@react-pdf/renderer';

const sectionStyle = {
  margin: 10,
  marginTop: 20,
}
const textBlockStyle = {
  fontSize: 10
}

const getHeaderStyle = (headerSize: 1 | 2 | 3 | 4): any => {
  const marginSize = 18 - (headerSize * 2);
  const fontSize = 36 - (headerSize * 6);
  return {
    fontWeight: 900,
    margin: marginSize,
    fontSize: fontSize,
  }
}

export const pdfStyles = StyleSheet.create({
  bizLogo: {
    width: 100,
    height: 100,
  },
  page: {
    textAlign: "center",
    // backgroundColor: '#E4E4E4',
  },
  bizInfo: {
    fontSize: 36,
    ...sectionStyle,
  },
  categoryWrap: {
  },
  categoryName: {
    ...getHeaderStyle(2),
    fontSize: 30,
    marginBottom: 0,
  },
  categorySub: {
    ...textBlockStyle,
  },
  productsList: {
  },
  pageNumber: {
    margin: "10px 5%",
    textAlign: "right",

  },
  // productSeparator: {
  //   border: 0,
  //   borderTop: "medium double #CCC",
  //   height:"1px",
  //   padding:"0",
  //   color: "#CCC",
  // },
  // productSeparatorContent: {
  //   content: "¶",
  //   position: "relative",
  //   top: "-0.7em",
  //   fontSize: "1.4em",
  //   padding: "0 0.3em",
  //   background: "white",
  // },
  productWrap: {
  },
  product: {
    ...getHeaderStyle(3),
    marginBottom: 0,
  },
  productSub: {
    ...textBlockStyle,
    margin: "0px 5%",
  },
  optionsList: {

  },
  optionsWrap: {
    textAlign: "justify",
    margin: "0px 60px",
  },
  productNamePrice: {
    // display: "flex",
    ...getHeaderStyle(4),
    flexDirection: 'row',
    justifyContent: "space-between",
  },
  optionNamePrice: {
    // display: "flex",
    ...getHeaderStyle(4),
    flexDirection: 'row',
    justifyContent: "space-between",
  },
  optionName: {
  },
  optionSub: {
    ...textBlockStyle,
  },
});
