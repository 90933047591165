import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

// Your web app’s Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyCU7tx0uxt_FievpcfBoiE3Gsdn-pcmJf4",
  authDomain: "relayfyrewards.firebaseapp.com",
  databaseURL: "https: //relayfyrewards.firebaseio.com",
  projectId: "relayfyrewards",
  storageBucket: "relayfyrewards.appspot.com",
  messagingSenderId: "530859399410",
  appId: "1:530859399410:web:ece7fb00c3f8b48866586b",
  measurementId: "G-NM6G47FJH0"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const fire = firebase;
export const auth = firebase.auth();

export const signInAppleWithPopup = () => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  return auth.signInWithPopup(provider);
}

export const signInGoogleWithPopup = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return auth.signInWithPopup(provider);
}

export const signInFacebookWithPopup = () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  return auth.signInWithPopup(provider);
}