import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router";
import { Loader } from "semantic-ui-react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { baseUrl } from "../../serverConfig";
import CateringSelection from "../../components/CateringSelection/CateringSelection";
import AccountRoute from "../account/AccountRoute";
import ActivateGiftPage from "../giftCard/ActivateGiftPage";
import LocationSelectorPage, { BizLocation } from "../locationSelector/LocationSelectorPage";
import PageNotFound from "../notFound/pageNotFound";
import RecommendPage from "../recommend/RecommendPage";

export interface LocationSelectorUrlParams {
  serviceProviderUserId: string;
  businessName: string;
}


const ServiceProviderRoute = () => {
  const { serviceProviderUserId, businessName } = useParams<LocationSelectorUrlParams>();
  const { setUserCredentials } = useActions();
  const { isSignedIn } = useTypedSelector(state => state.account);
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { setBizInfo } = useActions();
  const [isLoading, setIsLoading] = useState(true);
  const [addresses, setAddresses] = useState<BizLocation[]>([]);
  const [isPathLoadable, setIsPathLoadable] = useState(true);
  const { path, url } = useRouteMatch();



  useEffect(() => {
    if (!serviceProviderUserId) {
      setIsPathLoadable(false);
      return;
    };

    const fetchLocationInfo = async () => {
      const locationRes = await axios.get(`${baseUrl}/rest/business/businessLocationList/${serviceProviderUserId}`);
      const addressList = locationRes.data as BizLocation[];

      addressList.forEach(addr => {
        try {
          if (addr.json !== undefined) {
            addr.json = JSON.parse(addr.json as any);
          }
        } catch (error) {
          console.error("Parsing error", error, "| For", addr);
        }
      });

      setAddresses(addressList);
      if (addressList && addressList.length > 0 && addressList[0].json) {
        setBizInfo(addressList[0].json);
      }
      setIsLoading(false);
    }

    try {
      fetchLocationInfo();
    } catch (err) {
      console.error(err);
    }

    return () => setAddresses([]);
  }, [])

  useEffect(() => {
    if (!serviceProviderUserId) return;
    setUserCredentials();

  }, [serviceProviderUserId]);

  if (isLoading) return <Loader />;
  if (isPathLoadable === false) return <PageNotFound />;

  return (
    <Switch>
      <Route exact path={`${path}/catering`}>
        <CateringSelection addresses={addresses}/>
      </Route>
      <Route exact path={`${path}/activateGift`}>
        <ActivateGiftPage />
      </Route>
      <Route path={`${path}/recommend`}>
        <RecommendPage/>
      </Route>
      <Route path={`${path}/account`} >
        <AccountRoute />
      </Route>
      <Route path={`${path}/:businessName?`}>
        <LocationSelectorPage addresses={addresses} />
      </Route>

    </Switch>
  )
}

export default ServiceProviderRoute;