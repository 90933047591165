import React from 'react';
import classes from './BackDrop.module.scss';


interface BackDropProps {
  show: boolean;
  onClick?: Function;
}

const BackDrop: React.FC<BackDropProps> = (props) => {
  const { show, onClick, children } = props;

  const handleClick = (event) => {
    const className = event.target?.className;
    const isString = typeof className === "string";
    if (!isString || !className.startsWith("BackDrop_wrapper")) return;
    onClick && onClick();
  };

  return (<>
    {show &&
      <div onClick={handleClick} className={classes.wrapper}>
        {children}
      </div>}
  </>)
}

export default BackDrop
