import useExitPrompt from "../../hooks/useExitPrompt";
import { useEffect } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { useParams } from "react-router-dom";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";

const RefreshConfirmAlert = () => {
  useExitPrompt();
  const { businessName } = useParams<IPublicProfileRouteParams>();
  const { setEnableBrowserBackModal, setShowBrowserBackModal } = useActions();
  const orders = useTypedSelector((state) => state.orderDetails);

  useEffect(() => {
    const cartHasItems = orders.cart.length > 0;
    setEnableBrowserBackModal(cartHasItems)
  }, [orders.cart.length])

  return null;
};

export default RefreshConfirmAlert;
