import firebase from 'firebase';
import { Dispatch } from "redux";
import { credentialPayload } from "../../Types/Types";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { getCookie, setCookie, eraseCookie } from "../../common/cookies";
import { validateEmail } from "../../common/account/account";
import { loginWithEmail, loginFromFirebase, UserLoginState } from "../../api/user";
import {
  auth, signInAppleWithPopup, signInFacebookWithPopup, signInGoogleWithPopup
} from "../firebase/firebase";
import { store } from "../store";
import { isNumber } from "../../common/price/price";
import { userEmailKey, userIdKey } from '../../constants';
import { loadCreditInfo } from '.';

export const setUserCredentials = () => {
  return async (dispatch: Dispatch<Action>) => {
    const email = getCookie("userEmail");
    const userId = Number(getCookie("userId"));

    if (!email || !userId) {
      dispatch({
        type: ActionType.SIGN_IN_ERROR,
        payload: ""
      })
      return;
    }

    dispatch({
      type: ActionType.SIGN_IN_SUCCESS,
      payload: {
        email: email,
        userId: userId,
      }
    })
    dispatch({
      type: ActionType.SET_ORDER_USER_ID,
      payload: userId,
    })
  }
}

const setServiceProviderUserId = (userLogin: UserLoginState) => {
  const state = store.getState();
  const serviceProviderUserId = state.businessInfo.businessInfo?.serviceProviderUserId;
  if (serviceProviderUserId && isNumber(serviceProviderUserId)) {
    userLogin.serviceProviderUserId = Number(serviceProviderUserId);
  }
  return userLogin;
}


export const signInWithEmail = (data: credentialPayload) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      let userLogin = {
        emailAsUserId: data.signInId,
        password: data.password,
      };

      userLogin = setServiceProviderUserId(userLogin);

      const signInResult = await loginWithEmail(userLogin);

      if (signInResult.status === 204 && signInResult.data === "") {
        dispatch({
          type: ActionType.SIGN_IN_ERROR,
          payload: "Your email or password is incorrect"
        })
      }

      if (signInResult.status === 200) {
        dispatchSuccessActions(dispatch, signInResult.data);
      }
    } catch (err) {
      console.error(err);
    }
  }
}

export const signInWithApple = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const appleCredentials = await signInAppleWithPopup();

      const email = appleCredentials.user?.email;
      if (email && email !== null && email.includes("@privaterelay.appleid.com")) {
        dispatch({
          type: ActionType.EXTERNAL_SIGN_IN_ERROR,
          payload: "Oops.. You cannot sign-in with apple Id at this time. Please try a different login method."
        });
        return;
      }

      const userLogin = setServiceProviderUserId(getUserLogin(appleCredentials));
      const signInResult = await loginFromFirebase(userLogin);

      if (signInResult.status === 204 && signInResult.data === "") {
        dispatch({
          type: ActionType.EXTERNAL_SIGN_IN_ERROR,
          payload: "Sign in with Apple failed, please contact join@relayfy.com"
        })
      }

      if (signInResult.status === 200) {
        dispatchSuccessActions(dispatch, signInResult.data);
      }
    } catch (err) {
      dispatch({
        type: ActionType.EXTERNAL_SIGN_IN_ERROR,
        payload: "Sign in with Apple failed, please contact join@relayfy.com"
      })
      console.log(err);
    }
  }
}

export const signInWithGoogle = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const googleCredentials = await signInGoogleWithPopup();
      const userLogin = setServiceProviderUserId(getUserLogin(googleCredentials));

      const signInResult = await loginFromFirebase(userLogin);

      if (signInResult.status === 204 && signInResult.data === "") {
        dispatch({
          type: ActionType.EXTERNAL_SIGN_IN_ERROR,
          payload: "Sign in with Google failed, please contact join@relayfy.com"
        })
      }

      if (signInResult.status === 200) {
        dispatchSuccessActions(dispatch, signInResult.data);
      }
    } catch (err) {
      dispatch({
        type: ActionType.EXTERNAL_SIGN_IN_ERROR,
        payload: "Sign in with Google failed, please contact join@relayfy.com"
      })
      console.error(err);
    }
  }
}

export const signInWithFacebook = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const fbCredentials = await signInFacebookWithPopup();
      const userLogin = setServiceProviderUserId(getUserLogin(fbCredentials));

      const signInResult = await loginFromFirebase(userLogin);

      if (signInResult.status === 204 && signInResult.data === "") {
        dispatch({
          type: ActionType.EXTERNAL_SIGN_IN_ERROR,
          payload: "Sign in with Facebook failed, please contact join@relayfy.com"
        })
      }

      if (signInResult.status === 200) {
        dispatchSuccessActions(dispatch, signInResult.data);
      }
    } catch (err) {
      dispatch({
        type: ActionType.EXTERNAL_SIGN_IN_ERROR,
        payload: "Sign in with Facebook failed, please contact join@relayfy.com"
      })
      console.log(err);
    }
  }
}

export const setSignUpSuccess = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SIGN_UP_SUCCESS,
      payload: ""
    });
  }
}

export const signOut = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      await auth.signOut();
      dispatch({ type: ActionType.SIGN_OUT });
      dispatch({ type: ActionType.CLEAR_CREDIT_DISCOUNT });
      dispatch({ type: ActionType.CLEAR_ORDER_USER_ID });
      removeCredentials();
    } catch (error) {
      console.log(error);
    }
  }
}

export const resetPasswordSuccess = (emailAddress?: string) => {
  return async (dispatch: Dispatch<Action>) => {
    // try {

    //   const userLogin = {
    //     emailAsUserId: emailAddress,
    //     password: "",
    //     enterChoice: ""
    //   }

    //   const result = await sendResetEmail(userLogin);

    dispatch({
      type: ActionType.RESET_PASSWORD_SENT,
      payload: ""
    });
    // } catch (error) {
    //   console.log(error);
    // }
  }
}

export const clearAccountErrors = () => ({
  type: ActionType.CLEAR_ACCOUNT_ERRORS
})

export const setAccountError = (errorMessage) => {
  return {
    type: ActionType.SET_ACCOUNT_ERROR,
    payload: errorMessage
  }
}

export const validateUserEmail = (email: string) => {
  let errorMsg = "";
  if (!validateEmail(email)) {
    errorMsg = "Please enter a valid Email";
  }
  return {
    type: ActionType.SET_ACCOUNT_ERROR,
    payload: errorMsg
  }
}

interface ServerCredentials {
  userId: number;
  emailAsUserId: string;
}

const dispatchSuccessActions = (dispatch: Dispatch<Action>, credentials) => {
  dispatch({
    type: ActionType.SIGN_IN_SUCCESS,
    payload: getLoginData(credentials)
  });

  dispatch({
    type: ActionType.SET_ORDER_USER_ID,
    payload: credentials.userId
  });

  loadCreditInfo(undefined, dispatch);
  storeCredentials(credentials);
}

const storeCredentials = (credentials: ServerCredentials) => {
  setCookie(userIdKey, credentials.userId);
  setCookie(userEmailKey, credentials.emailAsUserId);
}

const getUserLogin = (credentials: firebase.auth.UserCredential) => {
  const user = credentials.user;
  const credential = credentials.credential as firebase.auth.OAuthCredential;
  return {
    emailAsUserId: user && user.email !== null ? user.email : "",
    password: credential.accessToken ?? "",
    enterChoice: "gtoken"
  }
}

const getLoginData = (data) => {
  return {
    ...data,
    email: data.emailAsUserId
  }
}

const removeCredentials = () => {
  eraseCookie("userEmail");
  eraseCookie("userId");
}
