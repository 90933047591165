import classes from './QrCode.module.scss';
import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { getQrCode, UrlMenuType } from "../../api/qrCode";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { bufferDataToBase64 } from '../../common/fileConverters';


const QrCode = () => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const { path } = useRouteMatch();
  const [qrImgUrl, setQrImgUrl] = useState("");
  const [headerText, setHeaderText] = useState("");

  const getMenuType = (viewMode: string): UrlMenuType | "" => {

    if (viewMode === "menu") {
      return "menuOnly"
    }

    if (path.toLowerCase() === "/:businessname/qrcode") {
      return "ordering";
    }

    const tableNumber = Number(viewMode);
    if (!isNaN(tableNumber)) {
      return tableNumber;
    }

    return "";
  }

  useEffect(() => {
    const fetchQrCode = async () => {
      if (!businessInfo?.serviceProviderUserId) return;

      const menuType = getMenuType(viewMode);

      if (!menuType) return;

      const res = await getQrCode({
        serviceProviderUserId: businessInfo.serviceProviderUserId,
        menuType: menuType,
      })

      if (res.status !== 200 || res.data === "error") return;
      
      try {
        const bufferData = res.data as ArrayBuffer;
        const base64 = bufferDataToBase64(bufferData);
        setQrImgUrl(`data:image/png;base64,${base64}`);
      } catch (error) {
        setHeaderText("Unfortunately, the requested Qr Code could not be retrieved.");
        console.log(error);
      }
    }

    fetchQrCode();

  }, [businessInfo?.serviceProviderUserId, viewMode])

  return (
    <section className={classes.qrCode_container}>

      <header className={classes.qrCode_header}>
        {headerText}
      </header>

      {qrImgUrl &&
        <img src={qrImgUrl}
          className={classes.qrCode_img}
          alt="qr-code-image"
        />}

    </section>
  )
}

export default QrCode;


