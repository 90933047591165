import classes from './ActivateGift.module.scss';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import { activateGiftCard } from '../../api/rewards';
import { giftActivationError } from '../../constants';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Header from '../../components/Header/Header';
import StyledButton from '../../components/StyledComponent/StyledButton';
import Snackbar, {
  createErrorToastSetting, createSuccessToastSetting, defaultErrorSetting
} from '../../components/Snackbar/Snackbar';
import { useActions } from '../../hooks/useActions';
import SignInPopup from '../../components/SignInPopup/SignInPopup';
import { Link } from 'react-router-dom';


const successSetting = createSuccessToastSetting('Activation Successful');
const giftErrorSetting = createErrorToastSetting(giftActivationError);

const tripToDigitsOnly = (value) => value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');


interface ActivateGiftPageUrlParams {
  serviceProviderUserId: string;
}
interface ActivateGiftPageProps {

}

const ActivateGiftPage = (props: ActivateGiftPageProps) => {
  const { serviceProviderUserId } = useParams<ActivateGiftPageUrlParams>();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { emptyCart } = useActions();
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { credential, isSignedIn } = useTypedSelector(state => state.account);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSetting, setSnackbarSetting] = useState(successSetting);

  const [giftCardNum, setGiftCardNum] = useState('');
  const [giftCardPin, setGiftCardPin] = useState('');


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!serviceProviderUserId) return;
    if (!credential?.email) return;
    if (!giftCardNum) return;
    if (!giftCardPin) return;

    try {
      const { data } = await activateGiftCard({
        activatorEmail: credential.email,
        giftCardNum: giftCardNum,
        giftCardPin: giftCardPin,
        serviceProviderUserId: serviceProviderUserId,
      });

      if (data === "error")
        setSnackbarSetting(giftErrorSetting);
      else {
        emptyCart();
        history.push(`/${serviceProviderUserId}/tasty`);
      }

    } catch (err) {
      console.error(err);
      setSnackbarSetting(defaultErrorSetting);
    } finally {
      setOpenSnackbar(true);
    }
  }

  const disabledActivateBtn = !isSignedIn || !giftCardPin || !giftCardNum;

  return (
    <section className={classes.page} style={{ background: businessInfo?.theme?.color + "dd", }}>
      <Header businessId={serviceProviderUserId} returnUrl={url} />

      <Snackbar
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        autoHide={false}
        {...snackbarSetting}
      />
      <div className={classes.body}>

        <div className={classes.backBtnWrap}>
          <div onClick={e => history.push(`/${serviceProviderUserId}/tasty`)} className={classes.backBtn}>
            <FontAwesomeIcon icon={faArrowCircleLeft} className={classes.backBtnIcon} />
            <span>Back to Menu</span>
          </div>
        </div>

        <form className={classes.form}>

          {isSignedIn === false && <div className={classes.warningMessage}>
            <div>Please</div>
            <Link to={{
              pathname: `${serviceProviderUserId ? `/${serviceProviderUserId}` : url}/account/signIn`,
              state: { returnUrl: url, signInRedirectUrl: url }
            }}><b>Sign in here</b></Link>
            <div>to activate your Gift Card!</div>
          </div>}

          <div className={classes.inputRow}>
            <label htmlFor="giftCardInput">Enter Gift Card Number</label>
            <input type="text" maxLength={16} className={classes.textInput} id="giftCardInput"
              placeholder="Gift Card Number"
              onChange={(event) => {
                const value = tripToDigitsOnly(event.target.value);
                setGiftCardNum(value);
              }}
              value={giftCardNum}
            />
          </div>

          <div className={classes.inputRow}>
            <label htmlFor="giftCardPinInput">Enter Gift Card Pin</label>
            <input type="text" maxLength={8} className={classes.textInput} id="giftCardPinInput"
              placeholder="Gift Card Pin"
              onChange={(event) => {
                const value = tripToDigitsOnly(event.target.value);
                setGiftCardPin(value);
              }}
              value={giftCardPin}
            />
          </div>

          <StyledButton className={classes.activateBtn} style={{ color: businessInfo?.theme.color }}
            disabled={disabledActivateBtn}
            onClick={handleSubmit}>
            Activate
          </StyledButton>

        </form>
      </div>
    </section>
  )
}

export default ActivateGiftPage;