import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React,{ ReactElement} from "react";
/* Material UI Imports */
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
// import { useTypedSelector } from "../../hooks/useTypedSelector";

type HtmlInputProps = React.DOMAttributes<HTMLInputElement>;
interface InputWithIconsProps {
  value: string | number | readonly string[] | undefined;
  leftIcon?: IconProp;
  RightElem?: ReactElement<any, any>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: HtmlInputProps["onKeyUp"];
  name?: string;
  type?: string;
  placeholder?: string;
  wrapperClasses?: string;
  label?: string;
  Icon?: React.FC;
  maxLength?: number;
}
const InputWithIcons: React.FC<InputWithIconsProps> = (props) => {
  // const {businessInfo} = useTypedSelector(state => state.businessInfo);
  
    const {
        name, value,type, placeholder,
        onChange, onKeyUp,label,Icon,maxLength
      } = props;

    return (<Box sx={{ '& > :not(style)': { m: 1 } , width:'100%'}}>
      <FormControl variant="standard" sx={{width:'100%'}}>
        <InputLabel 
          htmlFor="input-with-icon-adornment"
        >
          <Typography>
            {label}
          </Typography>
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              {Icon ? <Icon/> : ''}
            </InputAdornment>
          }
          
          name={name}
          value={value}
        //   className={`${classes.elemWithIcon_element} ${classes.elemWithIcon_input}`}
          onChange={onChange}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          type={type}
          sx={{
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: '2px solid',
              borderColor: 'primary.main',
            },
            '&:after': {
              borderBottom: '2px solid', // Focused color
              borderColor: 'primary.main',
            }
          }}
          inputProps={{ maxLength }}
        />
      </FormControl>
    </Box>)
}

export default InputWithIcons;