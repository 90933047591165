import classes from "./FoodCourt.module.scss";
import FoodCourtHeader from "../../components/FoodCourt/FoodCourtHeader";
import { useEffect, useState } from "react";
import { BizLabel, getBizData } from "../../api/foodCourt";
import { BusinessInfo } from "../../Types/Types";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Link, Typography } from "@material-ui/core";
import BizCard from "../../components/FoodCourt/BizCard";
import Loader from "../../components/Loader/Loader";
import NoBizFound from "../../components/FoodCourt/NoBizFound";
import { useHistory } from "react-router";

export interface FoodCourtProps {

}

const FoodCourt = (props: FoodCourtProps) => {
  const history = useHistory();

  const [bizList, setBizList] = useState<BusinessInfo[]>([]);
  const [labelsList, setLabelsList] = useState<BizLabel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isSignedIn, credential } = useTypedSelector(state => state.account);
  const { setUserCredentials, clearSelectedDisplayMenu} = useActions();


  history.listen((loc, action) => {
    if (loc.pathname == "/") clearSelectedDisplayMenu();
  });

  useEffect(() => {
    setUserCredentials();
  }, [])

  useEffect(() => {
    const fetchBizList = async () => {
      setIsLoading(true)
      try {
        const labelIdStr = labelsList.map(label => label.businessLabelId).join(',');
        const labelIds = labelsList.length > 0 ? labelIdStr : 0;
        const userId = isSignedIn ? credential.userId : 0;

        const results = await getBizData(labelIds, userId);
        const bizList = results as BusinessInfo[];
        setBizList(bizList);
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }

    fetchBizList();
    return () => {
      setBizList([]);
    }
  }, [isSignedIn, labelsList, credential.userId])

  const footerShouldBeOnBottom = bizList.length <= 1;

  const BizElements = bizList.map((biz, ind) => {
    return (
      <BizCard
        key={biz.serviceProviderUserId}
        business={biz}
        index={ind}
      />
    )
  })
  return (
    <div className={classes.foodCourt}
      style={{
        height: footerShouldBeOnBottom ? "100%" : "",
      }}
    >
      {isLoading && <Loader />}
      <FoodCourtHeader
        setLabelsList={setLabelsList}
        labelsList={labelsList}
      />
      <main className={classes.foodCourt_content} >
        {!isLoading && bizList.length === 0
          ? <NoBizFound
            setLabelsList={setLabelsList}
            labelsList={labelsList}
          />
          : BizElements}
      </main>

      {!isLoading && <footer className={classes.footer}>
        <Typography component="p"
          variant="subtitle1"
          className={classes.footer_termAndConditions}
        >
          <Link href={"https://relayfy.com/terms.html"} target="_blank" >
            Terms & Conditions
          </Link>
        </Typography>


        <Typography component="p"
          variant="subtitle2"
          className={classes.footer_copyright}
        >
          © {new Date().getFullYear()}. Relayfy Digital Services. ALL RIGHTS RESERVED.
        </Typography>

      </footer>}
    </div>
  );
}

export default FoodCourt;
