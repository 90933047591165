import React, { CSSProperties, useMemo } from "react";
import { Typography } from "@material-ui/core";
import GlobalModal from "../Modal/Modal";
import AppButton from "../AppButton/AppButton";
import classes from './CancelOrderModal.module.scss';
// --> will merge with BrowserBackModal?

interface CancelOrderModalProp {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  businessPath: string | undefined
  onConfirm: Function;
}

const CancelOrderModal: React.FC<CancelOrderModalProp> = ({
  isOpen,
  setIsOpen,
  onConfirm
}) => {

  const onNo = () => {
    setIsOpen(false);
  };

  const onYes = () => {
    onConfirm();
  };

  const PopupContent = useMemo(() => {

    return <div>
      <Typography variant="h6" gutterBottom>
        Would you like to cancel your order?
      </Typography>
    </div>
  }, []);

  const style: CSSProperties = { width: 140 };

  const Buttons = (
    <div className={classes.buttons}>

      <AppButton variant="outlined" isOutlined style={style} onClick={onNo}>Cancel</AppButton>
      <AppButton style={style} onClick={onYes}>OK</AppButton>

    </div>
  )

  return (<>
    <GlobalModal
      title="Are you sure?"
      open={isOpen}
      handleClose={() => setIsOpen(false)}
      body={PopupContent}
      // closeOnBackdropClick
      buttons={Buttons}
    />
  </>
  );
};

export default CancelOrderModal;
