import { ActionType } from '../action-types';
import { Action } from '../actions';

interface DisplayedMenuState {
  category: number | null
}

const initialState = {
 category:null
};

const reducer = (
  state: DisplayedMenuState = initialState,
  action: Action
): DisplayedMenuState => {
  switch (action.type) {
    case ActionType.CHANGE_DISPLAY_MENU:
      return { category: action.payload };
    case ActionType.CLEAR_DISPLAY_MENU:
      return { category: null };
    default:
      return state;
  }
};

export default reducer;
