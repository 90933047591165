import { BusinessInfo, DISCOUNT_TYPE, MenuByCategory, MenuCategory } from "./../../Types/Types";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface BusinessInfoState {
  error: string | null;
  businessInfo: BusinessInfo | null;
  foodsCategories: MenuCategory[] | null;
  productsInfo: MenuByCategory | null;
  isAppBootedUp: boolean;
  isCateringDateTimeSet: boolean;
}

const initialState = {
  error: null,
  businessInfo: null,
  foodsCategories: null,
  productsInfo: null,
  isAppBootedUp: false,
  isCateringDateTimeSet: false,
};

const reducer = (
  state: BusinessInfoState = initialState,
  action: Action
): BusinessInfoState => {

  const newBusinessInfo = state.businessInfo;
  const overallDiscounts = state.businessInfo?.overallDiscounts || [];

  switch (action.type) {
    case ActionType.SET_APP_BOOTED_UP:
      return { ...state, isAppBootedUp: action.payload };
    case ActionType.APP_TURN_OFF:
      return { ...initialState };
    case ActionType.SET_CATERING_DATE_TIME_SUCCESS:
      return { ...state, isCateringDateTimeSet: true };
    case ActionType.SET_BUSINESSINFO:
    case ActionType.FETCH_BUSINESSINFO_SUCCESS:
      const newBusinessJson = action.payload;

      if (newBusinessJson.deliveryDistance) {
        newBusinessJson.deliveryDistanceInMeters = newBusinessJson.deliveryDistance * 1000;
      } else {
        newBusinessJson.deliveryDistanceInMeters = 0;
      }

      if (newBusinessJson.deliveryDuration) {
        newBusinessJson.deliveryDurationInSeconds = newBusinessJson.deliveryDuration * 60;
      } else {
        newBusinessJson.deliveryDurationInSeconds = 0;
      }

      return { ...state, businessInfo: newBusinessJson };
    case ActionType.FETCH_BUSINESSINFO_ERROR:
      return { ...state, error: action.payload };
    case ActionType.FETCH_FOODS_CATEGORIES_SUCCESS:
      return { ...state, foodsCategories: action.payload };
    case ActionType.FETCH_FOODS_CATEGORIES_ERROR:
      return { ...state, error: action.payload };
    case ActionType.FETCH_PRODUCTS_SUCCESS:
      return { ...state, productsInfo: action.payload };
    case ActionType.FETCH_PRODUCTS_ERROR:
      return { ...state, error: action.payload };
    case ActionType.CLEAR_CREDIT_DISCOUNT:
      const creditDscInd = overallDiscounts.findIndex(dsc => dsc.discountType === DISCOUNT_TYPE.credit);
      if (creditDscInd !== -1) {
        overallDiscounts.splice(creditDscInd, 1);
      }

      return { ...state };
    case ActionType.SET_CREDIT_DISCOUNT:
      const credit = overallDiscounts?.find(dsc => dsc.title === "Credit");

      if (credit) {
        credit.value = action.payload
      }

      return state;
    case ActionType.ADD_OVERALL_DISCOUNT:
      if (!newBusinessInfo) return state;

      const promoDiscountTitle = action.payload.title;
      if (overallDiscounts.some(dsc => dsc.title === promoDiscountTitle)) {
        return state;
      }

      overallDiscounts.push(action.payload);
      newBusinessInfo.overallDiscounts = [...overallDiscounts];
      return { ...state, businessInfo: newBusinessInfo};
    default:
      return state;
  }
};

export default reducer;
