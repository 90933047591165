import classes from './Header.module.scss';
import AccountDropDown from './AccountDropDown';
import RewardsPopup from '../Rewards/RewardsPopup';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faUser, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import { DISCOUNT_TYPE } from '../../Types/Types';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { formatPrice } from '../../common/price/price';
import { IPublicProfileRouteParams } from '../../Types/RouteTypes';
import { getOrderMode } from '../../common/app';
import { OrderMode } from '../../constants';
import { useMemo } from 'react';
import { Typography } from '@material-ui/core';

interface HeaderProp {
  accountRouteToBaseUrl?: boolean,
  businessId?: string,
  returnUrl?: string,
  signInRedirectUrl?: string,
}

const Header = (props: HeaderProp) => {
  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const appViewMode = getOrderMode(viewMode);
  const isKioskViewMode = appViewMode === OrderMode.kiosk;
  const account = useTypedSelector(state => state.account);
  const { businessId, returnUrl, signInRedirectUrl, accountRouteToBaseUrl = false } = props;
  const { url } = useRouteMatch();
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);

  const getStyle = () => {
    if (!businessInfo) return { backgroundColor: 'transparent' };
    return {
      backgroundColor: businessInfo.theme.color
    }
  }

  const credit = businessInfo?.overallDiscounts
    ?.find(dsc => dsc.discountType === DISCOUNT_TYPE.credit);

  const hasCredit = !!credit && credit.value > 0;
  const creditAmt = hasCredit ? formatPrice(credit?.value) : "";

  const linkIcon = (icon: IconDefinition, link: string | undefined, size: number = 40) => {
    return (link &&
      <FontAwesomeIcon width={size} height={size}
        icon={icon}
        onClick={e => {
          window.open(link);
        }}
        className={classes.header_mapIcon}
      />
    )
  }

  const rewardStampCardType = businessInfo?.rewardStampCardType ?? -1;
  const showRewards = rewardStampCardType !== -1;

  const RewardsComponent = useMemo(() => {
    if (!showRewards) {
      return null;
    }

    return (<>
      <RewardsPopup />
      {(account.isSignedIn && creditAmt) &&
        <Typography variant="body1" className={classes.credit}>
          Credit: {creditAmt}
        </Typography>}
    </>)
  }, [account.isSignedIn, creditAmt, showRewards]);


  if (isKioskViewMode) return (
    <section style={getStyle()} className={classes.kioskHeader}>
      Ordering Kiosk
    </section>
  )

  const urlParts = url.split('/');
  const bizUrl = `${urlParts[0]}/${urlParts[1]}`;

  return (
    <div style={getStyle()} className={classes.header}>

      <div className={classes.rewards}>
        {RewardsComponent}
      </div>

      <div className={classes.header_right}>
        <div className={classes.header_links}>
          {linkIcon(faGlobe, businessInfo?.websiteUri, 35)}
          {linkIcon(faFacebook, businessInfo?.facebookUri, 35)}
          {linkIcon(faInstagram, businessInfo?.instagramUri)}
        </div>

        <div className={classes.header_account}>

          {account.isSignedIn ?
            <AccountDropDown /> :
            <Link
              className={classes.header_icon}
              to={{
                pathname: accountRouteToBaseUrl ? `/${businessId}/account/signIn` : `${bizUrl}/account/signIn`,
                state: { returnUrl, signInRedirectUrl }
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </Link>}

        </div>
      </div>
    </div>
  )
}

export default Header
