import classes from "./Account.module.scss";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { signUpOnServer, UserLoginState } from "../../api/user";
import { validateEmail } from "../../common/account/account";
import { isNumber } from "../../common/price/price";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { convertToPath } from "./AccountHeader";
import SubmitFieldWithButton from "./SubmitFieldWithButton";
import Button from "@mui/material/Button";
import { Typography } from "@material-ui/core";

const SignUp: React.FC = () => {
  const params = useParams<{ businessName: string }>();
  const history = useHistory();
  const appBaseUrl = convertToPath(params.businessName);

  const account = useTypedSelector((state) => state.account);
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const { setSignUpSuccess, setAccountError, clearAccountErrors } =
    useActions();
  const [email, setEmail] = useState("");

  useEffect(() => {
    clearAccountErrors();
  }, []);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = String(event.target.value);
    setEmail(currValue);
  };

  const isFormValid = () => {
    return email.length > 0;
  };

  const handleSignUp = async (e) => {
    const emailTemp = email;
    console.log("emailTemp", emailTemp);
    setEmail("");
    clearAccountErrors();
    if (!validateEmail(email)) {
      setAccountError("Please enter a valid Email");
      return;
    }

    try {
      const userSignUp = {
        emailAsUserId: email,
        password: email,
        enterChoice: "signup",
      } as UserLoginState;

      if (
        businessInfo?.serviceProviderUserId &&
        isNumber(businessInfo?.serviceProviderUserId)
      ) {
        userSignUp.serviceProviderUserId = Number(
          businessInfo?.serviceProviderUserId
        );
      }

      const result = await signUpOnServer(userSignUp);
      if (result.data === -1) {
        setAccountError(
          `The email '${emailTemp}' is already registered with us.`
        );
        return;
      }
      console.log("Sign up successful",result)
      setSignUpSuccess();
    } catch (err) {
      console.error(err);
    }

    history.push({
      pathname: `${appBaseUrl}/account/signIn`,
      state: { justSignedUp: true },
    });
  };

  return (
    <div className={classes.form}>
      <div className={classes.pageTitle}>Sign up</div>

      <SubmitFieldWithButton
        value={email}
        onChange={handleInput}
        onSubmit={handleSignUp}
        isFormValid={isFormValid}
        moreClasses={classes.signUp_button}
      />

      <div className={classes.messages}>
        <span className={classes.error}>
          {account.error !== "" ? account.error : ""}
        </span>
      </div>
      <Button
        sx={{
          width: "100%",
          padding: "1rem 0",
          backgroundColor: "primary.main",
          color: "white",
          borderRadius: "56px",
          "&:hover": {
            backgroundColor: "primary.main",
          },
        }}
        onClick={handleSignUp}
      >
        <Typography>Sign Up</Typography>
      </Button>
      <div className={classes.signUp_alternatives}>
        <Link className={classes.backLink} to={`${appBaseUrl}/account/signIn`}>
          Got back to Sign in
        </Link>
      </div>
    </div>
  );
};

export default SignUp;
