export const getFormattedPhoneNumber = (userPhoneNumber?: string) => {
  if (!userPhoneNumber) return "";

  if (userPhoneNumber.length < 10) {
    return userPhoneNumber;
  }

  if (userPhoneNumber.length === 10) {
    const firstThree = userPhoneNumber.slice(0, 3);
    const nextThree = userPhoneNumber.slice(3, 6);
    const rest = userPhoneNumber.slice(6, userPhoneNumber.length);
    const newValue = `(${firstThree})` +
      (nextThree && " " + nextThree) + (rest && " " + rest);
    return newValue;
  }
}

export const validatePhone = (number: string) => {
  if (number.length === 0) {
    return false;
  }

  if (isNaN(Number(number))) {
    return false;
  }

  if (number.length < 10) {
    return false;
  }
  return true;
}

export const normalizeFormattedPhoneNumber = (formattedPhone?: string) => {
  if (!formattedPhone) return "";

  const phoneNum = formattedPhone.replaceAll(/\D/g, '');
  return phoneNum;
}
