import classes from "./scss/CustomizeModal.module.scss";
import { customFields, useCustomization } from "./hooks/useCustomization";
import { useMemo } from "react";

import { ChoicesById, SectionChoicesById, CustomDetailType, CustomizationSection } from "../../Types/Types";
import { formatPrice } from "../../common/price/price";
import CheckboxLabels from "./CheckboxButton";
import NumberInput from "./NumberInput";
import RadioChoice from "./RadioChoice";
import ChoiceAccordion from "./ChoiceAccordion";
import { Box, Typography } from "@mui/material";

type AnyOfSectionProps = {
    customizationContext: ReturnType<typeof useCustomization>,
    section?: CustomizationSection,
    sectionChoicesById: SectionChoicesById,
    isNested?: boolean,
}

const isDebug = false;
const debugMax = 3;

export const AnyOfSection = (props: AnyOfSectionProps) => {
    const { section, sectionChoicesById, customizationContext, isNested = false } = props;
    const { calculateSelectedCount } = customizationContext;

    const choicesById = useMemo(() => {
        if (!section?.id) return {}

        const match = sectionChoicesById[section.id]
        return match;
    }, [section?.id])

    const selectedCount = calculateSelectedCount(choicesById)

    const Header = useMemo(() => {
        const sectionTitle = section?.message ?? '';
        if (!sectionTitle) return;

        return <>
            <Box className={classes.title} id={section?.id}>
                <Typography
                    sx={{
                        fontSize: "16px",
                        color: "#49454F",
                        fontWeight: 500,
                        marginTop: "16px",
                    }}
                >
                    {sectionTitle}
                </Typography>
            </Box>
        </>
    }, [section, isNested, selectedCount])

    const min = section?.min ?? -1;
    const max = isDebug ? debugMax : section?.max ?? -1;
    const showMinReqMessage = min > 0 && selectedCount === 0;
    const MinMsg = useMemo(() => {
        if (min === -1) return;

        const minMsg = showMinReqMessage ? `At least ${min} item(s) must be selected.` : '';
        return minMsg;
    }, [min, selectedCount])

    const CountMsg = useMemo(() => {
        if (section?.sectionType === "oneOf") return;
        if (max >= 999) return;
        if (max < 1) return;
        if (min === -1) return;
        if (min === 1 && max === 1) return;

        const showMinReqMessage = min > 0 && selectedCount === 0;
        const countMsg = !showMinReqMessage ? `${selectedCount} item(s) out of ${max} are selected.` : '';
        return countMsg;
    }, [min, max, section?.sectionType, selectedCount])

    const RestrictionNote = useMemo(() => {
        if (min === -1) return;
        if (!MinMsg && !CountMsg) return;

        const error = selectedCount < min ? 'red' : undefined;
        return <Box className={classes.title}>
            <Typography variant="caption" color={error}>{MinMsg}</Typography>
            <Typography variant="caption">{CountMsg}</Typography>
        </Box>
    }, [section, MinMsg, CountMsg, selectedCount])


    const choicesList = section?.choices;
    if (!choicesList) return <></>;

    return <Box key={`oneofList-${section.id}`}>
        {Header}

        {RestrictionNote}

        {choicesList?.length > 0 && (
            <Box className={classes.sectionWrap} id={!Header ? section?.id : ''}>
                <AnyOfChoicesList
                    list={choicesList}
                    section={section}
                    selectedCount={selectedCount}
                    sectionChoicesById={sectionChoicesById}
                    choicesById={choicesById}
                    customizationContext={customizationContext}
                    isNested={isNested}
                />
            </Box>)}
    </Box>
}


type ChoicesListProps = {
    section: CustomizationSection,
    list: CustomDetailType[],
    selectedCount: number,
    sectionChoicesById: SectionChoicesById,
    customizationContext: ReturnType<typeof useCustomization>,
    choicesById: ChoicesById,
    isNested: boolean,
}


export const AnyOfChoicesList = (props: ChoicesListProps) => {
    const { section, list, choicesById, sectionChoicesById, } = props;
    const { selectedCount, customizationContext, isNested } = props;
    const { calculateItemTotal, checkOnChange, radioOnChange, } = customizationContext;
    const { integratedArray, setIntegratedArray } = customizationContext;

    if (!list) {
        return <></>;
    }

    if (section.sectionType === "oneOf") {
        return (
            <RadioChoice
                radioOnChange={(choiceId) => radioOnChange(section.id, choiceId)}
                message={section.message}
                isNested={isNested}
                choices={list}
                choicesById={choicesById}
                sectionChoicesById={sectionChoicesById}
                customizationContext={customizationContext}
            />
        );
    }



    const mapToChoices = list.map((choice, index) => {
        const currChoiceId = choice.id ?? '-1';

        const unitOfMeasurement = choice.unitOfMeasurement
            ? choice.unitOfMeasurement
            : "unit";

        const choiceValues = choicesById[currChoiceId];
        const currentSelected = choiceValues.value;

        const max = isDebug ? debugMax : section.max;

        const isMaxValid = max > 0 && selectedCount === max;
        const disabled = choice.soldout || isMaxValid;

        const isPriceZero = choice.price === 0;
        const formattedChoicePrice = isPriceZero ? "" : formatPrice(choice.price);
        const pricePerUnitDisplayVal = isPriceZero
            ? ""
            : `${formattedChoicePrice}/${unitOfMeasurement}`;

        /** Checkbox properties */
        const singleItemAllowed = section.multiMax === 1;
        const isBooleanType = choice.type === "boolean";
        const renderAsCheckBox = isBooleanType || singleItemAllowed;
        const canSelectOtherCheckboxes = isBooleanType && max === 1;

        const handleNumberChange = (value) => {
            const positiveNumber = value instanceof String ? value.replace(/[^0-9]/g, "") : value;

            const newVal = Number(positiveNumber);

            if (!choiceValues || isNaN(newVal)) return

            if (max === -1 || max === 0) {
                choiceValues.value = newVal;
            } else {
                const delta = selectedCount - currentSelected;
                const isOverMax = delta + newVal > max;
                choiceValues.value = isOverMax ? max - delta : newVal;
            }

            setIntegratedArray({ ...integratedArray })
            calculateItemTotal();
        }

        const ChoiceElem = renderAsCheckBox ? (
            <>
                <CheckboxLabels
                    isChecked={currentSelected ? true : false}
                    controlLabel={choice.name}
                    checkOnChange={(newVal) => {
                        if (canSelectOtherCheckboxes) {

                            for (const choiceId in choicesById) {
                                if (customFields.includes(choiceId)) {
                                    continue
                                }
                                const choice = choicesById[choiceId];
                                if (currChoiceId !== choiceId) {
                                    choice.value = 0;
                                }
                            }
                        }
                        checkOnChange(choice.id)
                    }}
                    disabled={disabled && !currentSelected}
                />
                <Typography
                    fontWeight={400}
                    fontSize="14px !important"
                    color="#49454F"
                >
                    {formattedChoicePrice}
                </Typography>
            </>
        ) : (
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                gap: "1rem",
            }}>
                <NumberInput
                    min={0}
                    disableInput={disabled}
                    disableIncrement={disabled}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value;
                        handleNumberChange(value)
                    }}
                    onChange={(event, value) => {
                        handleNumberChange(value)
                    }}
                    value={currentSelected}
                    defaultValue={
                        !choice || choice.value === 0 ? 0 : choice.value
                    }
                />

                <label
                    style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "flex-start",
                    }}
                >
                    <Typography
                        fontWeight={400}
                        fontSize="14px !important"
                        color="#49454F"
                    >
                        {choice.name}
                    </Typography>
                </label>

                {pricePerUnitDisplayVal && (
                    <Typography
                        fontWeight={400}
                        fontSize="14px !important"
                        color="#49454F"
                    >
                        {pricePerUnitDisplayVal}
                    </Typography>
                )}
            </Box>
        );

        const noNestedChoices = !choice?.nestedChoices?.length;
        if (noNestedChoices || !currentSelected) {
            return <Box
                key={`${choice.name}_${index}`}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                {ChoiceElem}
            </Box>
        }

        if (isNested) return;

        return (
            <ChoiceAccordion
                elevation={0}
                disableGutters
                key={`${choice.name}_${index}`}
                sx={{
                    width: "100%",
                }}
                displayElement={ChoiceElem}
                defaultExpanded={!!currentSelected}
            >
                {choice.nestedChoices.map(section =>
                    <AnyOfSection
                        section={section}
                        sectionChoicesById={sectionChoicesById}
                        customizationContext={customizationContext}
                        isNested
                    />)}
            </ChoiceAccordion>
        );
    });

    return <>
        {mapToChoices}
    </>
};