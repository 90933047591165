import { useEffect } from "react";
import {
  Route,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { BusinessInfo, Channel } from "../../Types/Types";
import { kioskRefreshInterval } from "../../serverConfig";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import QrCode from "../../components/QrCode/QrCode";
import AccountRoute from "../account/AccountRoute";
import PdfPage from "../../components/PdfPage/PdfPage";
import Loader from "../../components/Loader/Loader";
import HomePage from "./HomePage";
import RecommendPage from "../recommend/RecommendPage";
import PageNotFound from "../notFound/pageNotFound";
import Feedback from "../../components/Feedback/Feedback";
import ServiceProviderRoute from "./ServiceProviderRoute";

const windowAsAny = window as any;
if (!windowAsAny.intervals) windowAsAny.intervals = [];

const hostUrl = window.location.origin;
const defaultManifest = {
  short_name: "Safe Ordering",
  name: "Relayfy Safe Ordering",
  icons: [
    {
      src: `${hostUrl}/relayfy_logo.png`,
      sizes: "100x100",
      type: "image/PNG",
    },
    {
      src: `${hostUrl}/relayfy.ico`,
      sizes: "64x64 32x32 24x24 16x16",
      type: "image/x-icon",
    },
  ],
  start_url: hostUrl,
  display: "standalone",
  theme_color: "#000000",
  background_color: "#ffffff",
};

const nonMenuLastPaths = ["qrcode", "pdf", "recommend"];
const nonMenuAfterBizPath = [
  "account",
  "feedback",
  "activategift",
  "cateringSelect",
];

let refreshDateTimeLimit = new Date();
const addLimitTime = (dateTime) => {
  dateTime.setHours(refreshDateTimeLimit.getHours() + kioskRefreshInterval);
  dateTime.setMinutes(refreshDateTimeLimit.getMinutes() + 1);
  return dateTime;
};

const getIsFirstParamNaN = (pathname: string) => {
  const allPath = pathname;
  const paths = allPath.split("/").splice(1, 1);
  return isNaN(Number(paths[0]));
};

refreshDateTimeLimit = addLimitTime(refreshDateTimeLimit);

const OrderingApp = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const { businessName } = useParams<IPublicProfileRouteParams>();
  const {
    fetchBusinessData,
    setAppBooted,
    setUserCredentials,
    fetchProductsAndCategories,
  } = useActions();
  const { businessInfo, isCateringDateTimeSet } = useTypedSelector(
    (state) => state.businessInfo
  );
  const isAppBootedUp = useTypedSelector(
    (state) => state.businessInfo.isAppBootedUp
  );
  const { category } = useTypedSelector((state) => state.displayedMenuCategory);

  const lowerCasePath = location.pathname;
  const pathParts = lowerCasePath.split("/");
  const pathPartAfterBusinessPath = pathParts[2] as Channel;

  const isCateringViewMode = pathPartAfterBusinessPath === "catering";

  let menuRefreshInterval: NodeJS.Timeout;
  const isInitialNaN = getIsFirstParamNaN(location.pathname);

  const serviceUserId = businessInfo?.serviceProviderUserId;
  const needRedirectToCateringSelect =
    !isCateringDateTimeSet && isCateringViewMode && isInitialNaN;

  useEffect(() => {
    if (needRedirectToCateringSelect && serviceUserId) {
      history.replace(`/${serviceUserId}/catering`);
    }
  }, [!isCateringDateTimeSet, isCateringViewMode, serviceUserId]);

  useEffect(() => {
    setUserCredentials();

    if (!isInitialNaN) {
      initServiceProviderIdPath();
      return;
    }

    if (!businessName) return;

    if (needRedirectToCateringSelect || !isAppBootedUp) {
      fetchBusinessData(businessName, pathPartAfterBusinessPath, category);
    }

    console.log("Calling fetch:", !isAppBootedUp, businessName);

    return () => {
      setAppBooted(false);
    };
  }, [isInitialNaN, businessName, needRedirectToCateringSelect]);

  const initServiceProviderIdPath = () => {};

  useEffect(() => {
    if (!businessInfo) return;

    document.title = businessInfo.name;
    setManifest(businessInfo);

    const square = document.createElement("script");

    if (businessInfo.env === "prod") {
      square.src = " https://web.squarecdn.com/v1/square.js";
    } else if (businessInfo.env === "nonprod") {
      square.src = "https://sandbox.web.squarecdn.com/v1/square.js";
    }

    square.type = "text/javascript";
    square.async = false;
    document.getElementsByTagName("head")[0].appendChild(square);

    const isKioskViewMode = pathPartAfterBusinessPath === "kiosk";
    if (isKioskViewMode) {
      if (menuRefreshInterval) {
        windowAsAny.intervals.filter((id) => id !== menuRefreshInterval);
        clearInterval(menuRefreshInterval);
      }
      menuRefreshInterval = setInterval(() => {
        let now = new Date();
        // if (refreshDateTimeLimit.getMinutes() <= now.getMinutes()) {
        if (refreshDateTimeLimit.getHours() <= now.getHours()) {
          fetchProductsAndCategories(
            businessInfo.serviceProviderUserId,
            pathPartAfterBusinessPath
          );
          now = addLimitTime(now);
          refreshDateTimeLimit = now;
        }
      }, 1000 /* every 1 second */);

      windowAsAny.intervals.push(menuRefreshInterval);
    }
  }, [businessInfo]);

  const setManifest = (currentBiz: BusinessInfo) => {
    const bizManifest = { ...defaultManifest };
    bizManifest.start_url += `/${currentBiz.routingPath}`;

    bizManifest.short_name = currentBiz.name;
    const stringManifest = JSON.stringify(bizManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    const manifestLinkElem = document.getElementById("manifest-placeholder");
    manifestLinkElem?.setAttribute("href", manifestURL);
  };

  const qrCodePaths = [`${path}/qrcode`, `${path}/:viewMode?/qrcode`];

  const nonMenuMatch = new RegExp(nonMenuAfterBizPath.join("|"), "i");
  const nonMenuLastMatch = new RegExp(nonMenuLastPaths.join("|"), "i");
  const isNonMenuPath =
    nonMenuLastMatch.test(pathPartAfterBusinessPath) ||
    nonMenuMatch.test(pathPartAfterBusinessPath);

  if (!isInitialNaN)
    return (
      <Route
        path={"/:serviceProviderUserId"}
        component={ServiceProviderRoute}
      />
    );

  return (
    <>
      {!isAppBootedUp ? (
        <Loader />
      ) : !businessInfo ? (
        <PageNotFound />
      ) : (
        <>
          {isNonMenuPath ? (
            <>
              <Route path={qrCodePaths} component={QrCode} />
              <Route path={`${path}/404`} component={PageNotFound} />
              <Route path={`${path}/pdf`} component={PdfPage} />
              <Route path={`${path}/recommend`} component={RecommendPage} />
              <Route
                path={`${path}/feedback/:orderNumber?`}
                component={Feedback}
              />
              <Route
                path={`${path}/account`}
                children={<AccountRoute isFromOrderingRoute />}
              />
            </>
          ) : (
            <Route strict path={`${path}/:viewMode?`} children={<HomePage />} />
          )}
        </>
      )}
    </>
  );
};

export default OrderingApp;
