import classes from "./Menu.module.scss";
import MenuRow from "../MenuCard/MenuRow";
import googleIcon from "../../img/icon-google-map.png";
import { RefObject, createRef, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { appendBaseUrlToImage } from "../../common/image";
import { getFont } from "../../common/themeUtils";
import { useParams } from "react-router";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import StoreCloseReason from "../StoreCloseReason/StoreCloseReason";
import CateringDateTimeDisplay from "../Catering/CateringDateTimeDisplay";
import FoodsCategoryTabs from "../FoodsCategoryTabs/FoodsCategoryTabs";
import { StoreOrderingState } from "../../constants";
import { useBizTheme } from "../../hooks/useBizTheme";

const logoSize = 70;

function debounce<F extends (...params: any[]) => void>(fn: F, ms: number) {
  let timeoutID: number;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), ms);
  } as F;
}

type MenuByCategoryProps = {
  categoryId: string | number;
  children?: React.ReactNode;
}

const MenuByCategory = forwardRef<HTMLDivElement, MenuByCategoryProps>((props: MenuByCategoryProps, ref) => {
  const theme = useBizTheme();
  const { categoryId } = props;
  const { foodsCategories } = useTypedSelector((state) => state.businessInfo);

  const categoryTitle = useMemo(() => {
    const category = foodsCategories?.find(x => x.id === categoryId);
    return category?.name ?? "unknown_category";
  }, [categoryId])

  return (
    <div
      ref={ref}
      style={{
        transition: "opacity 0.3s ease",
        opacity: 1,
        display: "block",
      }}
    >
      <label
        className={classes.categoryTitle}
        style={{ color: theme.color }}>
        {categoryTitle}
      </label>

      {props.children}
    </div>
  );
});

type MenuProps = {
  setShowSnackBar: Function;
}

const Menu = (props: MenuProps) => {
  const { setShowSnackBar } = props;
  const { businessName, viewMode } = useParams<IPublicProfileRouteParams>();
  let WindowScreenWidth = window.innerWidth;
  const { businessInfo, productsInfo } = useTypedSelector((state) => state.businessInfo);

  const orders = useTypedSelector((state) => state.orderDetails);
  const [showProducts, setShowProducts] = useState(true);
  const [storeClosed, setStoreClosed] = useState(false);
  const rowsRefs = useRef([]);
  const [productsRefsByCategoryIds, setProductsRefsByCategoryIds] = useState<{ [k: string]: RefObject<HTMLDivElement> }>({});

  useEffect(() => {
    const isPreorderOnly = businessInfo?.storeOrderingStatus === StoreOrderingState.preOrdersOnly;
    if (businessInfo?.storeTemporarilyClosed) {
      setStoreClosed(true);
    }

    const isOpenForOnlineOrdering = businessInfo?.storeOpen;
    if (isOpenForOnlineOrdering && isPreorderOnly) {
      setStoreClosed(true);
    }

    if (!isOpenForOnlineOrdering) {
      setStoreClosed(true);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, [orders]);

  const handleResize = debounce(function handleResize() {
    if (WindowScreenWidth !== window.innerWidth) {
      setShowProducts(false);
      setTimeout(() => {
        setShowProducts(true);
      }, 100);
    }
    WindowScreenWidth = window.innerWidth;
  }, 100);

  const foodCategoryWrapperStyle = useMemo(() => {
    const needCateringPadding = viewMode === 'catering' && !storeClosed;
    if (needCateringPadding) {
      return { marginTop: 128 };
    }

    return storeClosed ? { marginTop: 0 } : { marginTop: 20 };
  }, [storeClosed, viewMode]);

  const ProductsList = useMemo(() => {
    if (!productsInfo) return <></>;

    const temp: { [k: string]: RefObject<HTMLDivElement> } = {};
    const productsElems = Object.entries(productsInfo)
      .filter(([key, menuItems]) => menuItems.length > 0)
      .map(([key, value], index) => {
        const categoryId = key;
        const productOptions = value;
        const catRef = !rowsRefs.current[index] ? createRef<HTMLDivElement>() : rowsRefs.current[index];
        temp[categoryId] = catRef;
        return <MenuByCategory
          key={categoryId}
          categoryId={parseInt(categoryId)}
          ref={catRef}
        >
          {productOptions.map((product, index) =>
            <MenuRow
              key={product.id}
              menu={product}
              screenWidth={WindowScreenWidth}
              setShowSnackBar={setShowSnackBar}
            />)}
        </MenuByCategory>
      });


    setProductsRefsByCategoryIds(temp);
    return productsElems;
  }, [productsInfo, rowsRefs])

  const location = businessInfo?.location;

  const LogoElem = useMemo(() => {
    const logo = appendBaseUrlToImage(businessInfo?.logo);
    if (!logo) return;

    return <img
      src={logo}
      alt="business logo img"
      width={logoSize}
      height={logoSize}
      style={{ marginTop: '0px' }}
      className={classes.header_mapIcon}
    />
  }, [businessInfo?.logo])

  const GoogleMapIcon = useMemo(() => {
    if (!location?.googleMapUri) return;

    return <img
      src={googleIcon}
      alt="google-map-icon"
      className={classes.mapIcon}
      onClick={(e) => {
        if (!location?.googleMapUri) return;
        window.open(
          location.googleMapUri,
          "_blank",
          "noopener noreferrer"
        );
      }}
    />
  }, [businessInfo?.logo])

  if (!productsInfo) return <></>;

  const bottomPadding = 65;

  return (
    <div
      className={classes.menuContainer}
      style={{ paddingBottom: bottomPadding, }}
    >

      <header id="menuHeader" className={classes.header}>

        <div className={classes.title}>
          {LogoElem}
          <div className={classes.address}>
            <span className={classes.businessName} style={{ font: getFont(businessInfo) }}>
              {businessInfo?.name}
            </span>
            <div className={classes.location}>
              <div className={classes.address}>
                {location?.addressLine1}
                {location?.addressLine1 && location?.city && ", "}
                {location?.city}
              </div>

              {GoogleMapIcon}
            </div>
          </div>
        </div>

        <CateringDateTimeDisplay stickPosition />


      </header>
      <StoreCloseReason />
      <div className={classes.category__options}
        style={foodCategoryWrapperStyle} >
        <FoodsCategoryTabs productsRefsByCategoryIds={productsRefsByCategoryIds} />
      </div>
      {ProductsList}
      <div style={{ padding: 128, display: "flex", justifyContent: "center" }}>
        <a href="#menuHeader">Back to Top</a>
      </div>
    </div>
  );
};

export default Menu;
