
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface ReciptInfoState {
  error: string | null;
  updateStatusInfo: boolean;
}

const initialState = {
  error: null,
  updateStatusInfo: false
};

const reducer = (
  state: ReciptInfoState = initialState,
  action: Action
): ReciptInfoState => {
  function $set(...objects:any) {
    return Object.assign({}, ...objects); 
  }
  switch (action.type) {
    case ActionType.SEND_RECEIPT_SUCCESS:
      return { ...state, updateStatusInfo : $set(action.payload) };
    case ActionType.SEND_RECEIPT_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default reducer;
