import classes from './AdFooter.module.scss';
import { useTypedSelector } from "../../hooks/useTypedSelector";


const AdFooter = () => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  return (
    <div id="adFooterContainer" className={classes.adContainer}
      dangerouslySetInnerHTML={{ __html: businessInfo?.adHTML ?? "" }}>
    </div>
  )
}

export default AdFooter;