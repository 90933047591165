import classes from "./ConfirmOrder.module.scss";
import Popup from "../Popup/Popup"
import { formatPrice } from "../../common/price/price";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ThemedButton from "../StyledComponent/ThemedButton";

interface KioskOrderConfirmationPageProps {
    isOpen: boolean;
    orderNum: number;
    payableAmt: number;
    autoCloseRemainingTime: number;
    onClose: () => void;
}

const KioskOrderConfirmationPage = (props: KioskOrderConfirmationPageProps) => {
    const { businessInfo } = useTypedSelector((state) => state.businessInfo);

    const content = <>
        <section className={classes.kioskPopupContent}>

            <section className={classes.orderNum}>
                <div>Order Number</div>
                <div className={classes.value}>{props.orderNum}</div>
            </section>

            <section className={classes.payableAmt}>
                <div>Total Amount</div>
                <div className={classes.value}>{formatPrice(props.payableAmt)}</div>
            </section>

            {businessInfo?.kioskOrderConfirmation?.message &&
                <section className={classes.message}
                    dangerouslySetInnerHTML={{ __html: businessInfo?.kioskOrderConfirmation.message }} />}

            <div className={classes.footer}>
                <ThemedButton
                    isMaterial
                    className={classes.callToAction}
                    onClick={props.onClose}>
                    {businessInfo?.kioskOrderConfirmation?.callToAction}
                </ThemedButton>

                <div className={classes.autoCloseTime}>
                    This window will close in {props.autoCloseRemainingTime} seconds
                </div>
            </div>

        </section>
    </>;
    return <Popup
        handleClose={props.onClose}
        isOpen={props.isOpen}
        // isOpen={true}
        title="Order submitted successfully"
        popupStyle={{ border: "solid green 6px", maxWidth: '90%', maxHeight: '90%', }}
        contentStyle={{ height: '100%', overflowY: 'unset' }}
        content={content}
        footer={<></>}
        header={<></>}
        isFullScreen
        shouldCloseOnOverlayClick={false}
    // onOk={onClosePopup}
    />
}

export default KioskOrderConfirmationPage;