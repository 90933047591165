import classes from "./Account.module.scss";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { validateEmail } from "../../common/account/account";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { convertToPath } from "./AccountHeader";
// import PasswordIcon from "../../assets/icons/PasswordIcon";
import LockIcon from '@mui/icons-material/Lock';
// import InputWithIcons from "./InputWithIcons";
import SubmitFieldWithButton from "./SubmitFieldWithButton";
import NewInputWithIcons from "./InputWithIcon";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from "@material-ui/core";
import useTheme from '@mui/material/styles/useTheme';
interface ISignInProps {
  signInId: string,
  password: string,
}

interface SignInLocationState {
  justSignedUp?: boolean;
}


const SignIn: React.FC = () => {


  // material ui theme values in signin component
  const theme = useTheme();
  console.log("theme in signin", theme);

  const params = useParams<{ businessName: string }>();
  const appBaseUrl = convertToPath(params.businessName);
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  // const history = useHistory();
  const { signInWithEmail, setAccountError, clearAccountErrors } = useActions();
  const location = useLocation<SignInLocationState>();
  const justSignedUp = location?.state?.justSignedUp;
  const account = useTypedSelector(state => state.account);
  const [credential, setCredential] = useState<ISignInProps>({
    signInId: "",
    password: "",
  });
  
  useEffect(() => {
    clearAccountErrors();
  }, []);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = String(event.target.value);
    if (event.target.type === "email") {
      setCredential(rest => ({ ...rest, signInId: currValue }));
      return;
    }

    setCredential(rest => ({ ...rest, password: currValue }));
  }

  const isFormValid = () => {
    return credential.signInId.length > 0 && credential.password.length >= 4;
  }

  const handleSubmit = (e) => {
    clearAccountErrors();
    if (!validateEmail(credential.signInId)) {
      setAccountError("Please enter a valid Email");
      return;
    }
    signInWithEmail(credential);
  }


  return (
    <div className={classes.form}>
      <div className={classes.pageTitle}>Sign In</div>

      <SubmitFieldWithButton
        value={credential.signInId}
        onChange={handleInput}
        onSubmit={handleSubmit}
        isFormValid={isFormValid}
      />
      <NewInputWithIcons
        value={credential.password}
        placeholder="**********"
        type="password"
        leftIcon={faLock}
        onChange={handleInput}
        onKeyUp={(event) => {
          if(event.key === "Enter") {
            handleSubmit(event);
          }
        }}
        label="Password"
        Icon={LockIcon}
      />

    
      <div className={classes.messages}>
        {justSignedUp &&
          <span className={classes.info}>
            Please check your email and verify your email address
          </span>}
        <span className={classes.error}>
          {account.error}
        </span>
      </div>
      <Button
          onClick={handleSubmit}
          sx={{
            width:'100%',
            padding: '1rem 1rem',
            borderRadius: '56px',
            textDecoration: 'none',
            backgroundColor:businessInfo?.theme?.color || '#000',
            marginBottom:'1rem',
            '&:hover':{
              backgroundColor:'primary.main'
            }

          }} >
          <Typography
            sx={(theme)=>({
              color:'#fff',
              fontWeight:700
            })}
          >Sign In</Typography>
          
        </Button>
      <Box>
        <Button
          component={Link}
          to={`${appBaseUrl}/account/signUp`}
          sx={{
            padding: '.5rem 1rem',
            borderRadius: '10px',
            textDecoration: 'none'
          }}
        >
          <Typography
            fontWeight={700}
            color={businessInfo?.theme?.color || '#000'}
            >Go to Sign Up</Typography>
        </Button>
      
        <Button
          component={Link}
          to={`${appBaseUrl}/account/forgot`}
          sx={{
            padding: '.5rem 1rem',
            borderRadius: '10px',
            textDecoration: 'none'
          }} >
          <Typography
            fontWeight={700}
            color={businessInfo?.theme?.color || '#000'}
          >Forgot password?</Typography>
          
        </Button>
        </Box>
    </div>
  );
}

export default SignIn;
