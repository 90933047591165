import {
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import useTheme from "@mui/material/styles/useTheme";
import { useCallback, useMemo } from "react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { NumberMaskInput } from "./NumberMaskInput";
import classes from "./Tips.module.scss";

interface TipProps {
  title: string;
  type: "staff" | "delivery";
}
const Tips = ({ title, type }: TipProps) => {
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const selectedTip = useTypedSelector(
    (state) => state.orderDetails.order.selectedTipPercentage
  );
  const selectedDriverTip = useTypedSelector(
    (state) => state.orderDetails.order.driverTipPercentage
  );
  const { setTipPercentage, setDriverTipPercentage, setInfoToFinalOrder } =
    useActions();
  const theme = useTheme();

  const selectedTipStyle = useMemo(
    () => ({
      width: "50px",
      height: "50px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "15px",
      color: grey[100],
    }),
    [theme]
  );

  const setNewTip = useCallback(
    (tip) => {
      if (type === "staff") {
        setTipPercentage(tip);
      }
      if (type === "delivery") {
        setDriverTipPercentage(tip);
      }
      setInfoToFinalOrder();
    },
    [type, setTipPercentage, setDriverTipPercentage, setInfoToFinalOrder]
  );

  const CustomTipTextField = useMemo(() => {
    return (
      <TextField
        size="small"
        key="customTip"
        label="Custom tip"
        variant="outlined"
        placeholder="0"
        className={classes.customTip}
        value={
          type === "staff"
            ? selectedTip?.toString()
            : selectedDriverTip?.toString()
        }
        InputProps={{
          startAdornment: <InputAdornment position="start">%</InputAdornment>,
          inputComponent: NumberMaskInput as any,
        }}
        onChange={(e) => {
          const newVal = +e.target.value;
          if (isNaN(newVal)) {
            return;
          }

          setNewTip(newVal);
        }}
      />
    );
  }, [selectedTip, selectedDriverTip]);

  const TipsControls = useMemo(() => {
    if (!businessInfo?.tip || businessInfo?.tip.length === 0) {
      return null;
    }
    return businessInfo.tip.map((tip) => {
      if (type === "staff") {
        return (
          <div
            key={"type-" + tip}
            onClick={() => setNewTip(tip)}
            style={
              tip === selectedTip
                ? selectedTipStyle
                : { color: theme.palette.primary.main }
            }
          >
            {tip}%
          </div>
        );
      }
      if (type === "delivery") {
        return (
          <div
            key={"type-" + tip}
            onClick={() => setNewTip(tip)}
            style={
              tip === selectedDriverTip
                ? selectedTipStyle
                : { color: theme.palette.primary.main }
            }
          >
            {tip}%
          </div>
        );
      }
    });
  }, [
    businessInfo?.tip,
    selectedTip,
    selectedDriverTip,
    setNewTip,
    selectedTipStyle,
  ]);

  if (!businessInfo) return <></>;

  return (
    <FormControl className={classes.tips}>
      <Typography className={classes.label}>{title}</Typography>

      <div className={classes.list}>
        {TipsControls}
        {CustomTipTextField}
      </div>
    </FormControl>
  );
};

export default Tips;
