import classes from "./FoodCourtComps.module.scss";
import CircularProgressWithLabel from "../ProgressFeedback/CircularProgressWithLabel";
import {
  Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, IconButton, Typography
} from "@material-ui/core";
import { BusinessInfo } from "../../Types/Types";
import { appendBaseUrlToImage } from "../../common/image";
import { useHistory } from "react-router";
import { isNumber } from "../../common/price/price";
import { getLoyaltyNote } from "../Rewards/RewardsPopup";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { faGifts } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import shareIcon from "../../img/sharing.png";

interface BizCardProps {
  business: BusinessInfo;
  index: number;
}

const formatRating = (ratingStr?: string): string => {
  if (!isNumber(ratingStr)) return "";

  const ratingNum = Number(ratingStr);
  return (ratingNum / 100 * 5).toFixed(1);
}

const BizCard: React.FC<BizCardProps> = (props) => {
  const { business } = props;
  const history = useHistory();
  const isSignedIn = useTypedSelector(state => state.account.isSignedIn);

  const bizRoutePath = `/${business.routingPath}`;
  const navigateToMenuOnlyView = () => {
    const menuUrl = `${bizRoutePath}/menu`;
    // history.push(menuUrl);
    window.location.href = `${menuUrl}`;
  }

  const navigateToOrderingView = () => {
    // history.push(bizRoutePath);
    window.location.href = `${bizRoutePath}`;
  }

  const bizFeaturedImgList = business.featuredProductImages ?? [""];
  const featuredImgSrc = bizFeaturedImgList[0] || business.logo;
  const featuredImageUri = appendBaseUrlToImage(featuredImgSrc);

  const navigateToBiz = () => {
    business.menuOnly ? navigateToMenuOnlyView() : navigateToOrderingView();
  }

  const navigateToBizRecommend = () => {
    const recommendUrl = `${bizRoutePath}/recommend`;
    history.push(recommendUrl);
  }

  const hasRewardProgram = !!business.reward && !!business.rewardCriteria;
  const hasRewardProgression = !!business.rewardPoints && !!business.rewardStampCardType;

  return (
    <>
      <Card className={classes.card} elevation={5} >

        {featuredImageUri &&
          <CardActionArea
            className={classes.card_media}
            onClick={navigateToBiz}
          >
            <CardMedia
              className={classes.card_media_img}
              component="img"
              src={featuredImageUri}
              title={`Featured Product of ${business.name}`}
            />
          </CardActionArea>}

        <CardContent className={classes.card_content}>

          {formatRating(business.rating) &&
            <Chip
              className={classes.card_rating}
              label={formatRating(business.rating)}
            />}

          <Typography variant="h5" component="p">
            {business.name}
          </Typography>

          <Typography variant="subtitle2" color="textSecondary" component="p">
            {business.secondaryName}
          </Typography>

          <Typography variant="subtitle1" color="textSecondary" component="p"
            className={classes.card_tagline}
          >
            {business.tagLine}
          </Typography>


          {hasRewardProgram &&
            <div className={classes.rewardsRows}>
              {isSignedIn && hasRewardProgression && <div className={classes.rewardProgress}>

                <FontAwesomeIcon
                  className={classes.rewardProgress_rewardsIcon}
                  icon={faGifts} />

                <CircularProgressWithLabel
                  value={business.rewardPoints ?? 0}
                  maxValue={business.rewardStampCardType ?? 0}
                />
              </div>}

              <Typography variant="subtitle1" color="secondary" component="p">
                {`${getLoyaltyNote(business.rewardCriteria)}`}
              </Typography>


              <Typography variant="subtitle1" color="secondary" component="p">
                {`Reward: ${business.reward}`}
              </Typography>
            </div>
          }


        </CardContent>

        <CardActions className={classes.card_actions}>

          <Button size="large" color="primary"
            onClick={navigateToBiz}
          >
            {business.menuOnly ? "View Menu" : "Order Now"}
          </Button>

          {business.creditBalance &&
            <Typography variant="subtitle1"
              color="secondary"
              component="p"
            >
              {`Credit: $${business.creditBalance}`}
            </Typography>
          }

          <IconButton color="primary"
            onClick={navigateToBizRecommend}
          >
            <img
              src={shareIcon}
              alt="shareIcon"
              className={classes.recommendIcon}
            />
          </IconButton>

        </CardActions>

      </Card >
    </>)
};

export default BizCard;
