import classes from "./Modal.module.scss";
import AppButton from "../AppButton/AppButton";
import React, { useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useTheme } from "@mui/material/styles";
import { Close } from "@material-ui/icons";
import { Box, IconButton, Modal, Typography } from "@mui/material";

interface ModalPropsBase {
  open: boolean;
  body: React.ReactNode;
  okLabel?: string;
  cancelLabel?: string;
  title: React.ReactNode;
  buttons?: React.ReactNode;

  onOk?: Function;
  closeOnBackdropClick?: boolean;
  hideHeaderCloseBtn?: boolean;
}

interface ModalProps extends ModalPropsBase {
  onCancel: Function;
  handleClose?: never;
}

interface ModalPropsCustom extends ModalPropsBase {
  onCancel?: never;
  handleClose: Function;
}

const GlobalModal: React.FC<ModalProps | ModalPropsCustom> = (props) => {
  const {
    open,
    title,
    body,
    onOk,
    onCancel,
    buttons,
    closeOnBackdropClick,
    hideHeaderCloseBtn,
  } = props;
  const { handleClose } = props as ModalPropsCustom;
  const { okLabel, cancelLabel } = props as ModalProps;
  const [isOpen, setIsOpen] = useState(open);
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const theme = useTheme();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleOk = () => {
    onOk && onOk();
  };

  const handleCancel = (e) => {
    onCancel && onCancel();
    handleClose && handleClose();
  };

  const Title = useMemo(() => {
    const iconBtn = !hideHeaderCloseBtn && (
      <IconButton onClick={handleCancel} className={classes.closeButton}>
        <Close style={{ color: "white" }} />
      </IconButton>
    );

    return (
      <Typography
        variant="h5"
        className={classes.title}
        style={{ backgroundColor: theme.palette.primary.main }}
      >
        {iconBtn}
        {title}
      </Typography>
    );
  }, [title, hideHeaderCloseBtn, theme, okLabel, handleCancel, handleOk]);

  const Buttons = useMemo(() => {
    if (buttons) {
      return buttons;
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          padding: "0 1rem",
        }}
      >
        <AppButton variant="outlined" isOutlined onClick={handleCancel}>
          {cancelLabel ?? "Cancel"}
        </AppButton>
        <AppButton
          variant="outlined"
          onClick={handleOk}
          className={classes.paper_ok}
        >
          {okLabel ?? "Ok"}
        </AppButton>
      </Box>
    );
  }, [buttons, cancelLabel, okLabel, handleCancel, handleOk]);

  return (
    <Modal
      open={open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason === "backdropClick" && !closeOnBackdropClick) return;
        handleCancel(event);
      }}
    >
      <Box className={classes.paper}>
        {Title}
        <Box className={classes.content}>{body}</Box>
        <Box>
          {Buttons}
        </Box>
      </Box>
    </Modal>
  );
};

export default GlobalModal;
