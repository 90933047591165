import { DetailedHTMLProps, SelectHTMLAttributes } from "react";
import StyledComponent from "../StyledComponent/StyledComponent";
import classes from "./CateringSelection.module.scss";

interface DropDownComboProps extends DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  label: string;
  hasError?: boolean;
  required?: boolean;
}

const DropDownCombo = (props: DropDownComboProps) => {
  const { label, onChange, value, children, className } = props;
  const { hasError = false, required = false } = props;
  const errorClass = hasError ? " " + classes.errorInput : "";

  return (
    <div className={`${classes.inputCombo} ${className}`}>

      <span className={classes.label}>
        {label}
        {required
          ? <span className={classes.requiredIndicator}> *</span>
          : <></>}
      </span>

      <StyledComponent>
        <select
          {...props}
          className={`${classes.select}${errorClass}`}
          onChange={onChange}
          value={value}
        >
          {children}
        </select>
      </StyledComponent>

    </div>
  )
}



export default DropDownCombo;