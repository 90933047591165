import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export interface NameIdSelectType {
  name: string;
  id: string | number;
  selected?: boolean;
}

export interface LabelsAutocompleteProps<T> {
  value: T[];
  options: T[];
  nameSelector: (item: T) => string;
  className?: string;
  inputLabel?: string;
  placeholder?: string;
  onChange?: (event, newValue) => void;
}

const LabelsAutocomplete = <T extends Object>(props: LabelsAutocompleteProps<T>) => {
  const {
    value, options, onChange, nameSelector, inputLabel, placeholder, className
  } = props;

  return (
    <Autocomplete
      multiple
      className={className}
      value={value}
      options={options}
      // disableCloseOnSelect
      filterSelectedOptions
      onChange={onChange}
      getOptionLabel={nameSelector}
      renderInput={(params) => (
        <TextField {...params}
          variant="outlined"
          placeholder={value.length > 0 ? '' : placeholder}
          label={inputLabel}
          style={{
            backgroundColor: 'rgb(255, 255, 255)'
          }}
        />
      )}
    />
  );
}

export default LabelsAutocomplete;
