import classes from "./FoodCourtComps.module.scss";
import { Link, Typography } from "@material-ui/core";

export interface AboutUsProps {

}

const AboutUs: React.SFC<AboutUsProps> = () => {
  return (
    <div className={classes.aboutUs}>
      <div className={classes.aboutUs_text}>
        <Typography variant="body1" component="p" >
          Relayfy Digital Services (Incorporation # BC1112138) is a local tech company based in North Vancouver, BC, Canada. Our mission is to help people find their favourite local eats, treats and drinks more conveniently.
        </Typography>
        <Typography variant="body1" component="p" >
          We are also committed to supporting the technology needs of the restaurants and coffee shops to enable them to compete with the big international chain companies.
        </Typography>
      </div>

      <div className={classes.aboutUs_contacts}>

        <Typography variant="body2" component="p">
          Relayfy Digital Services
        </Typography>

        <Typography variant="body2" component="p">
          <Link href="https://www.relayfy.com" target="_blank" rel="nofollow">
            relayfy.com
          </Link>
        </Typography>

        <Typography variant="body2" component="p">
          300-133 17th Street West, North Vancouver, BC V7M 1V5
        </Typography>

        <Typography variant="body2" component="p">
          <Link href="mailto:webmaster@example.com">
            info@relayfy.com
          </Link>
        </Typography>
        <Typography variant="body2" component="p">
          Tel.:
          <Link href="tel:+1604-434-2526">
            604-434-2526
          </Link>
        </Typography>

      </div>
    </div>
  );
}

export default AboutUs;