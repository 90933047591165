import Popup from "../Popup/Popup";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import classes from "./Account.module.scss";
import { useActions } from "../../hooks/useActions";

export const AccountPopup: React.FC = () => {
  const { externalSignInError } = useTypedSelector((state) => state.account);
  const { clearAccountErrors } = useActions();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    externalSignInError && setOpen(true);
  }, [externalSignInError])

  const handleClose = () => {
    clearAccountErrors();
    setOpen(false);
  };

  const content = (
    <div className={classes.ext_errPopup_content}>
      {externalSignInError}
    </div>
  );

  const footer = (
    <div onClick={handleClose}
      className={classes.ext_errPopup_footer}>
      <hr className={classes.ext_errPopup_footer_separator} />
      <div><a href="#">OK</a></div>
    </div>
  )

  return (
    <Popup
      handleClose={handleClose}
      isOpen={isOpen}
      title="Warning"
      titleWrapStyles={{ backgroundColor: "#FEA833" }}
      popupStyle={{ border: "none", }}
      content={content}
      footer={footer}
    />
  );
};

export default AccountPopup;
