// ThemeContext.tsx
import React, { createContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { createTheme, ThemeProvider, Theme, ThemeOptions } from '@mui/material/styles';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { lighten } from '@mui/material/styles';



interface Palette {
  primary?: {
    main: string;
  };
  secondary?: {
    main: string;
  };
  [key: string]: any;
}

interface ThemeContextProps {
  theme: Theme;
  updateTheme: (newPalette: ThemeOptions) => void;
  updatePalette: (newPalette: Palette) => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

interface ThemeProviderComponentProps {
  children: ReactNode;
}

export const ThemeProviderComponent: React.FC<ThemeProviderComponentProps> = ({ children }) => {
  const [theme, setTheme] = useState(createTheme({
    typography: {
      button: {
        textTransform: 'none'
      }
    },
  }));
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);

  const updateTheme = useCallback((newTheme: ThemeOptions) => {
    setTheme((prevTheme) =>
      createTheme({
        ...prevTheme,
        ...newTheme,
      })
    );
  }, []);

  const updatePalette = useCallback((newPalette: Palette) => {
    setTheme((prevTheme) =>
      createTheme({
        ...prevTheme,
        palette: {
          ...prevTheme.palette,
          ...newPalette,
        },
      })
    );
  }, []);

  useEffect(() => {
    const newColor = businessInfo?.theme?.color || '#000';
    if (theme.palette.primary.main !== newColor) {
      updatePalette({
        primary: {
          main: newColor
        },
        light05: lighten(newColor, 0.95),
        light10: lighten(newColor, 0.9),
        light20: lighten(newColor, 0.8),
        light30: lighten(newColor, 0.7),
        light40: lighten(newColor, 0.6),
        light50: lighten(newColor, 0.5)

      });
    }
  }, [businessInfo?.theme?.color, theme.palette.primary.main, updatePalette]);

  return (
    <ThemeContext.Provider value={{ theme, updateTheme, updatePalette }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
