import {
  DestinationStatus,
  DISCOUNT_VALUE_TYPE,
  FinalOrderPayload,
  preItemType,
} from "../../../Types/Types";
import { OrderState } from "./reducerTypes";

export const initialPreItemState: preItemType = {
  count: 0,
  selectedCustomization: {
    customizationLabel: undefined,
    optionSpecialRequest: "",
    anyOf: undefined,
    oneOfList: undefined,
  },
  disabled: false,
  discountRule: {
    days: [],
    endDate: "",
    startDate: "",
    endTime: 0,
    startTime: 0,
    title: "",
    type: DISCOUNT_VALUE_TYPE.amount,
    value: 0,
  },
  name: "",
  image: "",
  description: "",
  optionName: "",
  optionsType: "",
  price: 0,
  productOptionId: 0,
  productImage: null,
  productDescription: null,
  specialRequest: "",
};

export const initialFinalOrderState: FinalOrderPayload = {
  businessId: "",
  calculatedDiscounts: [],
  calculatedMobilePayDiscount: undefined,
  customerName: "",
  finalOrderItems: [],
  instructions: "",
  orderNumber: "",
  sourceApp: "",
  orderType: undefined,
  phoneOS: "",
  selectedTipPercentage: undefined,
  driverTipPercentage: undefined,
  serviceProviderUserId: "",
  subTotal: 0,
  tax: 0,
  // requestedPrepTime: undefined,
  deliveryFee: 0,
  total: 0,
  userPhoneNumber: "",
  smsNotificationRequested: true,
  userId: undefined,
  paperBagCount: 1,
  requestedPrepDateTime: undefined,
  requestedPrepDateTimeString: undefined,
  deliveryAddress: {
    destinationStatus: DestinationStatus.standby,

    distance: undefined,
    duration: undefined,
    latitude: undefined,
    longitude: undefined,

    completeAddress: undefined,
    unitNumber: undefined,
    deliveryInstruction: undefined,
  },
};

export const initialState: OrderState = {
  cart: [],
  preItems: initialPreItemState,
  tip: undefined,
  order: initialFinalOrderState,
  finalOrder: initialFinalOrderState,
};
