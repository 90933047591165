import classes from "./Checkout.module.scss";
import { useParams } from "react-router";
import { OrderMode } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { getOrderMode } from "../../common/app";
import { useActions } from "../../hooks/useActions";
import { padTimePart } from "../../common/date";
import useDateTimeOptions from "./hooks/useDateTimeOptions";
import { CheckoutFormStatus } from "../../Types/Types";
import { useEffect, useMemo } from "react";
import { FormControl, FormHelperText, Input, InputAdornment, Select, Typography } from "@material-ui/core";
import AlarmIcon from '@material-ui/icons/Alarm';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';

interface DateTimeFieldsProps {
    // preOrderError: [boolean, Dispatch<boolean>];
}

const DefaultTimeOption = (isOrderTypePreOrder: boolean) => {
    return <option key={-1} value={-1}>
        {isOrderTypePreOrder ? "Please select a time" : "As soon as possible"}
    </option>
}

const DefaultDateOption = <option key={-1} value={-1}>
    Please select a date
</option>;

const InputWithAlarmIcon = <Input
    startAdornment={
        <InputAdornment position="start">
            <AlarmIcon />
        </InputAdornment>
    }
/>

const InputWithCalendarIcon = <Input
    startAdornment={
        <InputAdornment position="start">
            <CalendarTodayOutlinedIcon />
        </InputAdornment>
    }
/>

const DateTimeFields = (props: DateTimeFieldsProps) => {
    const { } = props;
    const { setPreOrderDateTime, updateCheckoutFormStatus } = useActions();

    const { viewMode } = useParams<IPublicProfileRouteParams>();
    const finalOrder = useTypedSelector((state) => state.orderDetails.order);
    const { checkoutFormStatus } = useTypedSelector((state) => state.appLocalStatus);
    const isOrderTypePreOrder = finalOrder.orderType === "pre-order";
    const isDelivery = finalOrder.orderType === "delivery";
    const appViewMode = getOrderMode(viewMode);
    const isKioskViewMode = appViewMode === OrderMode.kiosk;
    const isCateringViewMode = appViewMode === OrderMode.catering;

    const { selectedDateOptionIndex, selectedTimeOptionIndex, dateTimeOptions,
        selectedPrepDate, timeOptions } = useDateTimeOptions();

    const isDateTimeOrderTypes = isOrderTypePreOrder || isDelivery;

    const shouldDateFieldShow = isDateTimeOrderTypes && !isKioskViewMode && !isCateringViewMode;
    const shouldTimeFieldShow = !isKioskViewMode && !isCateringViewMode;

    const isValidOrderTypes = isOrderTypePreOrder || isCateringViewMode;
    const showDateTimeError = isValidOrderTypes && checkoutFormStatus === CheckoutFormStatus.missingFields && !finalOrder.requestedPrepDateTime;

    const TimeValidationMsg = useMemo(() => {
        if (!showDateTimeError) {
            return null;
        }

        return <FormHelperText color="error">A Time must be selected!</FormHelperText>
    }, [showDateTimeError])

    const DateValidationMsg = useMemo(() => {
        if (!showDateTimeError) {
            return null;
        }

        return <FormHelperText color="error">A Date must be selected!</FormHelperText>
    }, [showDateTimeError])


    const TimeOptions = useMemo(() => timeOptions.map((time, index) => {
        const isAM = time.hour < 12;
        const hour = padTimePart(
            time.hour < 13 ? time.hour : time.hour - 12
        );
        const min = padTimePart(time.minute);
        const postFix = isAM ? `AM` : `PM`;
        const combined = `${hour}:${min} ${postFix}`;
        return (
            <option key={combined} value={index}>
                {combined}
            </option>
        );
    }), [timeOptions]);

    const TimeField = useMemo(() => {
        if (!shouldTimeFieldShow) {
            return null;
        }

        return <FormControl className={classes.dropdownRow} error={showDateTimeError}>
            <Select
                name="time"
                id="time"
                native
                disabled={isCateringViewMode}
                className={classes.select}
                value={selectedTimeOptionIndex}
                defaultValue={-1}
                input={InputWithAlarmIcon}
                onChange={(e) => {
                    const optionIndex = Number(e.target.value);
                    const timeOption = timeOptions[+optionIndex];
                    if (!timeOption || optionIndex < 0) {
                        setPreOrderDateTime(undefined);
                        return;
                    }

                    let newPrepDate = !selectedPrepDate ? new Date() : selectedPrepDate;
                    newPrepDate.setHours(timeOption.hour);
                    newPrepDate.setMinutes(timeOption.minute);

                    setPreOrderDateTime(newPrepDate.getTime());
                }}
            >
                {DefaultTimeOption(isOrderTypePreOrder)}
                {TimeOptions}
            </Select>
            {TimeValidationMsg}
        </FormControl>
    }, [shouldTimeFieldShow, isOrderTypePreOrder, TimeOptions, timeOptions, selectedTimeOptionIndex, TimeValidationMsg]);

    const DateOptions = useMemo(() => dateTimeOptions.map((dateOption, index) => {
        const date = dateOption.date;
        return (
            <option key={date.getDate()} value={index}>
                {date.toLocaleDateString("en-CA", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                })}
            </option>
        );
    }), [dateTimeOptions]);

    const DateField = useMemo(() => {
        if (!shouldDateFieldShow) {
            return null;
        }

        return <FormControl className={classes.dropdownRow} error={showDateTimeError}>
            <Select
                name="date"
                id="date"
                native
                disabled={isCateringViewMode}
                className={classes.select}
                value={selectedDateOptionIndex}
                defaultValue={-1}
                input={InputWithCalendarIcon}
                onChange={(e) => {
                    const optionIndex = Number(e.target.value);
                    const dateOption = dateTimeOptions[optionIndex];
                    if (!dateOption) {
                        setPreOrderDateTime(undefined);
                        return;
                    }
                    const firstAvailableTimeOption =
                        dateOption.timeOptions[0];
                    const selectedDate = new Date();
                    selectedDate.setFullYear(
                        dateOption.date.getFullYear()
                    );
                    selectedDate.setMonth(dateOption.date.getMonth());
                    selectedDate.setDate(dateOption.date.getDate());
                    selectedDate.setHours(firstAvailableTimeOption.hour);
                    selectedDate.setMinutes(
                        firstAvailableTimeOption.minute
                    );
                    setPreOrderDateTime(selectedDate.getTime());
                    updateCheckoutFormStatus(CheckoutFormStatus.standby)
                }}
            >
                {DefaultDateOption}
                {DateOptions}
            </Select>
            {DateValidationMsg}
        </FormControl>
    }, [shouldDateFieldShow, DateOptions, selectedDateOptionIndex, dateTimeOptions, DateValidationMsg]);


    if (!shouldDateFieldShow && !shouldTimeFieldShow) {
        return null;
    }

    return <div className={classes.dropdownContainer}>

        {DateField}

        {TimeField}

    </div>
}


export default DateTimeFields;