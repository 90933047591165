import React, { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomizeModal from "../CustomizeModal/CustomizeModal";

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: 2,
  },
  content: {
    padding: "0",
    border: "none",
    borderRadius: "0",
  },
};

interface MenuImagePopoverProps {
  index: number;
  setShowModal: Function;
}


const MenuImagePopover: React.FC<MenuImagePopoverProps> = ({ index, setShowModal }) => {
  const preItem = useTypedSelector((state) => state.orderDetails.preItems);
  const [showCustomize, setShowCustomize] = useState(false);

  useEffect(() => {
    setShowCustomize(true);
  }, [])

  const closeModal = () => {
    setShowModal();
  }

  if (!showCustomize) {
    return null
  }

  return (
    <CustomizeModal
      index={index}
      isOpen={showCustomize}
      style={modalStyle}
      setShowCustomize={closeModal}
      closeModal={closeModal}
      type="pre"
    />
  );
};

export default MenuImagePopover;
