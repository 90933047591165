import classes from "./Feedback.module.scss";
import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FeedbackConfirmation from "./FeedbackConfirmation";
import { useParams } from "react-router";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  GetBusinessInfo,
  LegacyBusinessInfo,
  PutFeedback,
} from "../../api/index";
import { getCookie } from "../../common/cookies";
import { useHistory } from "react-router";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
export interface ITrackingRouteParams {
  orderNumber: string;
  userId?: string;
}

const Feedback: React.FC = () => {
  const { orderNumber } = useParams<{ orderNumber?: string }>();
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const [star, setStar] = useState(5);
  const [storeData, setStoreData] = useState<LegacyBusinessInfo>();
  const [isSent, setIsSent] = useState(false);
  const [feedbackMsg, setFeedbackMsg] = useState("");
  const history = useHistory();

  const goBack = (e) => {
    history.goBack();
  };

  useEffect(() => {
    if (!businessInfo?.serviceProviderUserId) return;

    const getInfo = async () => {
      const info = await GetBusinessInfo(businessInfo.serviceProviderUserId);

      setStoreData(info);
    };
    getInfo();
  }, [businessInfo?.serviceProviderUserId]);

  const handleChange = (e) => {
    const asNumber = +e.target.value;
    console.log("RADIO: ", asNumber, typeof asNumber);
    setStar(asNumber);
  };

  const cancel = () => {
    if (orderNumber) {
      history.goBack();
      return;
    }

    if (businessInfo?.routingPath) {
      history.push(`/${businessInfo.routingPath}`);
    }
  };

  const putFeedback = async () => {
    const userId = getCookie("userId");

    try {
      const res = await PutFeedback({
        userScore: star * 2,
        userComment: feedbackMsg,
        userId: userId ? +userId : 999999,
        ratingsId: storeData?.ratings.ratingsId,
        ratingId: 0,
        orderId: orderNumber ? +orderNumber : 999999,
      });

      console.log(res);

      setIsSent(true);
    } catch (err) {
      console.error(err);
      alert("Something went wrong, please try again later.");
    }
  };

  const RadioElem = (
    <Radio
      sx={{
        color: "primary.main",
      }}
    />
  );
  return !isSent ? (
    <div className={classes.body}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: "12px",
          padding: "10px 10px",
          cursor: "pointer",
          backgroundColor: "primary.main",
          color: "#fff",
          minHeight: "50px",
        }}
      >
        <ArrowBackIcon
          onClick={goBack}
          sx={{ color: "#fff", fontSize: "24px" }}
        />
        <span
          style={{
            color: "#fff",
            fontSize: "20px",
            fontWeight: 400,
          }}
        >
          Back to Order Status
        </span>
      </Box>
      <div className={classes.wrapper}>
        <Typography
          variant="h2"
          fontSize={32}
          fontWeight={500}
          sx={{
            margin: "2rem 0 !important",
            color: "#1D1B20",
          }}
        >
          How was your experience?
        </Typography>
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="position"
              name="position"
              value={star.toString()}
              onChange={handleChange}
            >
              <FormControlLabel
                value="1"
                control={RadioElem}
                label={radioLabel(1)}
              />
              <FormControlLabel
                value="2"
                control={RadioElem}
                label={radioLabel(2)}
              />
              <FormControlLabel
                value="3"
                control={RadioElem}
                label={radioLabel(3)}
              />
              <FormControlLabel
                value="4"
                control={RadioElem}
                label={radioLabel(4)}
              />
              <FormControlLabel
                value="5"
                control={RadioElem}
                label={radioLabel(5)}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.feedback}>
          <div className={classes.feedback_text}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "100%" },
                padding: "1rem 0 !important",
              }}
            >
              <TextField
                placeholder=""
                id="standard-multiline-static"
                label="Feedback"
                helperText="We want to hear from you!"
                multiline
                rows={1}
                //
                variant="standard"
                value={feedbackMsg}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => setFeedbackMsg(e.currentTarget.value)}
              />
            </Box>
          </div>
          <div className={classes.feedback_buttons}>
            <Button
              onClick={putFeedback}
              sx={{
                backgroundColor: "primary.main",
                width: "90%",
                padding: "12px 0",
                margin: "1rem auto",
                borderRadius: "100px",

                "&:hover": {
                  backgroundColor: "primary.main",
                },
              }}
            >
              <Typography
                sx={{
                  color: "primary.contrastText",
                  fontSize: "20px",
                  fontWeight: "500",
                }}
              >
                Submit
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <FeedbackConfirmation star={star} storeData={storeData} />
  );
};

const radioLabel = (numOfStar: number) => {
  switch (numOfStar) {
    case 1:
      return (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              "& .MuiRating-icon": {
                fontSize: "20px",
              },
            }}
          >
            <Rating
              name="read-only"
              value={numOfStar}
              readOnly
              emptyIcon={
                <StarIcon style={{ color: "#D8D8D8" }} fontSize="inherit" />
              }
            />
            <Typography
              color="#49454F"
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                verticalAlign: "middle",
              }}
            >
              Disappointed
            </Typography>
          </Box>
        </>
      );
    case 2:
      return (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              "& .MuiRating-icon": {
                fontSize: "20px",
              },
            }}
          >
            <Rating
              name="read-only"
              value={numOfStar}
              readOnly
              emptyIcon={
                <StarIcon style={{ color: "#D8D8D8" }} fontSize="inherit" />
              }
            />
            <Typography
              color="#49454F"
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                verticalAlign: "middle",
              }}
            >
              Somewhat Disappointed
            </Typography>
          </Box>
        </>
      );
    case 3:
      return (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              "& .MuiRating-icon": {
                fontSize: "20px",
              },
            }}
          >
            <Rating
              name="read-only"
              value={numOfStar}
              readOnly
              emptyIcon={
                <StarIcon style={{ color: "#D8D8D8" }} fontSize="inherit" />
              }
            />
            <Typography
              color="#49454F"
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                verticalAlign: "middle",
              }}
            >
              Might come back
            </Typography>
          </Box>
        </>
      );
    case 4:
      return (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              "& .MuiRating-icon": {
                fontSize: "20px",
              },
            }}
          >
            <Rating
              name="read-only"
              value={numOfStar}
              readOnly
              emptyIcon={
                <StarIcon style={{ color: "#D8D8D8" }} fontSize="inherit" />
              }
            />
            <Typography
              color="#49454F"
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                verticalAlign: "middle",
              }}
            >
              Surely come back again!
            </Typography>
          </Box>
        </>
      );
    case 5:
      return (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              "& .MuiRating-icon": {
                fontSize: "20px",
              },
            }}
          >
            <Rating
              name="read-only"
              value={numOfStar}
              readOnly
              emptyIcon={
                <StarIcon style={{ color: "#D8D8D8" }} fontSize="inherit" />
              }
            />
            <Typography
              color="#49454F"
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                verticalAlign: "middle",
              }}
            >
              Love it and will recommend!
            </Typography>
          </Box>
        </>
      );
  }
};

export default Feedback;
