import { TextField } from "@material-ui/core";
import { useEffect } from "react";
import {
  createDeliveryInstructionKey,
  createDeliveryUnitNumberKey,
  getCookie,
  setCookie,
} from "../../common/cookies";
import {
  deliveryInstructionsHelperText,
  deliveryInstructionsLabel,
  deliveryInstructionsPlaceholder,
  deliveryUnitFieldLabel,
  deliveryUnitPlaceholder,
  isDebug,
} from "../../constants";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { DestinationStatus } from "../../Types/Types";
import classes from "./Checkout.module.scss";
import { GoogleAutocomplete } from "./GoogleAutocomplete";
import { useDeliveryAddress } from "./hooks/useDeliveryAddress";

interface DeliveryFieldsProps {}

const DeliveryFields = (props: DeliveryFieldsProps) => {
  //   const {} = props;
  const { setDeliveryInfo, updateDestStatus } = useActions();
  const { ErrorMessage } = useDeliveryAddress();
  const finalOrder = useTypedSelector((state) => state.orderDetails.order);
  const destinationInfo = finalOrder.deliveryAddress;

  const unitNumberCookieKey = createDeliveryUnitNumberKey();
  const deliveryInstructionKey = createDeliveryInstructionKey();

  useEffect(() => {
    if (!destinationInfo.unitNumber || !destinationInfo.deliveryInstruction) {
      const unitNumberCookie = getCookie(unitNumberCookieKey);
      const unitNumberParsed = JSON.parse(unitNumberCookie!);

      const deliverInstructionCookie = getCookie(deliveryInstructionKey);
      const deliveryInstructionParsed = JSON.parse(deliverInstructionCookie!);

      if (!unitNumberParsed || !deliveryInstructionParsed) return;

      setDeliveryInfo({
        ...destinationInfo,
        unitNumber: unitNumberParsed,
        deliveryInstruction: deliveryInstructionParsed,
      });
    }
  }, [destinationInfo]);

  if (finalOrder.orderType !== "delivery") return <></>;

  return (
    <>
      <section className={classes.deliveryFields}>
        {isDebug && (
          <button onClick={(e) => updateDestStatus(DestinationStatus.error)}>
            Press
          </button>
        )}

        <GoogleAutocomplete />

        <TextField
          type="text"
          name="unitNumber"
          autoComplete="off"
          label={deliveryUnitFieldLabel}
          className={classes.textField}
          placeholder={deliveryUnitPlaceholder}
          value={destinationInfo?.unitNumber}
          inputProps={{
            maxLength: 50,
            onInput: (e) => {
              setDeliveryInfo({
                ...destinationInfo,
                destinationStatus: DestinationStatus.standby,
                unitNumber: e.currentTarget.value.toString(),
              });
              setCookie(
                unitNumberCookieKey,
                JSON.stringify(e.currentTarget.value.toString())
              );
            },
          }}
        />
        <TextField
          type="text"
          autoComplete="off"
          name="deliveryInstructions"
          className={classes.textField}
          label={deliveryInstructionsLabel}
          placeholder={deliveryInstructionsPlaceholder}
          helperText={deliveryInstructionsHelperText}
          value={destinationInfo?.deliveryInstruction}
          inputProps={{
            maxLength: 150,
            onInput: (e) => {
              setDeliveryInfo({
                ...destinationInfo,
                destinationStatus: DestinationStatus.standby,
                deliveryInstruction: e.currentTarget.value,
              });
              setCookie(
                deliveryInstructionKey,
                JSON.stringify(e.currentTarget.value)
              );
            },
          }}
        />

        {ErrorMessage}
      </section>
    </>
  );
};

export default DeliveryFields;
