import SvgIcon from '@mui/material/SvgIcon';

const RejectedIcon = () => {
    return (
        <SvgIcon style={{fontSize:40}}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.2167 5H13.7833L5 13.7833V26.2167L13.7833 35H26.2167L35 26.2167V13.7833L26.2167 5ZM31.6667 24.8333L24.8333 31.6667H15.1667L8.33333 24.8333V15.1667L15.1667 8.33333H24.8333L31.6667 15.1667V24.8333ZM24.7167 12.9333L20 17.65L15.2833 12.9333L12.9333 15.2833L17.65 20L12.9333 24.7167L15.2833 27.0667L20 22.35L24.7167 27.0667L27.0667 24.7167L22.35 20L27.0667 15.2833L24.7167 12.9333Z" fill="#C12335"/>
            </svg>

        </SvgIcon>
    )
}

export default RejectedIcon;