import classes from "./MenuCard.module.scss";
import { useMemo, useState } from "react";
import { MenuType } from "../../Types/Types";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import Slider, { Settings } from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SlickOverwrite.scss";
import MenuCardItem from "./MenuCardItem";
import SliderArrow from "./SliderArrow";

interface MenuSliderProps {
  menu: MenuType;
  UnescapeHTML: Function;
  addCart: Function;
  setShowSnackBar: Function;
}

const MenuCardSlider = ({
  menu,
  UnescapeHTML,
  addCart,
  setShowSnackBar
}: MenuSliderProps) => {
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const [showNext, setShowNext] = useState<boolean>(true);
  const [showPrev, setShowPrev] = useState<boolean>(false);
  const [isAfterSwipe, setIsAfterSwipe] = useState<boolean>(false);

  const themeColor = () => {
    if (!businessInfo) return "black";
    return businessInfo.theme.color;
  };

  const NextArrow = (props: any) => {
    if (!showNext) return <></>;
    return (
      <SliderArrow {...props} icon={faChevronRight}
      />
    );
  };

  const PrevArrow = (props: any) => {
    if (!showPrev) return <></>;
    return (
      <SliderArrow {...props} icon={faChevronLeft}
      />
    );
  };

  const swipeOption = (current: number) => {
    const SlideIndex = current + 1;

    if (SlideIndex === menu.options.length) {
      setShowNext(false);
      setShowPrev(true);
    } else if (
      SlideIndex < menu.options.length &&
      SlideIndex !== menu.options.length &&
      current !== 0
    ) {
      setShowPrev(true);
      setShowNext(true);
    } else if (current === 0) {
      setShowPrev(false);
      setShowNext(true);
    }
  };

  const settings: Settings = {
    // centerMode: false,
    infinite: false,
    arrows: false,
    // centerPadding: "70px",
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    slidesToShow: 1,
    speed: 400,
    afterChange: swipeOption,
    onSwipe: () => {
      setIsAfterSwipe(true);
    },
    swipe: true,
    swipeToSlide: true,
    className: `slider variable-width menu-slider`,
    variableWidth: true,
  }

  const isLargeScreen = window.innerWidth > 1200;
  const OptionsRowList = useMemo(() => {
    return menu.options.map((option, i) => {
      return (
        <MenuCardItem
          menu={menu}
          getStyle={themeColor()}
          UnescapeHTML={UnescapeHTML}
          addCart={addCart}
          key={i}
          option={option}
          index={i}
          isAfterSwipe={isAfterSwipe}
          setIsAfterSwipe={setIsAfterSwipe}
          setShowSnackBar={setShowSnackBar}
        />
      );
    });
  }, [menu, themeColor, UnescapeHTML, addCart, isAfterSwipe, setShowSnackBar])

  return (
    <>
      {/* must make it so that it doesn't create a slider when it is all visible on the screen */}
      {isLargeScreen ?
        <Slider {...settings}>
          {OptionsRowList}
        </Slider>
        :
        <section className={classes.rowWrapper}>
          {OptionsRowList}
        </section>
      }
    </>
  );
};


export default MenuCardSlider;
