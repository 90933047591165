import classes from "./FoodCourtComps.module.scss";
import { Chip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { BizLabel, getLabels } from "../../api/foodCourt";
import LabelsAutocomplete from "../LabelsAutocomplete/LabelsAutocomplete";

export interface FoodCourtBannerProps {
  drawerOpen: boolean;
  handleDrawerOpen: () => void;
  setLabelsList: Function;
  labelsList: BizLabel[];
}

const FoodCourtBanner: React.FC<FoodCourtBannerProps> = (props) => {
  const { labelsList, setLabelsList } = props;
  const [allLabelsList, setAllLabelsList] = useState<BizLabel[]>([]);

  useEffect(() => {
    const fetchLabels = async () => {
      const labels = await getLabels();
      setAllLabelsList(labels);
    }

    fetchLabels();

    return () => {
      setLabelsList([]);
    }
  }, [setAllLabelsList, setLabelsList])

  return (
    <div className={classes.banner} >

      <div className={classes.labelsAutocomplete_wrap}>
        <LabelsAutocomplete
          className={classes.labelsAutocomplete_input}
          placeholder="eg. #vegan; #greek; #seafood..."
          value={labelsList}
          options={allLabelsList}
          nameSelector={option => `#${option.businessLabel}`}
          onChange={(event, newValue) => {
            const newValueList = newValue as BizLabel[];
            setLabelsList(newValueList);
          }}
        />
      </div>

      <div className={classes.banner_popularTags}>

        {allLabelsList.filter(label => !labelsList.includes(label))
          .filter((l, ind) => ind < 5)
          .map(label => (
            <Chip
              key={label.businessLabelId}
              className={classes.banner_popularTags_tag}
              label={`#${label.businessLabel}`}
              onClick={(e) => setLabelsList([...labelsList, label])}
              clickable
            />
          )
          )}
      </div>

    </div >
  );
}

export default FoodCourtBanner;