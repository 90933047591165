import { TextField } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CheckoutFormStatus,
  DeliveryCookie,
  DeliveryInfo,
  DestinationStatus,
} from "../../Types/Types";
import {
  createDeliveryAddressKey,
  getCookie,
  setCookie,
} from "../../common/cookies";
import { deliveryAddressPlaceholder } from "../../constants";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import classes from "./Checkout.module.scss";
import { useDeliveryAddress } from "./hooks/useDeliveryAddress";

(window as any).initMap = () => {};

const eventName = "place_changed";
const typesNotNeedComma = ["street_number", "postal_code"];

const typesNotNeeded = [
  "administrative_area_level_2",
  "administrative_area_level_3",
];

export const GoogleAutocomplete = () => {
  const { setDeliveryInfo, updateCheckoutFormStatus } = useActions();

  const finalOrder = useTypedSelector((state) => state.orderDetails.order);
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const { checkoutFormStatus } = useTypedSelector(
    (state) => state.appLocalStatus
  );
  const destinationStatus = finalOrder.deliveryAddress.destinationStatus;
  const deliveryAddress = finalOrder.deliveryAddress;
  const { ValidateDestination } = useDeliveryAddress();
  const [inputValue, setInputValue] = useState(
    deliveryAddress?.completeAddress ?? ""
  );
  const [addressInput, setAddressInput] = useState<HTMLInputElement | null>(
    null
  );
  const addressInputRef = useRef<HTMLInputElement>(null);
  const destinationInfo = finalOrder.deliveryAddress;

  const createAutoCompleteListener = useCallback(
    (ac: google.maps.places.Autocomplete) => {
      return async () => {
        const place = ac.getPlace();
        if (!place.address_components) return;

        const fullAddr = place.address_components
          ?.filter((x) => !x.types.find((t) => typesNotNeeded.includes(t)))
          ?.map((x) => {
            let short_name = x.short_name;
            if (x.types.find((t) => typesNotNeedComma.includes(t))) {
              return short_name;
            }

            return (short_name += ",");
          })
          ?.join(" ");

        const loc = place.geometry?.location;
        if (!loc) return;

        let newDeliveryInfo: DeliveryInfo = {
          destinationStatus: DestinationStatus.valid,
          latitude: loc.lat(),
          longitude: loc.lng(),
          unitNumber: destinationInfo?.unitNumber,
          deliveryInstruction: destinationInfo?.deliveryInstruction,
          completeAddress: fullAddr,
          addressComponents: place.address_components,
        };
        newDeliveryInfo = await ValidateDestination(loc, newDeliveryInfo);
        if (!newDeliveryInfo) return;

        setInputValue(fullAddr);

        const cookieKey = createDeliveryAddressKey();
        setCookie(
          cookieKey,
          JSON.stringify({ deliveryAddress: newDeliveryInfo, location: loc })
        );
        return fullAddr;
      };
    },
    [businessInfo?.serviceProviderUserId]
  );

  const createGoogleAutoComplete = useCallback((input) => {
    if (!input) return null;

    const ac = new google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: ["us", "ca"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });

    ac.addListener(eventName, createAutoCompleteListener(ac));

    return ac;
  }, []);

  const [autocomplete, setAutocomplete] = useState(
    createGoogleAutoComplete(addressInput)
  );

  useEffect(() => {
    const cookieKey = createDeliveryAddressKey();
    const storageDeliveryInfo = getCookie(cookieKey);
    if (!storageDeliveryInfo) return;

    const parsed = JSON.parse(storageDeliveryInfo) as DeliveryCookie;
    const deliveryInfo = parsed.deliveryAddress;
    const location = parsed.location;

    const validateDest = async () => {
      const newDeliveryInfo = await ValidateDestination(
        parsed.location,
        deliveryInfo
      );
      if (!newDeliveryInfo) return;
      setDeliveryInfo(newDeliveryInfo);
      if (deliveryInfo.completeAddress) {
        setInputValue(deliveryInfo.completeAddress);
      }
    };

    validateDest();
  }, [addressInput]);

  const hasError =
    destinationStatus === DestinationStatus.error ||
    checkoutFormStatus === CheckoutFormStatus.missingFields;

  return (
    <div>
      <TextField
        autoFocus
        required
        label="Address"
        type="text"
        autoComplete="off"
        error={hasError}
        value={inputValue}
        className={classes.textField}
        inputRef={(ref) => setAddressInput(ref)}
        placeholder={deliveryAddressPlaceholder}
        inputProps={{
          maxLength: 100,

          onInput: (e) => {
            const value = e.currentTarget.value;
            if (!value) {
              setDeliveryInfo({
                ...deliveryAddress,
                destinationStatus: DestinationStatus.standby,
                completeAddress: "",
              });
              setInputValue(value);
            } else {
              setInputValue(value);
              updateCheckoutFormStatus(CheckoutFormStatus.standby);
            }
          },
        }}
      ></TextField>
    </div>
  );
};
