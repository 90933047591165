import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    AccordionProps
} from "@mui/material";
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactNode } from "react";


type ChoiceAccordionProps = {
    displayElement: ReactNode,
    children: ReactNode,
} & AccordionProps

const ChoiceAccordion = (props: ChoiceAccordionProps) => {
    const { displayElement, children, ...accordionProps } = props;

    return <Accordion
        elevation={0}
        disableGutters
        sx={{
            width: "100%",
        }}
        {...accordionProps}
    >
        <AccordionSummary
            expandIcon={
                <ExpandMoreIcon />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <>{displayElement}</>
        </AccordionSummary>
        <AccordionDetails
            sx={{
                marginLeft: "24px",
                display: "flex",
                flexDirection: "column",
                gap: "12px"
            }}
        >
            <>{children}</>
        </AccordionDetails>
    </Accordion>;
}

export default ChoiceAccordion;

const Accordion = withStyles({
    root: {
        // border: '1px solid rgba(0, 0, 0, .125)',
        border: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {
        boxShadow: 'none',
    },
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        border: 'none',
        padding: '0 !important',
        minHeight: "24px !important",
        '&$expanded': {
            minHeight: "24px !important",
        },
    },
    content: {
        margin: "0 !important",
        '&$expanded': {
            margin: "0 !important",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);
