import classes from "./FoodCourtComps.module.scss";
import { Button, Typography } from "@material-ui/core";

const NoBizFound = (props) => {
  const { labelsList, setLabelsList } = props;
  return (
    <div className={classes.noBizFound}>
      <div className={classes.noBizFound_labels}>
        <Typography variant="h5" component="div">
          We didn’t find a match for
        </Typography>
        <Typography variant="h5" component="div">
          {labelsList.map(label => `#${label.businessLabel}`).join(', ')}
        </Typography>
      </div>

      <Typography variant="h6" component="div">
        Try searching for something else instead
      </Typography>
      <Typography variant="h5" component="div">
        or
      </Typography>

      <Button variant="contained"
        onClick={e => setLabelsList([])}
        className={classes.highContrastBtn}
      >
        View All
      </Button>
    </div>
  );
};

export default NoBizFound;
