import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import classes from "./Account.module.scss";
import AccountPopup from "./AccountPopup";
import { useTypedSelector } from "../../hooks/useTypedSelector";
/* Material UI Imports*/
import Button from '@mui/material/Button';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from "../../assets/icons/GoogleIcon";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


interface AccountPageProp {
  isFromOrderingRoute?: boolean;
}



const ExternalSignIn = (props: AccountPageProp) => {
  const { isFromOrderingRoute } = props;
  const params = useParams<{ businessName: string }>();
  const { signInWithGoogle, signInWithApple } = useActions();
  const {businessInfo} = useTypedSelector(state => state.businessInfo);
  return (
    <div className={classes.external}>
      <AccountPopup />
        <Stack direction="column" spacing={3}>
            <Button 
                variant="contained" 
                startIcon={<AppleIcon />}
                onClick={e => signInWithApple()}
                sx={{
                    backgroundColor: '#000',
                    padding: '15px 0',
                    borderRadius: '10px',
                    '&:hover': {
                        backgroundColor: '#000'
                    }
                }}
                >
                    <Typography color='#fff' fontWeight={700}>Sign In with Apple</Typography>
            </Button>
            <Button 
                variant="contained" 
                startIcon={<GoogleIcon />}
                onClick={e => signInWithGoogle()}
                sx={{
                    padding: '15px 0',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                    color: '#000008',
                    '&:hover': {
                        backgroundColor: '#fff'
                    }
                }}
            >
                <Typography color='#0000008A' fontWeight={700}>Sign In with Google</Typography>
            </Button>
            {isFromOrderingRoute &&
        <Button
            component={Link}
          to={`/${params.businessName ?? ""}`}>
          <Typography
            color={businessInfo?.theme?.color || '#000'}
          >
            Continue as guest
          </Typography>
        </Button>
      }
        </Stack>
    </div>
  );
}

export default ExternalSignIn;