import classes from "./OrderTracking.module.scss";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { validateEmail } from "../../common/account/account";
import { SendReceipt } from "../../api";
import { FormGroup } from "@material-ui/core";
import CheckboxLabels from "../CustomizeModal/CheckboxButton";
import GlobalModal from "../Modal/Modal";
import { isNumber } from "../../common/price/price";
import InputWithIcons from "../Account/InputWithIcon";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ForwardIcon from "../../assets/icons/forwardIcon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
interface EmailReceiptProps {
  orderNumber: string | number | undefined;
  hasRewardsProgram: boolean;
  userId: string;
}

const EmailReceipt = (props: EmailReceiptProps) => {
  const { orderNumber, userId, hasRewardsProgram } = props;
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const { isSignedIn, credential } = useTypedSelector((state) => state.account);
  const { email } = credential;
  const [isSent, setIsSent] = useState(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isStampsChecked, setIsStampsChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [isEmailPromosChecked, setIsEmailPromosChecked] = useState(false);

  const onEmailInput = (e: any) => {
    const newValue = e.target.value;
    setUserEmail(newValue);
    if (newValue === "") {
      setShowError(false);
      setIsDisabled(true);
      return;
    }
    const validEmail = validateEmail(newValue);
    setShowError(!validEmail);
    setIsDisabled(!validEmail);
  };

  // check whether the existing email is valid and enable/disable the button accordingly
  useEffect(() => {
    if (userEmail) {
      const validEmail = validateEmail(userEmail);
      setShowError(!validEmail);
      setIsDisabled(!validEmail);
    }
  }, [userEmail]);

  const onCancel = () => {
    setOpen(false);
  };

  const onOk = () => {
    setOpen(false);
    let receiptJson = {
      orderNumber: orderNumber ? orderNumber : "1",
      emailId: userEmail,
      smsNumber: "",
      rewardsProgram: isStampsChecked ? "yes" : "no",
      emailOffersPromos: isEmailPromosChecked
    };
    SendReceipt(receiptJson).then((res) => {
      if (res) {
        setIsSent(true);
      } else {
        setIsSent(false);
        alert("Oops something went wrong...");
      }
    });
  };

  const modalBody = (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "80%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography component={"p"}>
          Would you like your receipt sent to
        </Typography>
        <Typography
          fontWeight={600}
          sx={{
            marginTop: "-8px",
          }}
        >
          {userEmail}
        </Typography>
        {hasRewardsProgram && (
          <FormGroup row className={classes.modalBody_checkbox}>
            <CheckboxLabels
              isChecked={isStampsChecked}
              controlLabel="Get my bonus rewards points"
              checkOnChange={(i) => setIsStampsChecked(!isStampsChecked)}
              getTheme={businessInfo ? businessInfo.theme.color : ""}
              index={1}
            />
            <CheckboxLabels
              isChecked={isEmailPromosChecked}
              controlLabel="Receive Offers and Promotions"
              checkOnChange={(e) => setIsEmailPromosChecked(!isEmailPromosChecked)}
              getTheme={businessInfo ? businessInfo.theme.color : ""}
              index={2}
            />
          </FormGroup>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      {isSent ? (
        <div className={classes.user}>
          <h5 className={classes.user_title}>
            Your receipt was sent to <span>{userEmail}</span>
          </h5>
          <div className={classes.user_warning}>
            If you haven't received the email yet, check your spam or junk folder.
          </div>
        </div>
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              gap: "1rem",
            }}
          >
            <InputWithIcons
              value={userEmail}
              onChange={(e) => onEmailInput(e)}
              placeholder="Enter email for receipt"
              Icon={MailOutlinedIcon}
              label="Enter email for receipt"
            />
            <IconButton
              size="small"
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                borderRadius: "10px",
                padding: "0 1rem",
                height: "3.7rem",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                "&:disabled": {
                  backgroundColor: "primary.light",
                },
              }}
              disabled={isDisabled}
              onClick={() => setOpen(true)}
            >
              <ForwardIcon />
            </IconButton>
          </Stack>
        </>
      )}
      {!isSignedIn && showError ? (
        <div className={classes.emailError}>Please enter a valid email.</div>
      ) : (
        <GlobalModal
          title={"Confirm Email"}
          open={open}
          body={modalBody}
          onOk={onOk}
          onCancel={onCancel}
        />
      )}
    </>
  );
};

export default EmailReceipt;
