import {  TrackOrderInfo } from "./../../Types/Types";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface OrderInfoState {
  error: string | null;
  trackInfo: TrackOrderInfo | null;
}

const initialState = {
  error: null,
  trackInfo: null
};

const reducer = (
  state: OrderInfoState = initialState,
  action: Action
): OrderInfoState => {
  switch (action.type) {
    case ActionType.SEND_ORDER_SUCCESS:
      return { ...state, trackInfo : action.payload };
    case ActionType.SEND_ORDER_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default reducer;
