import classes from "./Account.module.scss";
import InputWithIcons from "./InputWithIcons";
import SubmitFieldWithButton from "./SubmitFieldWithButton";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { sendPasswordChange } from "../../api/user";
import { validateEmail } from "../../common/account/account";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { convertToPath } from "./AccountHeader";


const ChangePassword: React.FC = () => {
  const params = useParams<{ businessName: string }>();
  const appBaseUrl = convertToPath(params.businessName);
  const account = useTypedSelector(state => state.account);
  const { setAccountError, clearAccountErrors } = useActions();
  const [successMsg, setInfoMsg] = useState("");
  const [fields, setFields] = useState({
    emailAsUserId: account.credential.email ?? "",
    password: "",
    newPassword: "",
    confirmedPassword: "",
  });

  useEffect(() => {
    clearAccountErrors();
  }, []);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = String(event.target.value);
    if (event.target.name === nameOf((a: typeof fields) => a.emailAsUserId)) {
      setFields({
        ...fields,
        emailAsUserId: currValue
      });
      return;
    }

    const fieldName = event.target.name;
    setFields({
      ...fields,
      [fieldName]: currValue
    });
  }

  const isFormValid = () => {
    const { password, newPassword, confirmedPassword } = fields;

    if (password.length < 4) return false;
    if (newPassword.length < 4) return false;
    if (newPassword !== confirmedPassword) return false;

    return true;
  }

  const tryChangePassword = async (event) => {
    clearAccountErrors();
    if (!validateEmail(fields.emailAsUserId)) {
      setAccountError("Please enter a valid Email");
      return;
    }

    const result = await sendPasswordChange({ ...fields });
    console.log(result);

    setInfoMsg("Your password has been changed successfully.");
  }

  const nameOf = (f: (x: any) => any): string => {
    var p = new Proxy({}, {
      get: (target, key) => key
    })
    return f(p);
  }

  const getMessage = () => {
    if (account.error) {
      return (
        <span className={classes.error}>
          {account.error}
        </span>
      );
    }

    if (successMsg) {
      return (
        <span className={classes.success}>
          {successMsg}
        </span>
      );
    }
    return "";
  }

  return (
    <div className={classes.form}>
      <div className={classes.pageTitle}>
        Enter your new password for Relayfy account
      </div>

      <SubmitFieldWithButton
        name={nameOf((a: typeof fields) => a.emailAsUserId)}
        value={fields.emailAsUserId}
        onChange={handleInput}
        onSubmit={tryChangePassword}
        isFormValid={isFormValid}
      />

      <InputWithIcons
        name={nameOf((a: typeof fields) => a.password)}
        value={fields.password}
        placeholder="Type current password"
        type="password"
        leftIcon={faLock}
        onChange={handleInput}
        wrapperClasses={classes.elemWithIcon_bottomGap}
      />

      <InputWithIcons
        name={nameOf((a: typeof fields) => a.newPassword)}
        value={fields.newPassword}
        placeholder="Type new password"
        type="password"
        leftIcon={faLock}
        onChange={handleInput}
        wrapperClasses={classes.elemWithIcon_bottomGap}
      />

      <InputWithIcons
        name={nameOf((a: typeof fields) => a.confirmedPassword)}
        value={fields.confirmedPassword}
        placeholder="Confirm new password"
        type="password"
        leftIcon={faLock}
        onChange={handleInput}
        wrapperClasses={classes.elemWithIcon_bottomGap}
      />

      <div className={classes.messages}>
        {getMessage()}
      </div>

      <div className={classes.alternatives}>
        <Link
          className={`${classes.backLink}`}
          to={appBaseUrl ?? "/"} >
          Got back to menu
        </Link>
      </div>
    </div>
  );
}

export default ChangePassword;