import classes from './Header.module.scss';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUnlock, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { useActions } from '../../hooks/useActions';
import { Dropdown } from 'semantic-ui-react';
import { Link, useRouteMatch } from 'react-router-dom';

const AccountDropDown = () => {
  const account = useTypedSelector(state => state.account);
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { signOut } = useActions();
  const { url } = useRouteMatch();

  return (
    <Dropdown
      direction='left'
      className={classes.header_icon}
      icon={<FontAwesomeIcon icon={faUserCheck} />}
    >
      <Dropdown.Menu className={classes.dropdown}>
        <Dropdown.Item text={account.credential.email} />
        <Dropdown.Item className={classes.item} as={Link} to={`/${businessInfo?.serviceProviderUserId}/activateGift`}>
          Activate Gift Card
        </Dropdown.Item>
        <Dropdown.Item className={classes.item} as={Link} to={`${url}/account/changePassword`}>
          Change password
          <FontAwesomeIcon
            className={classes.icon}
            icon={faUnlock} />
        </Dropdown.Item>
        <Dropdown.Item className={classes.item} onClick={e => signOut()}>
          Sign out
          <FontAwesomeIcon
            className={classes.icon}
            icon={faSignOutAlt} />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AccountDropDown;