import classes from "./styles/CheckoutSheet.module.scss";
import { useCallback, useEffect, useMemo } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { DISCOUNT_VALUE_TYPE, FinalOrderPayload, OrderingType, BusinessDiscount } from "../../../Types/Types";
import { useParams } from "react-router";
import { IPublicProfileRouteParams } from "../../../Types/RouteTypes";
import { getOrderMode, isDiningOptionAvailable } from "../../../common/app";
import { OrderMode, orderTypeLabelMap, orderTypeMap } from "../../../constants";
import { useActions } from "../../../hooks/useActions";
import { Card, CardActions, CardContent, CardMedia, IconButton, Typography } from "@material-ui/core";
import { calculateMaxPurchaseAmt, formatPrice } from "../../../common/price/price";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RestaurantIcon from "@material-ui/icons/Restaurant";
import LocalTaxiOutlinedIcon from '@material-ui/icons/LocalTaxiOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import FoodBankSvg from "../../../assets/icons/food_bank.svg";
import usePriceAdjustments, { acceptedDiscountTypes } from "../../ConfirmOrder/hooks/usePriceDetails";

type OrderTypeSelectionProps = {
    handleNext: Function;
    handleBack?: Function;
}

const formatPromoText = (discount: BusinessDiscount) => {

    const minPurchase = discount.minPurchase ?? 0;
    const minPurchaseTxt = minPurchase === 0 ? "" : ` on orders above ${formatPrice(minPurchase)}`;

    const formattedMaxDscAmt = formatPrice(calculateMaxPurchaseAmt(discount));
    const maxPurchase = discount.maxPurchase ?? 0;
    const isDefaultMaxPurchase = maxPurchase >= 99999;
    const isAmountDiscount = discount.type === DISCOUNT_VALUE_TYPE.amount;
    const maxDiscountTxt = (isDefaultMaxPurchase || isAmountDiscount) ? "" : `, with savings up to ${formattedMaxDscAmt}`;

    const discountValue = discount.type === DISCOUNT_VALUE_TYPE.amount ? formatPrice(discount.value) : discount.value + "%";
    const text = `Enjoy ${discountValue} off${minPurchaseTxt}${maxDiscountTxt}.`;
    return text;
}

const FoodBackIcon = <img src={FoodBankSvg} className={classes.cardIcon} alt="" />;

const DininOptionToIcon: { [key in OrderingType]: JSX.Element; } = {
    "dine-in": <RestaurantIcon fontSize="large" />,
    "pre-order": <CalendarTodayOutlinedIcon fontSize="large" />,
    "take-out": FoodBackIcon,
    "delivery": <LocalTaxiOutlinedIcon fontSize="large"/>,
}

const isDiningOptionAvailableByViewMode = (viewMode, diningOpt: FinalOrderPayload["orderType"]) => {

    const appViewMode = getOrderMode(viewMode);
    const isKioskViewMode = appViewMode === OrderMode.kiosk;
    const isCateringViewMode = appViewMode === OrderMode.catering;

    if (diningOpt === "pre-order" && isKioskViewMode) return false;
    if (diningOpt === "take-out" && isCateringViewMode) return false;
    if (diningOpt === "dine-in" && isCateringViewMode) return false;

    return true;
};

const OrderTypeSelection = (props: OrderTypeSelectionProps) => {
    const { handleNext, handleBack } = props;
    const { setPreOrderDateTime, } = useActions();
    const { highestDiscount, hasDiningOptionDiscounts } = usePriceAdjustments();

    const finalOrder = useTypedSelector((state) => state.orderDetails.order);

    const { viewMode } = useParams<IPublicProfileRouteParams>();
    const appViewMode = getOrderMode(viewMode);
    const isCateringViewMode = appViewMode === OrderMode.catering;
    const isOrderTypePreOrder = finalOrder.orderType === orderTypeMap.preorder;

    useEffect(() => {
        if (!isOrderTypePreOrder && !isCateringViewMode) {
            setPreOrderDateTime(undefined);
        }
    }, [finalOrder.orderType]);

    const allTypePromoText = useMemo(() => {
        if (!highestDiscount) return null;
        if (hasDiningOptionDiscounts) return null;
        const discountType = highestDiscount.discountType;
        const isAllOrderType = !discountType || acceptedDiscountTypes.includes(discountType);
        if (!isAllOrderType) return null;

        return <Typography variant="subtitle1" className={classes.promoText} gutterBottom>
            {formatPromoText(highestDiscount)}
        </Typography>;
    }, [highestDiscount, hasDiningOptionDiscounts]);

    return <div className={classes.orderTypeSelection}>
        {allTypePromoText}
        <OrderTypeCard diningOpt="take-out" handleNext={handleNext} />
        <OrderTypeCard diningOpt="dine-in" handleNext={handleNext} />
        <OrderTypeCard diningOpt="delivery" handleNext={handleNext} />
        <OrderTypeCard diningOpt="pre-order" handleNext={handleNext} />
    </div>
}


type OrderTypeCardProps = { diningOpt: OrderingType; }

const OrderTypeCard = (props: OrderTypeCardProps & OrderTypeSelectionProps) => {

    const { diningOpt, handleNext } = props;
    const { viewMode } = useParams<IPublicProfileRouteParams>();
    const { setOrderType } = useActions();
    const { businessInfo } = useTypedSelector((state) => state.businessInfo);
    const { subTotal } = useTypedSelector((state) => state.orderDetails.order);
    const { getHighestDiscount } = usePriceAdjustments();

    const entry = Object.entries(orderTypeMap).find(x => x[1] === (diningOpt as string));
    const diningOptionName = !!entry ? entry[0] : "";
    const isOptionEnabled = isDiningOptionAvailable(businessInfo?.diningOption[diningOptionName]);

    const DeliveryPromoText = useMemo(() => {
        if (diningOpt !== "delivery") return null;
        const requiredAmountForFreeDelivery = (businessInfo?.minOrderAmountForFreeDelivery ?? 0) * 100;
        if (requiredAmountForFreeDelivery === 0) return null;
        if (subTotal < requiredAmountForFreeDelivery) return null;

        return <Typography className={classes.promoText} variant="subtitle2" gutterBottom>
            Free delivery for a qualified address
        </Typography>
    }, [subTotal, businessInfo?.minOrderAmountForFreeDelivery]);

    const PromoText = useMemo(() => {
        const highestDiscount = getHighestDiscount(diningOpt);
        if (!highestDiscount) return null;
        if (highestDiscount.discountType !== diningOpt) return null;

        return <Typography className={classes.promoText} variant="subtitle2" gutterBottom>
            {formatPromoText(highestDiscount)}
        </Typography>
    }, [diningOpt, getHighestDiscount]);

    const onClick = useCallback((e) => {
        setOrderType(diningOpt);
        handleNext();
    }, [handleNext, setOrderType]);

    if (!diningOpt) return null;
    if (!isOptionEnabled) return null;
    if (!isDiningOptionAvailableByViewMode(viewMode, diningOpt)) return null;

    const orderTypeLabel = orderTypeLabelMap[diningOpt];
    const description = businessInfo?.diningOption[diningOptionName + "Desc"];
    const qualificationCriteria = businessInfo?.diningOption[diningOptionName + "QualificationCriteria"];

    return <Card
        variant="outlined"
        key={diningOpt}
        className={classes.card}
        onClick={onClick}
    >
        <CardMedia className={classes.cardMedia}>
            {DininOptionToIcon[diningOpt]}
        </CardMedia>

        <CardContent className={classes.cardContent}>
            <Typography variant="h6" className={classes.cardTitle} gutterBottom>{orderTypeLabel}</Typography>
            <Typography variant="body1" gutterBottom>{description}</Typography>
            <Typography variant="body1" gutterBottom>{qualificationCriteria}</Typography>
            {DeliveryPromoText}
            {PromoText}
        </CardContent>

        <CardActions>
            <IconButton
                // onClick={onClick}
                aria-label=""
            >
                <ChevronRightIcon />
            </IconButton>
        </CardActions>

    </Card>;
}


export default OrderTypeSelection;