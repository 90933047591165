import { faGifts } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { getUserRewards } from "../../api/rewards";
import { isNumber } from "../../common/price/price";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Box, Typography } from "@material-ui/core";
import Loader from "../../components/Loader/Loader";
import Popup from "../Popup/Popup";
import classes from "./Rewards.module.scss";

export const getLoyaltyNote = (customerLoyaltyNote) => {
  if (isNumber(customerLoyaltyNote)) {
    return `1 point per $${customerLoyaltyNote} spent!`;
  }

  return customerLoyaltyNote;
}

const initialRewardsState = {
  serviceProviderDisplayName: "",
  stampCardType: "",
  points: "",
  totalPoints: "",
  customerLoyaltyNote: "",
  sharingNote: "",
};

const RewardsPopup: React.FC = () => {
  const { url } = useRouteMatch();
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const account = useTypedSelector((state) => state.account);
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataValid, setDataValid] = useState(false);
  const [rewardsInfo, setRewardsInfo] = useState(initialRewardsState);

  useEffect(() => {
    if (account.isSignedIn) {
      return;
    }

  }, [account.isSignedIn]);

  useEffect(() => {

    (async () => {
      if (isOpen === false) return;
      if (!businessInfo?.serviceProviderUserEmail) return;
      if (!businessInfo?.serviceProviderUserId) return;

      try {
        setIsLoading(true);
        const response = await getUserRewards({
          costumerEmail: account.credential.email ?? "tba",
          serviceProviderEmail: businessInfo.serviceProviderUserEmail,
          serviceProviderUserId: businessInfo.serviceProviderUserId
        })

        const data = response.data;
        if (!data || data.serviceProviderUserId === 0) {
          setDataValid(false);
          return;
        }

        setRewardsInfo(data);
        setDataValid(true);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();

  }, [isOpen, businessInfo])

  const handleClose = () => {
    setOpen(false);
  }


  const onClickElem = (
    <div onClick={e => setOpen(true)}
      className={classes.rewards_open}
    >
      <FontAwesomeIcon
        className={classes.rewards_open_icon}
        icon={faGifts} />
    </div>
  )

  const loader = useMemo(() => {
    if (!isLoading) return null;
    if (!dataValid) return null;
    return <Loader />;
  }, [isLoading, dataValid]);

  const PopupContent = useMemo(() => {
    if (!account.isSignedIn) {
      return <Link to={`${url}/account/signIn`}>
        <Typography>
          Sign in to start with {rewardsInfo.points} bonus points!
        </Typography>
      </Link>;
    }

    return <Box className={classes.rewardsContainer}>
      <Typography>
        Points Balance <b>{rewardsInfo.points}</b>/{rewardsInfo.stampCardType}
      </Typography>

      <Typography>
        {getLoyaltyNote(rewardsInfo.customerLoyaltyNote)}
      </Typography>
      <Typography>
        {rewardsInfo.sharingNote}
      </Typography>
    </Box>
  }, [rewardsInfo, account.isSignedIn, url])


  return (<>
    {loader}
    <Popup
      isOpen={isOpen}
      title="Rewards Balance"
      content={PopupContent}
      onClickElement={onClickElem}
      handleClose={handleClose}
    />
  </>
  );
}

export default RewardsPopup;