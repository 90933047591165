import SvgIcon from '@mui/material/SvgIcon';

const ForwardIcon = () => {
    return (
        <SvgIcon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.5 12L3.21 19.5L2.5 18.79L5.5 12L2.5 5.21L3.21 4.5L21.5 12Z" fill="white"/>
</svg>

        </SvgIcon>
    )
}

export default ForwardIcon;



