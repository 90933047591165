// import { faArrowRight, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Account.module.scss";
// import InputWithIcons from "./InputWithIcons";
import NewInputWithIcons from "./InputWithIcon";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

type HtmlInputProps = React.DOMAttributes<HTMLInputElement>;

interface SubmitFieldWithButtonProps {
  value: string;
  onChange: HtmlInputProps["onChange"];
  onSubmit: HtmlInputProps["onClick"];
  isFormValid: () => boolean;
  moreClasses?: string;
  name?: string;
}

const SubmitFieldWithButton: React.FC<SubmitFieldWithButtonProps> = (props) => {
  const { value, onChange, onSubmit, isFormValid, moreClasses, name } = props;
  /* need to revamp this on error handling */
  const getSubmitBtnClass = (): string | undefined => {
    let classesStr = `${classes.signInBtn}`;
    if (!isFormValid()) {
      return classesStr += " " + classes.disabled;
    }
    return classesStr;
  }

  

  return (
    // <InputWithIcons
    //   value={value}
    //   placeholder="Email"
    //   type="email"
    //   name={name}
    //   leftIcon={faSignInAlt}
    //   onChange={onChange}
    //   onKeyUp={event => {
    //     if (event.key === "Enter") {
    //       onSubmit && onSubmit(event as any);
    //     }
    //   }}
    //   wrapperClasses={`${classes.inputField} ${moreClasses}`}
    //   RightElem={(
    //     <span
    //       onClick={onSubmit}
    //       className={getSubmitBtnClass()}
    //     >
    //       <FontAwesomeIcon icon={faArrowRight} />
    //     </span>
    //   )} />

    <NewInputWithIcons
      value={value}
      type="email"
      label="Email"
      placeholder="Email"
      Icon={MailOutlineIcon}
      name={name}
      onChange={onChange}
      onKeyUp={event => {
        if (event.key === "Enter") {
          onSubmit && onSubmit(event as any);
        }
      }}
    />
  );
};

export default SubmitFieldWithButton;
