import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { CheckoutFormStatus, DestinationStatus, FinalOrderPayload } from "../../Types/Types";


export const setShowBrowserBackModal = (isOpen: boolean) => {
	return async (dispatch: Dispatch<Action>) => {

		dispatch({
			type: ActionType.SET_SHOW_BROWSERBACK_MODAL,
			payload: isOpen,
		});

	};
};

export const setEnableBrowserBackModal = (enable: boolean) => {
	return async (dispatch: Dispatch<Action>) => {

		dispatch({
			type: ActionType.SET_ENABLE_BROWSERBACK_MODAL,
			payload: enable,
		});

	};
};

export const updateDestStatus = (status: DestinationStatus) => {
	return async (dispatch: Dispatch<Action>) => {

		dispatch({
			type: ActionType.UPDATE_DESTINATION_STATUS,
			payload: status,
		});

	};
};

export const updateCheckoutFormStatus = (status: CheckoutFormStatus) => {
	return async (dispatch: Dispatch<Action>) => {

		dispatch({
			type: ActionType.UPDATE_CHECKOUT_FORM_STATUS,
			payload: status,
		});

	};
};

export const setOrderTypeFromTracking = (orderType: FinalOrderPayload["orderType"]) => {
	return async (dispatch: Dispatch<Action>) => {

		dispatch({
			type: ActionType.SET_ORDER_TYPE_FROM_TRACKING,
			payload: orderType,
		});

	};
};

export const setAppLoading = () => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionType.SET_APP_LOADING, });
	};
};

export const setAppLoaded = () => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionType.SET_APP_LOADED, });
	};
};

