import { useTypedSelector } from "../../hooks/useTypedSelector";
import StyledButton, { StyledButtonProps } from "./StyledButton";

const ThemedButton = (props: StyledButtonProps) => {
    const { businessInfo } = useTypedSelector(state => state.businessInfo);

    return <StyledButton
        style={{
            backgroundColor: businessInfo?.theme.color,
            color: 'white',
        }}
        {...props}
    />
}

export default ThemedButton;