import classes from "./KioskWelcomePage.module.scss";
import Popup from "../Popup/Popup";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getOrderMode } from "../../common/app";
import { useParams } from "react-router";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { formatPrice } from "../../common/price/price";
import ThemedButton from "../StyledComponent/ThemedButton";

interface KioskWelcomePageProps {

}

const KioskWelcomePage = (props: KioskWelcomePageProps) => {
  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const appViewMode = getOrderMode(viewMode);

  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const [isOpen, setOpen] = useState<boolean>(false);
  const kioskWelcome = businessInfo?.kioskWelcome;

  useEffect(() => {
    setOpen(kioskWelcome?.show === "yes");
  }, [kioskWelcome?.show]);

  const handleClose = () => {
    setOpen(false);
  };

  if (!kioskWelcome) return <></>;
  const { title, show, callToAction, message } = kioskWelcome;

  const content = (
    <section className={classes.pageContent}>

      <section className={classes.title}>
        <div className={classes.value}>{title}</div>
      </section>

      {message &&
        <section className={classes.message}
          dangerouslySetInnerHTML={{ __html: message }}
        />}

      <div className={classes.footer}>
        <ThemedButton
          isMaterial
          className={classes.callToAction}
          onClick={handleClose} >
          {callToAction}
        </ThemedButton>

      </div>

    </section>
  );

  return (
    <Popup
      handleClose={handleClose}
      isOpen={isOpen}
      popupStyle={{ border: "solid 6px" + businessInfo?.theme.color, maxWidth: '90%', maxHeight: '90%', }}
      contentStyle={{ height: '100%', }}
      content={content}
      footer={<></>}
      header={<></>}
      isFullScreen
      shouldCloseOnOverlayClick
    />
  );
};

export default KioskWelcomePage;
