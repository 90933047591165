import React, { useEffect } from "react";
import classes from "./FeedbackConfirmation.module.scss";
import { useHistory, useParams } from "react-router";
import { LegacyBusinessInfo } from "../../api/index";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
interface FeedbackConfirmationProps {
  star: number;
  storeData?: LegacyBusinessInfo;
}

const FeedbackConfirmation: React.FC<FeedbackConfirmationProps> = (props) => {
  const { star, storeData } = props;
  const { orderNumber } = useParams<{ orderNumber?: string }>();
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const storeMapUrl = businessInfo?.location?.googleMapUri ?? undefined;
  const phone = storeData?.frequentlyPhoneNumber ?? "0000000000";
  const separatedPhoneNumber = [
    phone.slice(0, 3),
    phone.slice(3, 6),
    phone.slice(6),
  ];
  const telHref = `tel: ${phone}`;
  const history = useHistory();
  const theme = useTheme(); 
  const backToOrderStatus = () => {
    if (orderNumber) {
      history.replace(`/order/${orderNumber}`);
    } else {
      history.replace(`/${businessInfo?.routingPath}`);
    }
  };

  useEffect(() => {}, []);

  const backButtonLabel = orderNumber
    ? "Back to order status"
    : `Back to ${businessInfo?.name ?? "menu"}`;
  return (
    <Box className={classes.body}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: "12px",
          padding: "10px 10px",
          cursor: "pointer",
          backgroundColor: "primary.main",
          color: "#fff",
          minHeight: "50px",
        }}
      >
        <ArrowBackIcon
          onClick={backToOrderStatus}
          sx={{ color: "#fff", fontSize: "24px" }}
        />
        <span
          style={{
            color: "#fff",
            fontSize: "20px",
            fontWeight: 400,
          }}
        >
          {backButtonLabel}
        </span>
      </Box>

      <Box className={classes.content}>
        {star >= 3 ? (
          <Typography
            fontSize={"14px"}
            fontWeight={400}
            color={"#49454F"}
            sx={{
              margin: "2rem 0 !important",
            }}
          >
            Thank you for your feedback. Feel free to leave a Google Review{" "}
            <a
              style={{
                textDecoration: "underline",
                textDecorationColor:theme.palette.primary.main,
              }}
              href={storeMapUrl}
            >
              <Typography
                sx={{
                  display: "inline !important",
                  fontWeight: 800,
                  fontFamily: "inherit",
                  color:'primary.main',
                  
                }}
              >
                here.
              </Typography>
            </a>
          </Typography>
        ) : (
          <Typography
            fontSize={"14px"}
            fontWeight={400}
            color={"#49454F"}
            sx={{
              margin: "2rem 0 !important",
            }}
          >
            We are truly sorry about your bad experience. We will inform the
            management to take the required actions. Please fell free to contact
            us at{" "}
            <a
              style={{
                display: "inline !important",
                textDecoration: "underline",
                textDecorationColor: theme.palette.primary.main,
              }}
              href={telHref}
            >
              <Typography
              sx={{
                display: "inline !important",
                fontWeight: 800,
                fontFamily: "inherit",
                color:'primary.main',
              }}
              >
              ({separatedPhoneNumber[0]})-{separatedPhoneNumber[1]}-
              {separatedPhoneNumber[2]}
                </Typography>
            </a>
          </Typography>
        )}

        <Button
          sx={{
            width: "100%",
            backgroundColor: "primary.contrastText",
            color: "primary.main",
            padding: "10px 0",
            borderRadius: "100px",
            fontSize: "20px",
            fontWeight: 500,
            margin: "3rem 0",
          }}
          onClick={backToOrderStatus}
          variant="outlined"
        >
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default FeedbackConfirmation;
