import classes from './ConfirmModal.module.scss';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Button } from 'semantic-ui-react';
import { MouseEventHandler, ReactNode } from 'react';
import GlobalModal from '../Modal/Modal';


export interface ConfirmModalProps {
  isOpen: boolean;
  handleClose: MouseEventHandler;
  onOk: MouseEventHandler;
  onCancel?: MouseEventHandler;

  title?: string;
  bodyText1?: ReactNode;
  bodyText2?: ReactNode;

  okLabel?: string;
  cancelLabel?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);

  const {
    isOpen, handleClose, onCancel, onOk,
    title, bodyText1, bodyText2,
    okLabel, cancelLabel,
  } = props;

  const confirmButtons = (
    <div className={classes.buttons}>

      <Button onClick={handleClose}
        className={classes.buttons_no}>
        No
      </Button>

      <Button onClick={onOk}
        className={classes.buttons_yes}
        style={{ backgroundColor: businessInfo?.theme?.color }}>
        Yes
      </Button>

    </div>
  )

  return (
    <GlobalModal
      open={isOpen}
      handleClose={handleClose || onCancel}
      title={title ?? "Warning!"}
      body={
        <div className={classes.bodyContainer}>
          <br />
          <div>{bodyText1}</div>
          <div>{bodyText2}</div>
        </div>
      }
      onOk={onOk}
      okLabel="Order"
      cancelLabel="Change"
      buttons={confirmButtons}
    />
  );
}

export default ConfirmModal;