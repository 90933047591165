import { Address } from "../Types/Types";



export const formatLocation = (locationObj?: Address) => {
    if (!locationObj) return "";

    let res = locationObj.addressLine1 && `${locationObj.addressLine1} `;
    res += locationObj.addressLine2 && `${locationObj.addressLine2} `;
    res += locationObj.city && `${locationObj.city} `;
    res += locationObj.province && `${locationObj.province} `;
    res += locationObj.postalCode && `${locationObj.postalCode} `;

    return res;
}